import { router } from './utility/Router'
import { RouterProvider } from 'react-router-dom'
import { xGridLicense } from './misc/other'
import { LicenseInfo } from '@mui/x-license'
import axios from 'axios'

LicenseInfo.setLicenseKey(xGridLicense)
var root = window.location.protocol + '//' + window.location.host
window.localStorage.setItem('BASE_URL', root)

axios.defaults.baseURL=root



export const App = () => {
  return (
    <RouterProvider router={router}/>
  )
  }

