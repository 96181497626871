import * as React from 'react'
import { Typography, Box, 
        Grid, TextField, 
        FormControl, InputLabel, 
        ListSubheader, MenuItem, 
        Select, Button } from '@mui/material'
import AccountBoxIcon from '@mui/icons-material/AccountBox'        



export const VerifyBillingForm = ({customerInfo}) =>{
    
    return(
        <Box sx={{minHeight:'80vh', mt: 2, mb: 2}}>
            <Grid container direction='row'>
                <Grid item sx={{mb: 1}}>
                <Typography>Please update your billing info, if needed. </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{mb: 1}} direction='row' spacing={2} justifyContent='flex-start' alignItems='flex-start'>
                <Grid item xs={8} s={4} md={6}>
                    <TextField
                    fullWidth 
                    label='First Name'
                    id='first_name'
                    name='first_name'
                    type='text'
                    />
                </Grid>    
                <Grid item xs={8} s={4} md={6}>    
                    <TextField
                    fullWidth 
                    label='Last Name'
                    id='last_name'
                    name='last_name'
                    type='text'
                    />
                </Grid>
            </Grid>
            <Grid container sx={{mb: 1}} direction='row' justifyContent='flex-start' alignItems='flex-start'>    
                <Grid item xs={8} sm={8} md={12}>
                    <TextField
                    fullWidth
                    label='Street Address 2'
                    id='street_adddress_1'
                    name='street_address_1'
                    type='text'
                    />
                </Grid>
            </Grid>
            <Grid container direction='row' sx={{mb: 1}} justifyContent='flex-start' alignItems='flex-start'>    
                <Grid item xs={8} sm={8} md={12}>
                    <TextField
                    fullWidth
                    label='Street Address 2'
                    id='street_adddress_2'
                    name='street_adddress_2'
                    type='text'
                    />
                </Grid>
            </Grid>
            <Grid container direction='row' spacing={1} sx={{mb: 1}} justifyContent='flex-start' alignItems='flex-start'>
                <Grid item xs={6} sm={3} md={6}>
                    <TextField 
                        fullWidth
                        margin='none'
                        id='city'
                        name='city'
                        label='City'
                    />

                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <FormControl variant='outlined' fullWidth>
                        <InputLabel id='state-label'>State</InputLabel>
                            <Select
                                fullWidth
                                label='state-label'
                                id='state'
                            >
                                <ListSubheader>States</ListSubheader>
                                <MenuItem value="AL">AL</MenuItem>
                                <MenuItem value="AK">AK</MenuItem>
                                <MenuItem value="AR">AR</MenuItem>
                                <MenuItem value="AZ">AZ</MenuItem>
                                <MenuItem value="CA">CA</MenuItem>
                                <MenuItem value="CO">CO</MenuItem>
                                <MenuItem value="CT">CT</MenuItem>
                                <MenuItem value="DC">DC</MenuItem>
                                <MenuItem value="DE">DE</MenuItem>
                                <MenuItem value="FL">FL</MenuItem>
                                <MenuItem value="GA">GA</MenuItem>
                                <MenuItem value="HI">HI</MenuItem>
                                <MenuItem value="IA">IA</MenuItem>
                                <MenuItem value="ID">ID</MenuItem>
                                <MenuItem value="IL">IL</MenuItem>
                                <MenuItem value="IN">IN</MenuItem>
                                <MenuItem value="KS">KS</MenuItem>
                                <MenuItem value="KY">KY</MenuItem>
                                <MenuItem value="LA">LA</MenuItem>
                                <MenuItem value="MA">MA</MenuItem>
                                <MenuItem value="MD">MD</MenuItem>
                                <MenuItem value="ME">ME</MenuItem>
                                <MenuItem value="MI">MI</MenuItem>
                                <MenuItem value="MN">MN</MenuItem>
                                <MenuItem value="MO">MO</MenuItem>
                                <MenuItem value="MS">MS</MenuItem>
                                <MenuItem value="MT">MT</MenuItem>
                                <MenuItem value="NC">NC</MenuItem>
                                <MenuItem value="NE">NE</MenuItem>
                                <MenuItem value="NH">NH</MenuItem>
                                <MenuItem value="NJ">NJ</MenuItem>
                                <MenuItem value="NM">NM</MenuItem>
                                <MenuItem value="NV">NV</MenuItem>
                                <MenuItem value="NY">NY</MenuItem>
                                <MenuItem value="ND">ND</MenuItem>
                                <MenuItem value="OH">OH</MenuItem>
                                <MenuItem value="OK">OK</MenuItem>
                                <MenuItem value="OR">OR</MenuItem>
                                <MenuItem value="PA">PA</MenuItem>
                                <MenuItem value="RI">RI</MenuItem>
                                <MenuItem value="SC">SC</MenuItem>
                                <MenuItem value="SD">SD</MenuItem>
                                <MenuItem value="TN">TN</MenuItem>
                                <MenuItem value="TX">TX</MenuItem>
                                <MenuItem value="UT">UT</MenuItem>
                                <MenuItem value="VT">VT</MenuItem>
                                <MenuItem value="VA">VA</MenuItem>
                                <MenuItem value="WA">WA</MenuItem>
                                <MenuItem value="WI">WI</MenuItem>
                                <MenuItem value="WV">WV</MenuItem>
                                <MenuItem value="WY">WY</MenuItem>
                                <ListSubheader>Outlying Territories</ListSubheader>
                                <MenuItem value="AS">AS</MenuItem>
                                <MenuItem value="GU">GU</MenuItem>
                                <MenuItem value="MP">MP</MenuItem>
                                <MenuItem value="PR">PR</MenuItem>
                                <MenuItem value="UM">UM</MenuItem>
                                <MenuItem value="VI">VI</MenuItem>
                                <ListSubheader>Armed Forces </ListSubheader>
                                <MenuItem value="APO-AA">AA</MenuItem>
                                <MenuItem value="APO-AP">AP</MenuItem>
                                <MenuItem value="APO-AE">AE</MenuItem>
                                <MenuItem value="FPO-AA">AA</MenuItem>
                                <MenuItem value="FPO-AP">AP</MenuItem>
                                <MenuItem value="FPO-AE">AE</MenuItem>


                            </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <TextField 
                        sx={{ml: .5}}
                        fullWidth
                        margin='none'
                        type='phone'
                        id='zip'
                        name='zip'
                        label='Zip'
                    />
                </Grid>
                <Grid item xs={3} sm={3} md={2}>
                    <TextField 
                        sx={{ml: 1}}
                        margin='none'
                        type='phone'
                        id='zip4'
                        name='zip4'
                        label='+4'                    
                    />
                </Grid>

            </Grid>
            <Grid container direction='row-reverse'>
                <Grid item>
                    <Button variant='contained' color='primary' endIcon={<AccountBoxIcon />}>Update Billing</Button>
                </Grid>
            </Grid>
        </Box>
    )

}