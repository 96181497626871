import * as React from 'react'
import { Typography, Box, Button, Grid, TextField } from '@mui/material'



export const PaybyCheckRec = ({customerInfo, paymentInfo}) =>{






return(
    <Box sx={{minHeight:'30vh', mt: 2, mb: 2}}>
        <Grid container direction='row' sx={{mb: 2}}>
            <Grid item>
                <Typography color='secondary'>First Payment is due today, you will be billed ${paymentInfo.monthlyBill} over the next {paymentInfo.numberofMonths-1} months</Typography>
            </Grid>
         </Grid>   
        <Grid container direction='row' sx={{mb: 1}}>
            <Grid item>
                <Typography>Please fill out your checking account info</Typography> 
            </Grid>
        </Grid>
        <Grid container direction='row'>
            <Grid item sx={{mb: 1}} xs={12} sm={10} md={9}>
                <TextField
                    fullWidth
                    name='accountnumber'
                    id='accountnumber'
                    label="Account Number"
                    type='number'
                    required
                />
            </Grid>
        </Grid>
        <Grid container direction='row'>
            <Grid item sx={{mb: 1}} xs={12} sm={10} md={9}>
                <TextField
                    fullWidth
                    name='routingnumber'
                    id='routingnumber'
                    label="Routing Number"
                    type='number'
                    required
                />
            </Grid>
        </Grid>
        <Grid container direction='row-reverse' sx={{mb: 1}}>
            <Grid item xs={4}>
                <Button  fullWidth variant='contained'> Pay ${paymentInfo.monthlyBill} </Button>
            </Grid>
        </Grid>      
         
    </Box>
    
    
)
}