import * as React from 'react'
import { useFormik } from 'formik'
import { TextField, InputAdornment, Button, Grid, Link, Box, Container } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import * as yup from 'yup'
import { useCSVReader, lightenDarkenColor, formatFileSize } from 'react-papaparse'
import axios from 'axios'
import { todaysDate, purchaseDate, secondDigits, thirdDigits } from '../../misc/dates'
import { ErrorAlert } from '../../alerts/ErrorAlert'
import { CustomerUploadProgress } from '../../alerts/CustomerUploadProgress'
import CheckIcon from '@mui/icons-material/Check'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt'
import { FileSizeToBig } from '../../alerts/FileSizeToBig'

var dataFromCsv = []

const BASE_URL = window.location.origin

const dropBorderColor = '#CCC'
const dropBackGroundColor = '#FFFFFF66'
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919'
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40)
const dropHoverColor = '#686868'

const dropStyles = {
    zone: {
        alignItems: 'center',
        border: `2px dashed ${dropBorderColor}`,
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        padding: 20,
        marginTop: '2rem',
        marginBottom: '2rem'
    },
    file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 200,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: dropBackGroundColor,
    width: 180,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: dropBackGroundColor,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: dropHoverColor,
  },
  default: {
    borderColor: dropBackGroundColor,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  }


}



const validationSchema = yup.object().shape({
    vendor: yup.string()
                .required("Vendor Number is Required")
                .min(5, '5 or more characters required'),
    purchasePrice: yup.number()
                    .required('Amount Portfolio purchased is required'),
    numberOfAccounts: yup.number().required('Please enter a number').min(1,'Please enter a number greater than 1'),
    faceValue: yup.number().required('Please enter a purchase price').min(1, 'Please enter a number greater than 1')               
                    
    
    })

    export const AddCustomers =() =>{
        const {CSVReader} = useCSVReader()
        const [zoneHover, setZoneHover] =React.useState(false)
        const [removeHoverColor, setRemoveHoverColor] = React.useState(DEFAULT_REMOVE_HOVER_COLOR)
        const [csvData, setCSVData] = React.useState(null)
        const [accountsNumToDisplay, setAccountsNumToDisplay] = React.useState(0)
        const [accountsToLoad, setAccountsToLoad] = React.useState(0)
        const [loading, setLoading] = React.useState(false)
        const [upload, setUpload] = React.useState(false)
        const [batchID, setBatchID] = React.useState('')
        const [disabled, setDisabled] = React.useState(true)
       
        const [sizeToBig, setSizeToBig] = React.useState(false)


        const closeProgress = () =>
        {setUpload(false)
        }

        const closeSizeToBig = () =>{
          setSizeToBig(false)
        }

        const handleOnDrop = (data) =>{
            
            let customerdata = data.data
            
        if (customerdata[0].hasOwnProperty('selective_service_number'&&
   'first_name' &&
   'last_name' &&
   'email' &&
   'phone_number' &&
   'street_address_1' &&
   'street_address_2' &&
   'city' && 
   'state' &&
   'zip' &&
   'zip4' &&
   'type' &&
    'original_creditor' &&
    'original_account_number' &&
    'face_value' && 
    'fees_and_penalties' &&
    'overdraft_fees' && 
    'principal') 
     && Object.keys(customerdata[0]).length === 18
   ) {
    
     for (let i = 0; i < customerdata.length; i++){
      if(customerdata[i].selective_service_number !== ''){
        dataFromCsv.push(customerdata[i]);
      }
           }
           
          setCSVData(dataFromCsv)
          setAccountsNumToDisplay(dataFromCsv.length)
          setAccountsToLoad(dataFromCsv.length)
          setZoneHover(false)
    



   } else {
   let results = []
   setCSVData(results)
   setZoneHover(false) 
    
    return alert('Check your column headers.')
   }

   
}

let dragOver = (event) =>{
    event.preventDefault();
    setZoneHover(true)
}

let dragLeave = (event) =>{
    event.preventDefault()
    setZoneHover(false)
} 

let mouseOver = (event) =>{
    event.preventDefault()
    setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT)
}

let mouseOut = (event) =>{
    event.preventDefault()
    setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR)

}
 
const csvDropDisplay = ({getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove})=>{
  
  if(acceptedFile){
  if(acceptedFile.size > 2000000 ){
setDisabled(true)
setSizeToBig(true)

  }else{
    setDisabled(false)
    
  }
  }
  return (
    <>
    <div
    {...getRootProps()}
    style={Object.assign(
        {},
        dropStyles.zone,
        zoneHover && dropStyles.zoneHover
    )}>
       {acceptedFile ? (
        <>
        <div style={dropStyles.file}>
            <div style={dropStyles.info}>
                <span style={dropStyles.size}>
                    {formatFileSize(acceptedFile.size)}
                </span>
                <span style={dropStyles.name}>{acceptedFile.name}</span>
            </div>
            <div style={dropStyles.progressBar}>
                <ProgressBar />
            </div>
            <div
            {...getRemoveFileProps()}
            style={dropStyles.remove}
            onMouseOver ={mouseOver}
            onMouseOut ={mouseOut}
            >
              <Remove color={removeHoverColor} />  

            </div>
        </div>
        </>

       ): ('Drop CSV file here or click to upload')}  

    </div>
    
    
    </>
)}



const dataUploadClick = async () =>{
  setUpload(true)
  setLoading(true)

  let newData = csvData
      
    

  const dataToSend = await newData.map(async (object, index)=>{
    const container = {}
    
    container.key = index
    
    let url = BASE_URL +'/api/oracle2/customernumber/'+object.selective_service_number
    container.customer_number = await (await axios(url)).data.customer_number
    container.batch_id = batchID
    container.city = object.city
    container.email = object.email
    container.face_value= object.face_value
    container.fees_and_penalties= object.fees_and_penalties
    container.first_name= object.first_name
    container.last_name= object.last_name
    container.original_account_number= object.original_account_number
    container.original_creditor= object.original_creditor
    container.overdraft_fees=object.overdraft_fees
    container.phone_number = object.phone_number
    container.principal= object.principal
    container.selective_service_number= object.selective_service_number
    container.state= object.state
    container.street_address_1= object.street_address_1
    container.street_address_2= object.street_address_2
    container.type= object.type
    container.zip= object.zip.replace(/\s/g, "")
    container.zip4= object.zip4.replace(/\s/g, "") 


    return container 
           
    
  }) 
  
 
  console.log('Here is the dataToSend: ', dataToSend)
  
 const result = await Promise.all(dataToSend) 

 console.log('Here is the result', result)

 setTimeout(()=>{
  
      axios({
        method: "POST",
        url: "api/oracle2/customerupload",
        data: result
  
      })
      .then(result => {
        console.log("Here are the results of the axios call", result)
        setAccountsNumToDisplay(0)
        setCSVData(null)
        setLoading(false)
        dataFromCsv = []
        
             
             })
             .catch(error=>{
               setCSVData(null)
               setAccountsNumToDisplay(0)
               setLoading(false)
               dataFromCsv = []
               let errMessage = 'Here is the error in the Caught Add Customers Call: ' + error
               return ErrorAlert(errMessage)
              })
  
  
    },(75*accountsNumToDisplay))


}



  const formik = useFormik({
    initialValues: {
        vendor: 'Vendor',
        purchasePrice: 0.00,
        purchaseDate: purchaseDate,
        numberOfAccounts: 0,
        faceValue: 0.00
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions)=>{
      
      setLoading(true)
     
      let firstDigits = todaysDate.getUTCFullYear()
      let firstString = firstDigits.toString()
      let secondString =secondDigits.toString()
      let thirdString = thirdDigits.toString()
      let fourthString = formik.values.vendor.substring(0,5)
      let finalFourthString = fourthString.replace(' ', '_')
      
      let newbatchID = firstString + secondString + thirdString + finalFourthString.toUpperCase()
      setBatchID(newbatchID)
      
      
      
        
      
  
      let batchinfo = Object.assign({}, {'vendor': values.vendor}, {'purchasePrice': values.purchasePrice}, {'purchaseDate':values.purchaseDate}, {'number_of_accounts':values.numberOfAccounts}, {'batch_face_value': values.faceValue}, {'batch_id': newbatchID})
      
      console.log('Batch Info: ', batchinfo)
      
      axios({
        method: "POST",
        url: 'api/oracle2/batchupload',
        data: batchinfo,
      }).then(result=>{
        console.log("Here is the result.status: ", result.status)
        if(result.status===200){
        setLoading(false)
        setDisabled(false)
        actions.setSubmitting(false)}
      })

  
  
      
      
      
  
  
    }
    
  
  })

  let totalTime = 2000 * accountsToLoad
  
  
  
  
  return( 
  
    
  
  
  
    <Box sx={{minHeight:'80vh', mt: 15, mb: 2}} component='form' onSubmit={formik.handleSubmit}>
      <Grid>
        <CustomerUploadProgress open={upload} totalTime={totalTime} close={closeProgress} />
        <FileSizeToBig open={sizeToBig} handleClose={closeSizeToBig}/>
      </Grid>
   <Grid
   container
   direction='row'
   justifyContent='center'>
  
  
  
  
  <TextField
    variant='outlined'
    id = 'vendor'
    name='vendor'
    label='Vendor'
    sx={{margin: '1rem'}}
    value={formik.values.vendor}
    onChange={formik.handleChange}
    InputProps={{readOnly: false}}
  />
  
  <TextField
    variant='outlined'
    id = 'purchasePrice'
    name='purchasePrice'
    label='Purchase Price'
    type='number'
    sx={{margin: '1rem'}}
    InputProps ={{ startAdornment: <InputAdornment position='start'>$</InputAdornment>}}
    onChange={formik.handleChange}
    value={formik.values.purchasePrice}
  
  />
  
  
   <TextField
    label='Purchase Date'
    variant='outlined'
    id='purchaseDate'
    format='yyyy-MM-dd'
    type='date'
    sx={{margin: '1rem'}}
    value={formik.values.purchaseDate}
    onChange={formik.handleChange}
    InputLabelProps={{shrink: true}}
  /> 
  
  </Grid>
  <Grid
   container
   direction='row'
   justifyContent='center'>
  
  
  
  
  <TextField
    variant='outlined'
    id = 'numberOfAccounts'
    name='numberOfAccounts'
    label='Number of Accounts'
    type='number'
    sx={{margin: '1rem'}}
    value={formik.values.numberOfAccounts}
    onChange={formik.handleChange}
    InputProps={{readOnly: false}}
  />
  
  <TextField
    variant='outlined'
    id = 'faceValue'
    name='faceValue'
    label='Face Value'
    type='number'
    sx={{margin: '1rem'}}
    InputProps ={{ startAdornment: <InputAdornment position='start'>$</InputAdornment>}}
    onChange={formik.handleChange}
    value={formik.values.faceValue}
  
  />
  
  <Grid
  
  container
  direction='row'
  justifyContent='center'
  >
  
  <LoadingButton loading={loading} loadingPosition='end' disabled={formik.isSubmitting||!formik.dirty||!formik.isValid} variant="contained" color="primary" type='submit' endIcon= {<CheckIcon />} >
            Enter Batch Info
          </LoadingButton>
          
  
  </Grid>
  
   
  
  </Grid>  
  <Grid
  container
  direction='row'
  justifyContent='center'
  >
  <CSVReader
   onUploadAccepted ={handleOnDrop}
   onDragOver ={dragOver}
   onDragLeave = {dragLeave}
   config = {{header: true}}
     >
{csvDropDisplay}
 
  
  </CSVReader>
  
  </Grid>
  <Grid
  
  container
  direction='row'
  justifyContent='center'
  spacing={1}
  >
  
  <LoadingButton loading={loading} loadingPosition='end' disabled={disabled} variant="contained" color="primary" onClick={dataUploadClick} endIcon= {<CloudUploadIcon />} >
            Upload
          </LoadingButton>

  
  </Grid>
  
  <Grid
  container
  direction='row'
  justifyContent='center'>
  <h1> Number of accounts to add: {accountsNumToDisplay}</h1>
  
  </Grid>
  <Grid
  container
  direction='row'
  justifyContent='center'>
  <Button variant="contained" color="secondary" download='Customer_Upload_Final_with_examples_v2.1.xlsx' type='download' component={Link} href='/Customer_Upload_Final_with_examples_v2.1.xlsx' endIcon= {<SystemUpdateAltIcon />}>
            Template Download version 2.1 or Higher
          </Button>
  
  </Grid>
  
  </Box>
  
 

   )

    }
