import * as React from 'react'
import {Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer'
import axios from 'axios'
import { round } from '../misc/other'
import { formatted_date } from '../misc/dates'

const getPDFData = async (customer_number) =>{
    const promise = await axios.post('api/oracle5/pdfinfo', {customer_number: customer_number})
    .then((result)=>{
        if (result.status===200){
            let pulledUserData = {first_name: result.data[0],
                                  last_name:  result.data[1],
                                  street_address_1: result.data[2],
                                  street_address_2: result.data[3],
                                  city: result.data[4],
                                  state: result.data[5],
                                  zip: result.data[6],
                                  zip4: result.data[7],
                                  }
            return pulledUserData
        
            }
        
        
         else {console.log('Other than 200 result')} 


    }).catch(errors=>{console.log("Here are the errors", errors)})

    return promise
}

const BASE_URL = window.location.origin

export const getProps = async (data, originalAmmount)=>{
    let customer_number = data[0].customer_number
    let accounts = []
    let zip = ''
    let accountString = ''
    let total = round(originalAmmount)
        
    for(let l=0; l < data.length; l++){
    accounts.push(data[l])
    }
    for(let m=0; m<accounts.length; m++){
        accountString += `-Original Creditor: ${accounts[m].original_creditor}, Account Number: ${accounts[m].original_account_number} Original Amount: $${accounts[m].principal} Settlment Offer: $${round((total*accounts[m].per_of_settlement))} \n`;
              }
    
    const PDFUserData = getPDFData(customer_number).then((user)=>{return user}) 
    
    let userData = await PDFUserData
    userData.zip = userData.zip.slice(0,5)
    
    if (userData.zip4 === null ||userData.zip4 === '' ){
        zip = `${userData.zip}`
    }else{
        zip = `${userData.zip}-${userData.zip4}`
    }
    
    if (userData.street_address_2=== ''){
        userData.street_address_2 = null
    }
    
    
     
    return({customer_number: customer_number, 
            total: total, 
            accountString: accountString, userData: userData, zip: zip})


}

Font.register({family: 'Roboto', src: "https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2"})
let pagefont = 11





const styles = StyleSheet.create({
    page:{flexDirection: 'column',
    backgroundColor: 'white'

    },

    letterView: {
        
        margin: 2.5,
        padding: 1,        
        alignItems: 'center'
    },
    letterheadLogo:{
        width: 320,
        height: 70,
        margin: 2.5,     
                },
    addressView:{
        alignItems: 'center',
        flexDirection: 'column',
        color: 'gray',
        fontSize: 14
        
        
    },
    address:{
        fontSize: pagefont,
        color: '#100c08',           
        marginBottom: pagefont,
        marginLeft: 16

    },
    afterAddress:{
        marginBottom: pagefont,
        marginLeft: 16
    },
    body:{
        color: '#100c08',
        fontSize: pagefont,
        marginLeft: 16,
        marginRight: 16,
        marginBottom: pagefont
    },
    subjectLine: {
         marginBottom: 12,
         fontWeight: 'bold',
         fontSize: 12,
         marginLeft: 16

    },
    accountsLine:{
        color: '#100c08',
        fontSize: 9,
        marginLeft: 16,
        marginRight: 16,
        marginBottom: pagefont,
        lineHeight: 1.5
    },
    signature:{
        width: 200,
        marginLeft: 16
        

    },
    signatureStart:{
        color: '#100c08',
        fontSize: pagefont,
        marginLeft: 16,
        marginRight: 16,
        marginTop: pagefont
    },
    signatureBlock:{
        color: '#100c08',
        fontSize: pagefont,
        marginLeft: 16,
        lineHeight: 1.2
    }


})

export const SettlementLetterPDF = ({customer_number, total, accountString, userData, zip}) => (
    



    <Document>
        <Page size='LETTER' style={styles.page}>
        <View style={styles.letterView}>
            <Image style={styles.letterheadLogo} src={BASE_URL+'/SmallPNGForLetterheadv3.png'}/>
        </View>
        <View style={styles.addressView}>
            <Text>PO Box 15333</Text>
            <Text style={styles.afterAddress}>Spokane Valley, WA 99215-333</Text>
        </View>
        <View style={styles.address}>
            <Text>{formatted_date}</Text>
        </View>
        <View style={styles.address}>
            <Text>{userData.first_name} {userData.last_name}</Text>
             <Text>{userData.street_address_1}</Text>
             <Text>{userData.street_address_2}</Text>
             <Text>{userData.city}, {userData.state} {zip} </Text>
        </View>
        <View sytle={styles.afterAddress}>
            <Text style={styles.body}>Customer Number: {customer_number}</Text>
        </View>
        <View>
            <View>
               <Text style={styles.subjectLine}> SUBJECT: NOTICE OF SETTLEMENT LETTER FOR DEBT OF CUSTOMER: {customer_number} </Text> 
            </View>
            <View>
                <Text style={styles.body}>This letter is to inform you that your debt as described below is considered paid and settled.</Text>
            </View>
            <View>
                <Text style={styles.body}>Description of Account(s) below:</Text>
            </View>
            <View>
                <Text style={styles.accountsLine}>{accountString}</Text>                
            </View>
            <View>
                <Text style={styles.body}>The total settlement amount is: ${total}, as agreed upon for each account.
                                          The settlement amount has been paid and the debt is settled and no further payments or actions are required.  
                                          A 1099-C will be filed with the IRS if the difference from the Face Value and Settlement Amount is greater than $600.00 
                                          and may be counted as taxable income, however some people will be able to file exclusions or exceptions to this income.  
                                          Please contact a Certified Public Accountant for further Tax Advice on how to properly report this income.    </Text>
            </View>
            <View>
                <Text style={styles.body}>No other Debt or Collection Agency can collect on this debt. If you are contacted by another agency for the collection 
                                          of this debt please furnish them an electronic or paper copy of this letter. If the collection agency continues to harass 
                                          you on the payment of this debt you can contact The Federal Trade Commission via phone, website, or by mail and 
                                          report the malicious behavior to them.   </Text>
            </View>
            <View>
                <Text style={styles.body}>Since payment is received and processed the debt will be considered reconciled and now we will report to Equifax, 
                                          Transunion, and Experian that the debt has been settled. You have not been charged any more than what is noted in the 
                                          settlement amount, however you may have been charged an additional $5.00 mailing and processing fee to deliver any documents via 
                                          US Postal Mail. Any Marketing Promotional Offers that you used is not subject to the 1099-C. Any checking or credit card 
                                          information has not been stored on our servers and a third party has processed the transaction.  Even if you enter the 
                                          payment information on the website owned and operated by NameMySettlement.com or any of its parent companies or subsidies, 
                                          NameMySettlement.com will not have any electronic access to your checking account.  </Text>
            </View>
            <View>
                <Text style={styles.signatureStart}>Thank You,</Text>
            </View>
            <View>
                <Image style={styles.signature} src={BASE_URL+'/Signature.png'}/>
            </View> 
            <View>
                <Text style={styles.signatureBlock}>Brian Boyer</Text>
                <Text style={styles.signatureBlock}>Chief Executive Officer</Text>
                <Text style={styles.signatureBlock}>NameMySettlement.com</Text>
            </View>
            </View>

        

        </Page>
    </Document>

);