import * as React from 'react'
import {Button, 
        Dialog, 
        DialogTitle, 
        DialogContent, 
        DialogActions, 
        IconButton, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'    
import { styled } from '@mui/material/styles'
import { getProps, OfferLetterPDF } from '../letters/OfferLetter'
import FileSaver from 'file-saver'
import {pdf} from '@react-pdf/renderer'
import axios from 'axios'
import { PDFDate } from '../misc/dates'
import { ErrorAlert } from './ErrorAlert'

const CustomDialog = styled(Dialog)(({theme})=>({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
      },
  
  }));

  export const AcceptedSettlement = ({open, onClose, offerAccepted, data, gotoPayment}) =>{

    const context = JSON.parse(window.localStorage.getItem('context'))
       
        let customer_number = context.customer_number

        const getFillData = (data, offerAccepted) =>{
            
            
            let newData = []
            for(let i=0; i<data.length; i++){
                newData.push({
                    settlement_amount: (offerAccepted*data[i].per_of_settlement),
                    original_creditor: data[i].original_creditor,
                    original_account_number: data[i].original_account_number,
                    batch_number: data[i].batch_number
                })

            }
            return newData
        }
        const file_name = customer_number.toString() + '_OfferLetter_' + PDFDate + '.pdf'
        
        const blobData = {customer_number: customer_number,
            file_name: file_name,
            blob_file: null}

    let fillData = getFillData(data, offerAccepted)

    const handleClose = () =>{
        onClose()
    }

const handleClick = async () =>{
        

const PDFprops = await getProps(data, offerAccepted)
const doc =<OfferLetterPDF {...PDFprops}/>
const asPDF = pdf([])
asPDF.updateContainer(doc)
const blob = await asPDF.toBlob()

FileSaver.saveAs(blob, blobData.file_name)

axios({
    method: 'POST',
    url: 'api/oracle4/upload',
    headers: {'Content-Type': 'application/octet-stream'},
    params:{fileName: file_name,
            customer_number: customer_number, 
            autogenerated: 1,
            email: context.email
            },
    data: blob
    
}).then(response=>{
    if (response.status===200){
        gotoPayment()
        onClose()
    } else {
        
        let e =('Here is the error in response from the upload call: ', response.statusText)
        return ErrorAlert(e)
    }
}).catch(error=>{
    let err=('Here are the errors with the axios upload call: ', error)
    return ErrorAlert(err)
})





axios({
    method: 'POST',
    url:'api/oracle5/confirm',
    data: {customer_number: customer_number,
           data: fillData,
          file_name: file_name}

  }).then(result=>{
    if (result.status===200){
      
      gotoPayment()
        onClose()
    }
  })
  .catch(errors=>{
    let errs=('Here are the errors in the confirmation call:',errors)
    return ErrorAlert(errs)
})

}

return(

    <CustomDialog open={open} onClose={handleClose}>
        <DialogTitle>
        Your Offer Is Accepted!!!
        </DialogTitle>
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
             <CloseIcon />
            </IconButton>  
        <DialogContent dividers>
            <Typography>
            Please click the button below to download your offer letter.
          </Typography>
          <Typography variant='h6' align='center'>
            Your settled amount is: ${offerAccepted}
          </Typography>
          <Typography gutterBottom>
            Confirm and print your offer letter. 
          </Typography>
            </DialogContent>
            <DialogActions>
          <Button autoFocus onClick={handleClick} color="primary">
            Confirm and Print
          </Button>
        </DialogActions>
    </CustomDialog>
)


  }