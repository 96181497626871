import * as React from 'react'
import * as yup from 'yup'
import { Navigate} from 'react-router-dom'
import { Dialog, TextField, DialogTitle,Button, DialogContentText, Grid, useMediaQuery, useTheme, Divider } from '@mui/material'
import { useFormik } from 'formik'
import axios from 'axios'
import { lowercaseregex, UpperCaseRegex, numberRegex, specialCharacterRegex } from '../utility/regex'
import { useAuth
 } from '../misc/auth'
import { ErrorAlert } from './ErrorAlert'
import LockIcon from '@mui/icons-material/Lock';


const validationSchema2 =  yup.object().shape({
    email: yup.string().email("Email needs to include an @ and a . for registering"),
password: yup.string().matches(UpperCaseRegex, "Please include 1 UPPER CASE character")
              .matches(lowercaseregex, "Please include a lower case characters")
              .matches(numberRegex, 'Pl3as3 1nclude a Numb3r 0-9')
              .matches(specialCharacterRegex, 'Ple@$e include a $pec!@l Ch@r@cter (#,%&^)') 
              .min(8, 'Minimum 8 characters required')
              .required("Please enter a Password"),
confirmPassword: yup.string().oneOf([yup.ref('password')],"Please check to make sure this password matches your previously entered password.").required("Please confirm your previous password here.")

})


export const SetPassword = ({open, accounts, stopLoading}) =>{
    
    const theme = useTheme()
    const { login } = useAuth()
    const [loggedIn, setLoggedIn] = React.useState(false)
    const [accountPath, setAccountPath] = React.useState('')
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))
    
    



    
    

    const formik2 = useFormik({
        
        initialValues:{
            email: accounts.email,
            password: '',
            confirmPassword: ''
        },
        validationSchema: validationSchema2,
        onSubmit: (values, actions) =>{
            let path = '/user/accounts/' + values.email
            
            setAccountPath(path)

            
            axios({
                method: 'POST',
                url:'api/oracle1/submit',
                data: {customer_number: accounts.customer_number,
                        email: values.email,
                        first_name: accounts.first_name,
                        last_name: accounts.last_name,
                        street_address_1: accounts.street_address_1,
                    street_address_2: accounts.street_address_2,
                    city: accounts.city,
                    state: accounts.state_abr,
                    zip: accounts.zip,
                    zip4: accounts.zip4,
                    phone_number: Number(accounts.phone_number),
                    password: values.password,
                    address_verified: accounts.address_verified}
            }).then( result=>{
                if (result.status===200){
                    
                    let newContext = { 
                        admin: 0,
                        city: accounts.city,
                        customer_number: accounts.customer_number,
                        email: values.email,
                        first_name: accounts.first_name,
                        last_name: accounts.last_name,
                        state_abr: accounts.state_abr,
                        street_address_1: accounts.street_address_1,
                        street_address_2: accounts.street_address_2,
                        zip: accounts.zip,
                        zip4: accounts.zip4,
                        phone: accounts.phone_number,
                        mail_marketing: 0,
                        email_marketing:1,
                        address_verified: accounts.address_verified

                         }
                        window.localStorage.setItem('context', JSON.stringify(newContext)) 
                        login(result.data)
                        setLoggedIn(true)
                        
                        
                    
                    
                }
    
                
    
            })
            .catch(errors=>{
                
                actions.setSubmitting(false)
                let err = ('Here are the errors when confirming the accounts: ',errors)
                stopLoading()
                return ErrorAlert(err)
            })
    
        }
    
       })

    if(accounts===null){
        formik2.initialValues.email=''
    } else {
        formik2.initialValues.email=accounts.email
        
    }

       if (loggedIn){
        return <Navigate to={accountPath} />
       }
       return(
        <Dialog open={open} fullWidth aria-labelledby='form-dialog-title' fullScreen={fullscreen} >
        <DialogTitle id='form-dialog-title' sx={{backgroundColor: 'secondary.main', color: 'white'}}>
            Enter Email and Password
        </DialogTitle>
        <Divider />
        <DialogContentText color='black' sx={{mt:'1rem', ml:'1rem'}}>
            Confirm Email and Enter Password Twice.  
        </DialogContentText>
        <Divider sx={{mt: '1rem'}}/>
        <form onSubmit={formik2.handleSubmit}>
        <Grid container direction='column' sx={{mt: '2rem', ml: '1rem'}}>
        <Grid item  xs={12} sm={12} md={8} lg={6}>   
        <TextField
        
        sx={{mb: '2rem', width:'80%'}}
        margin='none'
        type='email' 
        id ='email'
        name='email'
        label= 'Email'
        value={formik2.values.email}
        onChange={formik2.handleChange}
        error={Boolean(formik2.errors.email)}
        helperText={formik2.errors.email}
        />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}> 
        <TextField
        fullWidth
        sx={{mb: '2rem', width:'80%'}}
        autoFocus
        margin='none'
        type='password' 
        id ='password'
        name='password'
        label= 'Password'
        value={formik2.values.password}
        onChange={formik2.handleChange}
        error={formik2.touched.password && Boolean(formik2.touched.password)}
        helperText={formik2.errors.password}
        />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
        <TextField
        fullWidth
        sx={{mb: '2rem', width:'80%'}}
        margin='none'
        // variant='Standard'
        type='password' 
        id ='confirmPassword'
        name='confirmPassword'
        label= 'Confirm Password'
        value={formik2.values.confirmPassword}
        onChange={formik2.handleChange}
        error={formik2.touched.confirmPassword && Boolean(formik2.errors.confirmPassword)}
        helperText={formik2.errors.confirmPassword}
        />
        </Grid>
        <Grid item>
    <Button
            
            onClick={formik2.handleSubmit} 
            type ='submit'
            sx={{mb: '4rem', ml: '6rem', mr: '6rem'}} 
            variant='contained' 
            color='secondary'
            disabled ={formik2.isSubmitting||!formik2.isValid||!formik2.dirty}
            endIcon={<LockIcon />}
            >
            Submit</Button>
                </Grid>
                </Grid>
                </form> 
    </Dialog>
        

       

)}