import * as React from 'react'
import { Typography, Box } from '@mui/material'


export const AdminPayment = () =>{

    return (
        <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
            <Typography>You have hit the Payment page</Typography>
        </Box>
    )
}