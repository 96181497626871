import * as React from 'react'
import { Dialog, DialogTitle, Button, DialogContent, DialogActions, Typography  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import {styled} from '@mui/material/styles'


const CustomDialog = styled(Dialog)(({theme})=>({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
      },
  
  }));

  
  export const AlreadySetUp = ({open, handleClose}) => {


    return(

        <CustomDialog 
        open={open}
        onClose={handleClose}>
            <DialogTitle sx={{padding: 1, mr: '3rem'}} onClose={handleClose}>
            
            Your Account is Already Set Up
            </DialogTitle>
            <DialogContent dividers>
            <Typography variant='body1'gutterBottom>
              Please login using your email and Password.
            </Typography>
            <Typography gutterBottom>
              If you have forgotten either please use the feedback button below.
            </Typography>
            <Typography gutterBottom>
              If you are a hacking robot go away and don't come back.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary" endIcon={<CloseIcon />}>
              Close
            </Button>
          </DialogActions>

    </CustomDialog>
   
    )
} 
