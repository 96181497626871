export const adminFeedbackRequest = new Request('/api/oracle2/getfeedback' ,{method: 'GET'})

const baseURL = window.localStorage.getItem('BASE_URL')

export const accountLoader = async (email) =>{ 
    
     return await fetch(baseURL + '/api/oracle3/getaccounts',{method: 'POST', headers:{
        'Content-Type': 'application/json'
     }, body: JSON.stringify({email: email})})}

export const confirmationLoader= async (customer_number) =>{
   return await fetch(baseURL + '/api/oracle1/confirmation',{method: 'POST', headers:{
      'Content-Type': 'application/json'
   }, body: JSON.stringify({customer_number: customer_number})})


}
