import * as React from 'react'
import {Alert, AlertTitle, Stack} from '@mui/material'




export const SuccessAlert = (message) =>{

    return(
        <Stack sx={{width: '100%'}} spacing={2}>
            <Alert severity='success' onClose={()=>{}}>
                <AlertTitle>Success</AlertTitle>
                <strong>{message} </strong>
            </Alert>
        </Stack>
    )
}