import * as React from 'react'
import { useLoaderData, Navigate,
        Link as RouterLink
 } from 'react-router-dom'

import { Paper, Typography, Box, Grid, Breadcrumbs, Link } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { setData } from '../../../misc/other'
import axios from 'axios'
import CheckIcon from '@mui/icons-material/Check'



const columns = [
    {field: 'id', headerName:'ID', flex: 1},
    {field: 'original_creditor', headerName: 'Originator', flex: 4.5 },
    {field: 'original_account_number', headerName: 'Account #', flex: 2},
    {field: 'account_balance', description: 'This is not the ammount you will pay after you Name Your Settlement',
    headerName: 'Balance ($)', flex: 4 },
    {field: 'account_status', headerName: 'Status', flex: 4}
]

export const AdminUserAccounts = () => {

let loaderData = useLoaderData()

const data = setData(loaderData)

console.log('Here is the data: ', data)

const [accountSelected, setAccountSelected] = React.useState([])
    const [accountStatus, setAccountStatus] = React.useState(null)
    const [disableButton, setDisableButton] = React.useState(true)
    const [totalDue, setTotalDue] = React.useState(0)
    const [pathName, setPathName] = React.useState('/admin/custumercare/confirmaccounts')
    const [isReady, setIsReady] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)

    React.useLayoutEffect(()=>{
        
        const whereToGo =(account_status)=> {
            switch(account_status) {
                
                case 'Settled':
                case 'RECURRING': 
                case 'Money Order':                   
                    setPathName('/admin/customercare/payment');
                    break
                case '1099-C':    
                case 'Already_Settled' :
                case 'Bankruptcy' :
                case 'Deceased' :
                case 'Ready to Settle' :    
                case 'Wrong_Identity' :
                
                                 
                    setPathName('/admin/customercare/confirmaccounts')
                    break
                
                case 'PAID':
                case 'Reconciled':
                    
                   return setDisableButton(true);
                
                case 'Confirmed' :
                        
                    setPathName('/admin/customercare/settlement')
                    break
                
                default: 
                setPathName('/admin/customercare/confirmaccounts')
                break
                }
        
        }


            if(accountStatus == null){console.log('Do nothing')
            
            }
                    
            else{
        
        
    for(let j=0; j<accountSelected.length; j++){
     if (accountSelected[j].account_status!==accountStatus){
        setDisableButton(true)

    }else{
        
        setDisableButton(false)
        whereToGo(accountStatus)}
}

            }
    
    },[accountSelected, accountStatus])


    const buttonClick = () => {
        setIsSubmitting(true)
        let almostReadyAccounts = accountSelected
       
        for(let r=0; r<almostReadyAccounts.length; r++){
            almostReadyAccounts[r].per_of_settlement =Number((almostReadyAccounts[r].account_balance/totalDue).toFixed(2)) 
        }
        
        setAccountSelected(almostReadyAccounts)

        axios({
            method: "POST",
            url: 'api/oracle3/persettlement',
            data: {almostReadyAccounts}
        }).then(res=>{
            
            for(let m=0; m<almostReadyAccounts.length; m++){
                almostReadyAccounts[m].settlement_per = res.data[m].settlement_per
               
            }
            setAccountSelected(almostReadyAccounts)
            setIsReady(true)

        })

        
    }

    const rowClick = (row) =>{
        
           
        if(!row.length){setDisableButton(true)}

        else{
        
        let rowsSelected = []
        let i = 0
        let total = 0    
        
        while (i < (data.length)){           
        if (row.includes(data[i].id)){
                      rowsSelected.push(data[i])
                      total += data[i].account_balance
                      setAccountSelected(rowsSelected)
                      setAccountStatus(rowsSelected[0].account_status)
                    
                      setTotalDue(total)
                      
                      
        }
        
        i++
        
           }
           
        }
    }

    let accountStatusWarning;
if(isReady) return(<Navigate  to={pathName} state={{accounts: accountSelected, totalDue: totalDue}}/>)

    if (disableButton) 
        {accountStatusWarning = <React.Fragment><Grid container direction='row' justifyContent='flex-end'><Grid item lg={6} md={8} sm={10}> <Typography variant='h6'color='primary' gutterBottom={true}> *Select items with the same account status or select one debt.</Typography> </Grid></Grid></React.Fragment>}
        else{<Grid></Grid>}

return(
    <Box sx={{minHeight:'75vh', mt: 15, mb: 2}}>
        <Breadcrumbs sx={{mb: 3}}>
        <Link underline='hover' component={RouterLink} to='/admin/dashboard'>Dashboard</Link> 
        <Link underline='hover' component={RouterLink} to='/admin/customercare'>Search </Link> 
        <Link underline='hover' component={RouterLink} to='/admin/customercare/confirm'>Confirm Info</Link>
    </Breadcrumbs>  
        <Grid container direction='row'>
            <Grid item xs={false} sm={1} md={1} lg={2}></Grid>
            <Grid item xs sm={11} md={11} lg={9} xl={8}>
                <Typography color='secondary' variant='h6'  gutterBottom={true}>“Check the box(s) to the left of the items you want to settle, then click CONTINUE at the bottom of this page. All items selected must have the same status</Typography>
            </Grid>
        </Grid>  

   <Grid container direction='row' justifyContent='center' alignItems='center' alignContent='center'>
    <Grid item xs sm={9} md={9} lg={8} >
        <Paper>
            <DataGridPro
                   rows={data}
                   columns={columns} 
                   autoPageSize={true} 
                   checkboxSelection 
                   columnBufferPx={150} 
                   hideFooter={false}
                   onRowSelectionModelChange={rowClick} 
                                    
                   />
        </Paper>
    </Grid>
   </Grid>
<Grid container direction='row' sx={{
    mt: 2
}}>
<Grid item xs={6} sm={8} md={8} lg={9}>
</Grid>
<Grid item>
<LoadingButton
        loading={isSubmitting}
        loadingPosition='end'
        variant='contained' 
        color='secondary'
        size = 'large'
        onClick={buttonClick}
        type = 'button' 
        disabled={disableButton || !accountSelected.length} 
        endIcon= {<CheckIcon/>}>
            CONTINUE</LoadingButton> 
</Grid>
{accountStatusWarning}
</Grid>
<Grid container direction='column' alignItems='center' justifyItems='center' > 
</Grid>
</Box>
)

}