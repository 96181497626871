import * as React from 'react'
import axios from 'axios'
import {useFormik} from 'formik'
import {LoadingButton} from '@mui/lab'
import { TextField, Grid } from '@mui/material'
import { Navigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import * as yup from 'yup'
import SearchIcon from '@mui/icons-material/Search';
import { ErrorAlert } from '../alerts/ErrorAlert'
import { SearchAlert } from '../alerts/SearchAlert'
import { GetRecapchaSiteKey } from '../misc/other'

const validationSchema = yup.object().shape({
    custNumber: yup.number()
                    .positive()
                    .required('Customer Number is required')
                    .min(10000000,"Not enough numbers please enter 8 digits and no decimal points (.) ")
                    .max(9999999999, "Too many numbers we can only accept up to 10 digits and no decimal points (.)")
                    .integer("No Periods/Decimal Points(.)"),
    last_name: yup.string().required(),
    recaptcha: yup.string().required("Please confirm you are not a robot")

})

export const MainSearchForm=()=>{
  
  const path = window.localStorage.getItem('BASE_URL')
  const RecaptchaSiteKey = GetRecapchaSiteKey(path)
  
  
    const params = new URLSearchParams(window.location.search)
    const last_name = params.get('last_name')
    const customer_number = params.get('customer_number')
    let initialLastName;
  
    if(last_name){
      initialLastName = last_name
  
    } else {
      initialLastName = 'Davis-Martin III'
    }
  
    let initialCustomerNumber;
  
    if(customer_number){
      initialCustomerNumber = customer_number
      
    } else {
      initialCustomerNumber = 0
      
    }
  
      
     
  
      const [open, setOpen] = React.useState(false)
      const [confirming, setConfirming] = React.useState(false)
      const [confirmingData, setConfirmingData] = React.useState({})
      
      
      const handleClose = () =>{
          setOpen(false)
  
      }
  
      
      
      
    
    
  
         
      React.useEffect(()=>{
          const script = document.createElement("script");
          script.src = "https://www.google.com/recaptcha/api.js";
          script.async = true;
          script.defer = true;
          document.body.appendChild(script);
      
      },[])
  
      const formik = useFormik({
          initialValues:{
              custNumber: initialCustomerNumber,
              last_name: initialLastName,
              recaptcha: ''
          },
      validationSchema: validationSchema,
      onSubmit: (values, actions) => {
       
        actions.setSubmitting(true)
        axios({
          method: 'POST',
          url: 'api/oracle1/search',
          data: {customer_number: values.custNumber.toString(), last_name: values.last_name}
  
        })
        .then( async result=>{
           
          if(result.status===200){
           try{
  
  
  
             let a;
             let b;
             a = result.data[0]
             b = result.data[1]
             axios({
                          method: 'POST',
                          url: 'api/oracle1/confirmation',
                          data: {customer_number: a}
                      })
                      .then( async result=>{
                         
                        
                          
                          let accounts =  {
                                             first_name: await result.data.first_name,
                                             last_name: b,
                                             street_address_1: await result.data.street_address_1,
                                             street_address_2: await result.data.street_address_2,
                                             city: await result.data.city,
                                             state: await result.data.state,
                                             zip: await result.data.zip,
                                             zip4: await result.data.zip4,
                                             phone_number: await result.data.phone_number,
                                             email: await result.data.email,
                                             customer_number: a,
                                             account_setup: await result.data.account_setup
                  
                          }
                          
                         
                          setConfirmingData(accounts)
                          
                          window.localStorage.setItem('context', JSON.stringify(accounts))
                         setConfirming(true)
                          actions.setSubmitting(false)
                          
                      
                      }).catch(errors=>{
                        
                          if(errors){
                              let accounts =  {
                                  first_name: '',
                                  last_name: 'Already Set Up',
                                  street_address_1: 'Some Address',
                                  street_address_2: 'Some Address 2',
                                  city: 'City of God',
                                  state: 'ID',
                                  zip: '90210',
                                  zip4: '',
                                  phone_number:8004127777,
                                  email: 'email@joy.com',
                                  customer_number: a,
                                  account_setup: 1
              
               }
               setConfirmingData(accounts)
               
              window.localStorage.setItem('context', JSON.stringify(accounts))
              setConfirming(true)
              actions.setSubmitting(false)
              }
            })  
            
            
           }
           catch (e){let errorMessage = ('here is the error in the search call: ', e.message)
                    return ErrorAlert(errorMessage)
          }
                 
                  
          }else{
            actions.setSubmitting(false)
            setOpen(true)
          }
        })
        .catch(err=>{
          actions.setSubmitting(false)
          setOpen(true)
          let errMess = ('Here is the error message of the caught error in the search call: ', err.message)
          return ErrorAlert(errMess)
        })
  
  
         
          
          
          
      }
  })
  
  const recaptchaChange =(value)=>{
    formik.setFieldValue('recaptcha', value)
  }
    if (confirming){
      
      return <Navigate to='/confirm' state={{confirmingData: confirmingData }} />} 
  
  
      return(
          <Grid container
            direction='row'
            justifyContent='center'
            alignItems='center'
                              
            
           >
          <form onSubmit={formik.handleSubmit}>
          
            <Grid item xs={12} sm={12} lg={12}>
          <TextField
          sx={{mr:4, mb: 4}}
          margin='none'
          variant='outlined' 
          id ='custNumber'
          name='custNumber'
          label= 'Account Number Search'
          type='number'
          value={formik.values.custNumber}
          onChange={formik.handleChange}
          error={formik.touched.custNumber && Boolean(formik.errors.custNumber)}
          helperText={formik.errors.custNumber}
          />
          
          <TextField
          sx={{mr:2, mb: 4}}
          margin='none'
          variant='outlined'
          id = 'last_name'
          name= 'last_name'
          label='Your Last Name'
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.touched.last_name && Boolean(formik.errors.last_name)} 
          helperText={formik.errors.last_name}
          />
         
       
      <LoadingButton variant = 'contained' 
                     color='primary'
                     size='large'
                     loading={formik.isSubmitting}
                     loadingPosition='end' 
                     endIcon={<SearchIcon />} 
                     disabled = {formik.isSubmitting||!formik.isValid ||!formik.dirty } 
                     sx={{ borderSpacing: 2, marginLeft: 2, mb:4 }} 
                     type='submit' 
                     value='submit'>
                  Continue
              </LoadingButton>
              
              </Grid>         
      <Grid container
      direction='row'
      justifyContent='center'
      alignItems='center'>
        <Grid item xs={12} sm={12} lg={8}>
          <ReCAPTCHA
              sitekey={RecaptchaSiteKey}
              onChange={recaptchaChange}
          />
          </Grid>
          </Grid>
          
      
  
          
      
     
  
          </form>
          <Grid>
            <SearchAlert open={open} handleClose={handleClose}/>
          </Grid>
          </Grid>
          
      
      )
  
  
  }