import * as React from 'react'
import { round } from '../misc/other'
import { Dialog, TextField, DialogTitle,Button, DialogContentText, Typography, IconButton, DialogContent, DialogActions } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'   

import axios from 'axios'



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  




export const UserPromo = ({open, original_ammount, settotaldue, customer_number, setpromocodesused, close, accounts, setrecurringpaymentamount, numberofpayments}) =>{
    const theme = useTheme()
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))
    const [promoCodeMessage, setPromoCodeMessage] = React.useState('')
    const [promoCodeSubmit, setPromoCodeSubmit] = React.useState(false)
    const [promoModuleDisplay, setPromoModuleDisplay] = React.useState(false)
    const [disabledButton, setDisabledButton] = React.useState(false)
    const [promoCode, setPromoCode] = React.useState('Promo Code')
    const [promoError, setPromoError] = React.useState(false)
    const [promoCodesArray, setpromoCodesArray] = React.useState([])
    const [newTotalDue, setNewTotalDue] = React.useState(original_ammount)
    
    React.useEffect(()=>{
        if(promoCodesArray.length>0){
       
      let ammount = original_ammount 
        
        
  
  
      
  for(let a=0; a<promoCodesArray.length; a++){
      setPromoModuleDisplay(true)
         
      ammount -= (round(ammount * promoCodesArray[a].percentage))
     
      
      settotaldue(ammount)
      setNewTotalDue(ammount)
      let recurringpaymentamount = ammount/numberofpayments
      setrecurringpaymentamount(recurringpaymentamount)
        
  
  
  }
        } else {
            setPromoModuleDisplay(false)
        }
  
  
    },[promoCodesArray, original_ammount,numberofpayments])

    
    React.useEffect(()=>{
        axios({
            method: 'POST',
            url: 'api/oracle3/userpromocodes',
            data: {customer_number: customer_number}
        })
        .then(result=>{
         let promoCodesArray = result.data.promoCodes
         setpromoCodesArray(promoCodesArray)
    
        
         
        })
         
    
    
         
    
    
     },[customer_number])  

     const closeAlert = () =>{
        close()

     }


    const onPromoChange = (enteredPromo) => {
        setPromoCode(enteredPromo.target.value)
        
}
    

   

    let promoCodeDisplay; 
    if(promoModuleDisplay){promoCodeDisplay = <Typography  sx={{mt: '1rem', ml: '6rem'}} variant='h6'>Promo codes used: {promoCodesArray.map((d)=>
        <li key={d.id}><b>{d.promoCode}</b>-{d.percentage*100}%</li>)} </Typography> }


    const checkPromoCode = () => {
        setDisabledButton(true)
        setPromoCodeSubmit(true)
        let indexValue = promoCodesArray.findIndex((e)=>e.promoCode === promoCode)
        
        
        
        if(indexValue>=0){
            setPromoCode('Already Used')
            setPromoError(true)
            setPromoCodeMessage('Already Used Promo Code')
            setDisabledButton(false)
            setPromoCodeSubmit(false)
        }else{
            
            
                if(promoCode.length>=5){
                    setPromoError(false)
                    axios({
                        method: 'POST',
                        url: 'api/oracle3/confirmpromo',
                        data: {promo_code: promoCode,
                               data: accounts}
                    })
                    .then(response=>{
                        if(response.data ==='Promo Code not valid.'){
                            
                            setPromoCodeMessage('Invalid Promo Code Try Again')
                            setPromoError(true)
                            setDisabledButton(false)
                            setPromoCodeSubmit(false)
                        }else{
                           setPromoError(false)
                            let promoCodeArray = promoCodesArray
                            let id = promoCodeArray.length+1
                            let newPromoCode = response.data
                            newPromoCode = {id:id,...newPromoCode}
                            
                            
                          promoCodeArray.push(newPromoCode)
                            
                          
                          
                          


                            
                            
                            setPromoCodeMessage('Promo Accepted, please enter another one')
                            setpromoCodesArray(promoCodeArray)
                            setpromocodesused(promoCodesArray)
                            
                            let ammount = original_ammount 
                            for(let a=0; a<promoCodesArray.length; a++){
                                setPromoModuleDisplay(true)
                                   
                                ammount -= (round(ammount * promoCodesArray[a].percentage))
                               
                                
                                settotaldue(ammount)
                                setNewTotalDue(ammount)
                                let recurringpaymentamount = ammount/numberofpayments
                                setrecurringpaymentamount(recurringpaymentamount)      
                            
                            
                            }



                            
                            setPromoModuleDisplay(true)
                            setDisabledButton(false)
                            setPromoCodeSubmit(false)


                    
                    } 
                    })
                }else{ 
                    setPromoError(true)
                    setDisabledButton(false)
                    setPromoCodeMessage('Please enter at least 5 characters')
                }
            setPromoError(true)
            setDisabledButton(false)
            setPromoCode(promoCode.replace(/ /g, ""))
            
    }

       
            
            
           
        }
    
    
    
    
    
        
    
    






    
    

    

   

       
       return(
        <BootstrapDialog fullScreen={fullscreen} onClose={close} open={open} aria-labelledby='form-dialog-title' >
        <DialogTitle  id='form-dialog-title' >
           Enter Promo Code
        </DialogTitle>
        <IconButton aria-label="close"
            onClick={close}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
       <DialogContent dividers sx={{justifyContent: 'center', justifyItems: 'center', justifySelf: 'center'}}>
        <DialogContentText sx={{mb: '2rem',ml: '6rem', mr: '6rem', mt: '2rem'}} color='black'>
            Please enter a Promo Code if you don't have one try PROMO20. Promo Codes are stackable. 
        </DialogContentText>
              
        <TextField
        
        sx={{mb: '2rem', ml: '6rem'}}
       color='primary'
        type='text'
        marign='none'
        variant='outlined'
        id='promo'
        name='promo'
        label='Promo Code'
        value={promoCode}
        onChange={onPromoChange}
        error={promoError}
        disabled={disabledButton}
        helperText ={promoCodeMessage}
        />
        
        <Typography
        sx={{ml: '6rem'}}
        variant='h4'
        color='secondary'
        >Total Due: $ {newTotalDue.toFixed(2)}</Typography>
       
        
            {promoCodeDisplay}
            </DialogContent>   
           <DialogActions>
    <LoadingButton
            loading={promoCodeSubmit}
            loadingPosition='end' 
            
            variant='contained' 
            color='secondary'
            onClick={checkPromoCode}
            >
                Apply</LoadingButton>
                
                
        <Button
        
        variant='contained'
        color='primary'
        onClick={closeAlert}>Back to Payment</Button>
        </DialogActions>
                
                
    </BootstrapDialog>
        

       

)}