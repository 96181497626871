import * as React from 'react'
import { 
    Typography, 
    Grid, 
    TextField, 
    Select,
    InputLabel, 
    FormControl,
    ListSubheader,
    MenuItem, 
    Button, 
    InputAdornment,
    ToggleButton,
    ToggleButtonGroup } from '@mui/material'
import { LoadingButton } from '@mui/lab';    
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import {MuiTelInput, matchIsValidTel} from 'mui-tel-input'
import * as yup from 'yup'
import {Navigate, useLocation} from 'react-router-dom'
import {pdf} from '@react-pdf/renderer'
import {useFormik} from 'formik'
import FileSaver from 'file-saver'
import axios from 'axios'
import { RoutingTool } from '../utility/connections'
import { SettlementLetterPDF, getProps } from '../letters/SettlementLetter'
import { PDFDate, dayOfMonth } from '../misc/dates'
import { UserPromo } from '../alerts/UserPromo'
import { ErrorAlert } from '../alerts/ErrorAlert'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'






var RoutingToolData = new FormData()
RoutingToolData.append('secret_key', RoutingTool.secretKey)
RoutingToolData.append('public_key', RoutingTool.publicKey)

const validationSchema = yup.object().shape({
    accountNumber: yup.string().required("Please enter your Bank Account Number").min(2,"Please Enter a Bank Account"),
    fullName: yup.string()
                    .required("Please enter your Full(First and Last) Name")
                    .min(5, "Please enter a valid Full Name")
                    .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Please enter your full name.'),
    address1: yup.string().min(5, 'Please enter a valid address'),
    address2: yup.string().nullable(),
    city: yup.string().min(3, 'Please enter a valid city name'),
    zip: yup.string().min(5,'Please enter 5 numbers').max(5, 'Please enter only 5 numbers'),
    zip_plus4: yup.string().min(4, 'Please enter 4 numbers').max(4, "Please enter only 4 numbers"),
    email: yup.string().email("Email needs to include an @ and a . for registering")
})




export const MFPayment = () =>{
let location = useLocation()
const context = JSON.parse(window.localStorage.getItem('context'))
const initialPhone = '+1' + context.phone



const[paidInFull, setPaidInFull] = React.useState(false)
const[recurringPaymentSetup, setRecurringPaymentSetup] = React.useState(false)
const [phoneNumber, setPhoneNumber] = React.useState(initialPhone)
const [phoneToStore, setPhoneToStore] = React.useState(context.phone)
const [phoneError, setPhoneError] = React.useState(false)
const [phoneErrorMessage, setPhoneErrorMessage] = React.useState('')
const [errorInAttempt, setErrorInAttempt] = React.useState(false)
const [mailDocs, setMailDocs] = React.useState(0)
const [mailOption, setMailOption] = React.useState(false)
const [totalDue, setTotalDue] = React.useState(location.state.totalDue)
const customerNumber = context.customer_number
const [promoCodesUsed, setPromoCodesUsed] = React.useState([])
const [promoAlert, setPromoAlert] = React.useState(false)
const[recurringPaymentAmount, setRecurringPaymentAmount] = React.useState((totalDue/3).toFixed(2))
const [numberOfPayments, setNumberOfPayments] = React.useState(3)
const [routingNumber, setRoutingNumber] = React.useState('')
const [bankName, setBankeName] = React.useState('')
const [paymentLoading, setPaymentLoading] = React.useState(false)
const [routingValidated, setRoutingValidated] = React.useState(false)
const [paymentType, setPaymentType] = React.useState('oneTime')
const [moneyOrder, setMoneyOrder] = React.useState(false)

const originalAmmount = location.state.totalDue
const accounts = location.state.accounts
const file_name = customerNumber.toString() +'_SettlementLetter_'+ PDFDate + '.pdf'


const getFillData =(data, offerAccepted ) =>{
    let newData = []

    for(let i=0; i<data.length; i++){
        newData.push({
            settlement_amount:(offerAccepted*data[i].per_of_settlement),
            original_creditor: data[i].original_creditor,
            original_account_number: data[i].original_account_number,
            batch_number: data[i].batch_number
        })
    }
    return newData

}

let fillData = getFillData(location.state.accounts, location.state.totalDue)



 const handlePhoneChange = (newValue, info) =>{
    
    setPhoneNumber(newValue)
    
    if(matchIsValidTel(info.numberValue)){
        setPhoneToStore(info.nationalNumber)
        
        setPhoneError(false)
        setPhoneErrorMessage("")
    }else{
        setPhoneError(true)
        setPhoneErrorMessage('Please enter a valid phone number')
    }

}

const closeAlert =() =>{
    setPromoAlert(false)

}

const paymentChange = (event, newType) =>{
    setPaymentType(newType)
    
   
}


const changeInMail = () =>{

let newBoolean = !mailOption

setMailOption(newBoolean)

setTimeout(()=>{if(newBoolean){

let newamount = totalDue + 5
setTotalDue(newamount)

setMailDocs(1)}
else{

 let newmount = totalDue-5
 setTotalDue(newmount)
 
 
 setMailDocs(0)
}}, 500)



}

const promoButtonOnClick = () =>{
    setPromoAlert(true)
}

const changeRecurringPaymentTimes = (event) => {
        
    let amountDue = (totalDue/event.target.value).toFixed(2)
    setRecurringPaymentAmount(amountDue)
    setNumberOfPayments(event.target.value)
    
}

const routingNumberCheck = (event)=> {
       
    setRoutingNumber(event.target.value.toString())
    
}

const PDFfunction = async() =>{

    const PDFprops = await getProps(location.state.accounts, originalAmmount)
    const doc =<SettlementLetterPDF {...PDFprops} />
    const asPDF = pdf([])
    asPDF.updateContainer(doc)
    const blob = await asPDF.toBlob()
    FileSaver.saveAs(blob, file_name)

    axios({
        method: 'POST',
        url: 'api/oracle4/upload',
        headers: {'Content-Type': 'application/octet-stream'},
        params:{fileName: file_name,
                customer_number: customerNumber,
                autogenerated: 1,
                email: context.email},
        data: blob
        
    }).then((response)=>{
        
        if (response.status===200){
            axios({
                method: 'POST',
                url:'api/oracle5/confirm',
                data: {customer_number: customerNumber,
                       data: fillData,
                      file_name: file_name}
            
              }).then(result=>{
                if (result.status===200){
                  
                  setPaymentLoading(false)
                  setPaidInFull(true)
                    
                }
              })
              .catch(errors=>{
                let errorMess = ('Here are the errors in the confirmation call:',errors)
                return ErrorAlert(errorMess)
            })
        } else {
            let errMess = ('Here is the error in response from the upload call: ', response.statusText)
            setErrorInAttempt(true)
            return ErrorAlert(errMess)
        }
    }).catch(error=>{
        let errorMessage = ('Here are the errors with the axios upload call: ', error)
        setErrorInAttempt(true)
        return ErrorAlert(errorMessage)
    })

    


}

const moneyOrderButtonClick = ()=>{
    axios({
        method: "POST",
        url: "api/oracle3/moneyorder",
        data: {data: location.state.accounts,
               balance: totalDue+5,
               promo_code_used: promoCodesUsed
            }
        }).then(result=>{
                if(result.data==="Pending MoneyOrder"){
                    setMoneyOrder(true)
                }
                else{
                    setErrorInAttempt(true)
                    setPaymentLoading(false)

                }
            })
            .catch(errors=>{
                
                setErrorInAttempt(true)
                let errMessage = ('Here is the errors in the recurring setup call', errors)
                return ErrorAlert(errMessage) })


} 


React.useEffect(()=>{
        

    if(routingNumber.length === 9){
        
                   
        
        axios({
            method: 'POST',
            url: 'https://verify.routingtool.com/api/get_token',
            headers: {'Content-Type': 'multipart/form-data'},
            data: RoutingToolData

        })
        .then(response=>{
            
            let accessToken = response.data.access_token
            let dataToSend = new FormData()
            dataToSend.append('routing',routingNumber)
            axios({
                method: "POST",
                url: 'https://verify.routingtool.com/api/get_routing',
                headers: {'Content-Type': 'multipart/form-data','X-Access-Token': accessToken},
                data: dataToSend

            }).then(response=>{
               
                if (response.data.info.result_code === "WF01" || response.data.info.result_code === "WN02"){setBankeName(response.data.info.bank_info.name)
                                                               setRoutingValidated(true)         }
                else{ setBankeName('Check your Routing Number'); setRoutingValidated(false)}
            })
        })
    }

},[routingNumber])

const formik = useFormik({
    
    initialValues:{
        accountNumber: "",
        oneTimePayment: totalDue.toFixed(2),
        fullName: context.first_name+' '+ context.last_name,
        email: context.email,
        address1: context.street_address_1,
        address2: context.street_address_2,
        city: context.city,
        state_abr: context.state_abr,
        zip: context.zip, 
        zip4: context.zip4,
    },

    validationSchema: validationSchema,
    onSubmit:(values,actions) =>{
        setPaymentLoading(true)
            values.country="US"
            values.bankName=bankName
            values.routingNumber=routingNumber
            values.customerNumber=customerNumber
            values.oneTimePayment=totalDue.toFixed(2)

        if(paymentType==='oneTime'){
            
            axios({
                method: "POST",
                url: "api/oracle6/payment",
                data: {values, phone: phoneToStore}

            })
            .then(result=>{
                
                if(result.status===200 && Number(result.data.Result[0])=== 0){
                    axios({
                        method: "POST",
                        url: "api/oracle3/paid",
                        data: {data: location.state.accounts,
                               mail_documents: mailDocs,
                               promo_code_used: promoCodesUsed
                                }

                    }).then(result=>{
                        
                        if (result.data === "Payment Complete"){
                            
                            PDFfunction()
                            
                            
                            
                    } else{
                        setPaymentLoading(false)
                        setErrorInAttempt(true)
                        let errorMessage = ('error in the paid axios call:', result.statusText)
                        return ErrorAlert(errorMessage)
                    }


                    })
                   


                    actions.setSubmitting(false)
                    
                } else {
                    setPaymentLoading(false)
                    setErrorInAttempt(true)
                    
                }
            })
            .catch(errors=>{
                let errorsMessage = ('Here are the errors in the paid call', errors)
                actions.resetForm({})
                actions.setSubmitting(false)
                setErrorInAttempt(true)
                return ErrorAlert(errorsMessage)
                
            })              


        } else {

        axios({
            method: "POST",
            url: "api/oracle6/recurringpayment",
            data: {values, 
                   phone: phoneToStore, 
                   number_of_payments: numberOfPayments,
                   recurring_payment_amount: recurringPaymentAmount                   
                }

        })
        .then(result=>{
            
            if(result.status===200 &&  Number(result.data.Result[0])=== 0){
                let balance = totalDue - recurringPaymentAmount
                let numberOfPaymentsLeft = numberOfPayments - 1 
                axios({
                    method: "POST",
                    url: "api/oracle3/recurring",
                    data: {data: location.state.accounts,
                           balance: balance,
                           number_of_payments: numberOfPaymentsLeft,
                           day_of_month: Number(dayOfMonth),
                           mail_documents: mailDocs,
                           promo_code_used: promoCodesUsed
                        }

                })
                .then(result=>{
                    if(result.data==="Recurring Payments Set Up"){
                        setPaymentLoading(false)
                        setRecurringPaymentSetup(true)
                    }else{
                        setErrorInAttempt(true)
                        setPaymentLoading(false)
                    }
                })
                actions.setSubmitting(false)
                
            } else{
                setErrorInAttempt(true)
                setPaymentLoading(false)
            }
        })
        .catch(errors=>{
            actions.resetForm({})
            actions.setSubmitting(false)
            setErrorInAttempt(true)
            let errMessage = ('Here is the errors in the recurring setup call', errors)
            return ErrorAlert(errMessage)
        })
        } 

        
    }
    
})




let form;
let form2;

if (paymentType==='oneTime'){
    form =  <Grid container spacing={2} direction='row' justifyContent='center' sx={{mb: '1rem'}}>
    <Grid item xs={10} sm={8} md={8} lg={4}>
    <TextField
    fullWidth 
    variant='outlined'
    id='oneTimePayment'
    name= 'oneTimePayment'
    label='One Time Payment'
    disabled={true}
    type='number'
    InputProps={{startAdornment: <InputAdornment postion='start'>$</InputAdornment>}}
    value={totalDue.toFixed(2)}
    />
    </Grid> 
    </Grid>



}else{

        form =
        <React.Fragment>
        <Grid container spacing={0} direction='row' justifyContent='center' sx={{mb: '1rem'}}>            
        <Grid item sm={3} md={3} lg={3}>
        <TextField
        fullWidth 
    variant='outlined'
    id='recurringPaymentAmount'
    name= 'recurringPaymentAmount'
    label='Recurring Payment Amount'
    type='number'
    disabled={true}
    value={recurringPaymentAmount}
    
    InputProps={{startAdornment: <InputAdornment postion='start'>$</InputAdornment>}}
    />
    </Grid>
        <Grid item sm={2} md={2} lg={1}>
        <FormControl sx={{minWidth: '4rem', mb: '1rem', marginLeft: 5}} fullWidth>
        <InputLabel  variant='outlined' ref={InputLabel} htmlFor='numberOfPayments'>Number of Payments</InputLabel>
        <Select
        defaultValue={numberOfPayments}
        id='numberOfPayments'
        onChange={changeRecurringPaymentTimes}
        value={numberOfPayments}
        >
            
            <MenuItem value = {2}>2</MenuItem>
            <MenuItem value = {3}>3</MenuItem>
            <MenuItem value = {4}>4</MenuItem>
            <MenuItem value = {5}>5</MenuItem>
            <MenuItem value = {6}>6</MenuItem>
            <MenuItem value = {7}>7</MenuItem>
            <MenuItem value = {8}>8</MenuItem>
            <MenuItem value = {9}>9</MenuItem>
            <MenuItem value = {10}>10</MenuItem>
            <MenuItem value = {11}>11</MenuItem>
            <MenuItem value = {12}>12</MenuItem>
        </Select>
        </FormControl>
        </Grid>
        
        </Grid>
        <Grid container direction='row' justifyContent='center' sx={{mb: '1rem'}}>
        <Grid item xs ={10} sm={6} md={6} lg={4}>
            <Typography> Recurring Payments in the amount of ${recurringPaymentAmount} will occur on day number {dayOfMonth} for each of the next {numberOfPayments} months. If today is the 31st it will be billed on the 30th(or last day of the month). If this date is on a holiday or weekday it will be debited out of your account the previous day.</Typography>

        </Grid>

        </Grid>
        </React.Fragment>  
}
 if(paymentType==='oneTime'|| 'recurring'){

    form2 = <form onSubmit={formik.handleSubmit}>
    <Grid container spacing={2} direction='row' justifyContent='center'  sx={{mb: '1rem'}}>   
        <Grid item xs={10} sm={8} md={6} lg={5}>
        <TextField
        fullWidth
        type='text'
        margin='none'
        variant='outlined' 
        id ='routingNumber'
        name='routingNumber'
        autoFocus = {true} 
        label= 'Routing Number'
        inputProps={{maxLength: 9}}
        
        onChange={routingNumberCheck}
        
        
        />
        </Grid> 
        <Grid item xs={10} sm={8} md={6} lg={5} >     
        <TextField
        fullWidth
        type='text'
        margin='none'
        placeholder="Your Bank"
        disabled={true}
        variant='outlined' 
        id ='bankName'
        name='bankName'
        label= 'Financial Institution'
        value={bankName}
        
        />
        </Grid>
        </Grid>
        <Grid container spacing={2} direction='row' justifyContent='center' sx={{mb: '1rem'}}>
        <Grid item xs={10} sm={8} md={6} lg={5}>
        <TextField
        fullWidth
        type='text'
        margin='none'
        variant='outlined' 
        id ='accountNumber'
        name='accountNumber'
        label= 'Bank Account Number'
        value={formik.values.accountNumber}
        onChange={formik.handleChange}
        error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
        helperText={formik.errors.accountNumber}
        />
        </Grid> 
        <Grid item xs={10} sm={8} md={6} lg={5} >
        <Button
        fullWidth
        color='secondary' 
        variant='contained'
        onClick={promoButtonOnClick}
        >Add Promo Code</Button>
        

        
        
        </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='center'>
            <Grid item >
                <ToggleButton 
                value='mailOption'
                selected={mailOption}
                onChange={changeInMail}
                color='primary'
                sx={{marginBottom: 2,
                     marginTop: 2}}
                > <MarkunreadMailboxIcon /> </ToggleButton> 
            </Grid>
            <Grid><Typography variant='body1'> Click the Icon if you want us to mail your documents via US Postal Mail for a $5.00 fee.</Typography></Grid>
        </Grid>
        {form}
     <Grid container spacing={2} direction='row' justifyContent='center'>
        <Grid item>
            <LoadingButton loading={paymentLoading} loadingPosition='end' type='submit' variant ='contained' color='secondary'size='medium' sx={{mb: '1rem'}} disabled = {!formik.isValid || !routingValidated} endIcon={<AccountBalanceIcon />}> Submit Payment </LoadingButton>
        </Grid>   
    </Grid>   
    <Grid container spacing={2} direction='row' justifyContent='center' sx={{mb: '1rem'}}>
      <Grid item xs={10} sm={8} md={4} lg={4}>
        <TextField
        fullWidth
        type='text'
        margin='none'
        variant='outlined'
        id='fullName'
        name='fullName'
        label='First and Last Name'
        value={formik.values.fullName}
        onChange={formik.handleChange}
        error={formik.touched.fullName && Boolean(formik.errors.fullName)}
        helperText={formik.errors.fullName}
        />        
      </Grid>
      
      <Grid item xs={10} sm={8} md={4} lg={4}>
        <TextField
        fullWidth
        type='email'
        margin='none'
        variant='outlined'
        id='email'
        name='email'
        label='Email'
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.errors.email}
        />        
      </Grid> 
    
      <Grid container spacing={2} direction='row' justifyContent='center' sx={{mb: '1rem'}}>
            <Grid item xs={10} sm={8} md ={8} lg={8}>
            <MuiTelInput
                        fullWidth
                        sx={{mt: 2}}                    
                        variant='outlined'
                        defaultCountry='US'
                        forceCallingCode={true}
                        onlyCountries={['US']}
                        disableDropdown={true}
                        value={phoneNumber}
                        label='Phone Number'
                        id='phone'
                        name='phone'
                        type='phone'
                        onChange={handlePhoneChange}
                        error={phoneError}
                        helperText={phoneErrorMessage}/>
            </Grid>
        </Grid>
        <Grid spacing={2} container direction='row' justifyContent='center' sx={{mb: '1rem'}}>
        <Grid item  xs={10} sm={8} md ={8} lg={8}>   
        <TextField
        type='text'
        margin='none'
        fullWidth
        variant='outlined'
        id ='address1'
        name='address1'
        label= 'Street Address Line 1'
        value={formik.values.address1}
        onChange={formik.handleChange}
        error={formik.touched.address1 && Boolean(formik.errors.address1)}
        helperText={formik.errors.address1}
        /> 
        </Grid>    
        </Grid>
        <Grid container spacing={2} direction='row' justifyContent='center' sx={{mb: '1rem'}}>
        <Grid item xs={10} sm={8} md ={8} lg={8}>   
        <TextField
        type='text'
        margin='none'
        fullWidth
        variant='outlined'
        id ='address2'
        name='address2'
        label= 'Street Address Line 2'
        value={formik.values.address2}
        onChange={formik.handleChange}
        error={formik.touched.address2 && Boolean(formik.errors.address2)}
        helperText={formik.errors.address2}
        /> 
        </Grid>   
        </Grid>
        <Grid container direction='row' justifyContent='center' spacing={2} sx={{mb: '1rem'}}>
         <Grid item xs={7} sm={6} md={3} lg={3}>      
        <TextField
        fullWidth
        margin='none'
        variant='outlined' 
        id ='city'
        name='city'
        label= 'City'
        value={formik.values.city}
        onChange={formik.handleChange}
        error={formik.touched.city && Boolean(formik.errors.city)}
        helperText={formik.errors.city}
        />
        </Grid>
        <Grid item sm={2} md={2} lg={2}>    
        <FormControl variant='outlined'
        fullWidth 
       
        >
        <InputLabel htmlFor='state_abr'>State</InputLabel>
        <Select
        fullWidth
        label='State'
        labelId='state-label'
        id='state' 
        defaultValue = {formik.values.state_abr}
        value = {formik.values.state_abr}
        onChange={formik.handleChange}
       
        >
    <ListSubheader>States</ListSubheader>      
    <MenuItem value="AL">AL</MenuItem>
	<MenuItem value="AK">AK</MenuItem>
	<MenuItem value="AR">AR</MenuItem>	
	<MenuItem value="AZ">AZ</MenuItem>
	<MenuItem value="CA">CA</MenuItem>
	<MenuItem value="CO">CO</MenuItem>
	<MenuItem value="CT">CT</MenuItem>
	<MenuItem value="DC">DC</MenuItem>
	<MenuItem value="DE">DE</MenuItem>
	<MenuItem value="FL">FL</MenuItem>
	<MenuItem value="GA">GA</MenuItem>
	<MenuItem value="HI">HI</MenuItem>
	<MenuItem value="IA">IA</MenuItem>	
	<MenuItem value="ID">ID</MenuItem>
	<MenuItem value="IL">IL</MenuItem>
	<MenuItem value="IN">IN</MenuItem>
	<MenuItem value="KS">KS</MenuItem>
	<MenuItem value="KY">KY</MenuItem>
	<MenuItem value="LA">LA</MenuItem>
	<MenuItem value="MA">MA</MenuItem>
	<MenuItem value="MD">MD</MenuItem>
	<MenuItem value="ME">ME</MenuItem>
	<MenuItem value="MI">MI</MenuItem>
	<MenuItem value="MN">MN</MenuItem>
	<MenuItem value="MO">MO</MenuItem>	
	<MenuItem value="MS">MS</MenuItem>
	<MenuItem value="MT">MT</MenuItem>
	<MenuItem value="NC">NC</MenuItem>	
	<MenuItem value="NE">NE</MenuItem>
	<MenuItem value="NH">NH</MenuItem>
	<MenuItem value="NJ">NJ</MenuItem>
	<MenuItem value="NM">NM</MenuItem>			
	<MenuItem value="NV">NV</MenuItem>
	<MenuItem value="NY">NY</MenuItem>
	<MenuItem value="ND">ND</MenuItem>
	<MenuItem value="OH">OH</MenuItem>
	<MenuItem value="OK">OK</MenuItem>
	<MenuItem value="OR">OR</MenuItem>
	<MenuItem value="PA">PA</MenuItem>
	<MenuItem value="RI">RI</MenuItem>
	<MenuItem value="SC">SC</MenuItem>
	<MenuItem value="SD">SD</MenuItem>
	<MenuItem value="TN">TN</MenuItem>
	<MenuItem value="TX">TX</MenuItem>
	<MenuItem value="UT">UT</MenuItem>
	<MenuItem value="VT">VT</MenuItem>
	<MenuItem value="VA">VA</MenuItem>
	<MenuItem value="WA">WA</MenuItem>
	<MenuItem value="WI">WI</MenuItem>	
	<MenuItem value="WV">WV</MenuItem>
	<MenuItem value="WY">WY</MenuItem>
    <ListSubheader>Outlying Territories</ListSubheader>
    <MenuItem value="AS">AS</MenuItem>
    <MenuItem value="GU">GU</MenuItem>
    <MenuItem value="MP">MP</MenuItem>
    <MenuItem value="PR">PR</MenuItem>
    <MenuItem value="UM">UM</MenuItem>
    <MenuItem value="VI">VI</MenuItem>
    <ListSubheader>Armed Forces</ListSubheader> 
    <MenuItem value="APO-AA">AA</MenuItem>
    <MenuItem value="APO-AP">AP</MenuItem>
    <MenuItem value="APO-AE">AE</MenuItem>
    <MenuItem value="FPO-AA">AA</MenuItem>
    <MenuItem value="FPO-AP">AP</MenuItem>
    <MenuItem value="FPO-AE">AE</MenuItem>		   
        </Select>
        </FormControl>
        </Grid>
        <Grid item xs={7} sm ={6} md={2} lg={2}>
        <TextField
        fullWidth
        margin='none'
        variant='outlined'
        type='phone' 
        id ='zip'
        name='zip'
        label= 'Zip'
        value={formik.values.zip}
        onChange={formik.handleChange}
        error={formik.touched.zip && Boolean(formik.errors.zip)}
        helperText={formik.errors.zip}
        />
        </Grid>
        <Grid item xs={3} sm ={2} md={1} lg={1}> 
        <TextField
        margin='none'
        variant='outlined'
        type='phone' 
        id ='zip_plus4'
        name='zip_plus4'
        label= '+4'
        value={formik.values.zip_plus4}
        onChange={formik.handleChange}
        error={formik.touched.zip_plus4 && Boolean(formik.errors.zip_plus4)}
        helperText={formik.errors.zip_plus4}
        />
        </Grid> 
        </Grid>
        



    </Grid>
    </form>
} 


if (paymentType ==='moneyOrder') {

    form2 = 
    <React.Fragment>
        <Grid container direction='column' justifyContent='center' alignItems='center' alignContent='center'>
    <Grid item sx={{mb: '1rem'}}>
        <Typography color='primary' variant='h6'>Confirm your money order below, remember to send the money order with a return on receipt and please mail the offer letter with the money order. </Typography>
    </Grid>
    <Grid item sx={{mb: '1rem'}}>
    <Button
        
        color='secondary' 
        variant='contained'
        onClick={promoButtonOnClick}
        >Add Promo Code</Button>
        </Grid>
        <Grid item sx={{mb: '1rem'}}>
        <Button variant='contained' color = 'secondary'onClick={moneyOrderButtonClick} startIcon={<LocalAtmIcon/>} >Confirm Money Order</Button>
    </Grid>
    </Grid>
    </React.Fragment> 
    }

   
let message;     
    

   
    if(paidInFull){return <Navigate to='/user/completed' state={{data: location.state.accounts, mail_documents: mailDocs, promo_code_used: promoCodesUsed}} />}
    if(errorInAttempt){message = <Typography variant='h6' color = 'secondary' >Check your information again, especially phone number and bank account information</Typography>}
    if(recurringPaymentSetup){return <Navigate to ='/user/recurringsetup'/>}    
    if(moneyOrder){return <Navigate to='/user/moneyorder' state={{totalDue: totalDue+5 }}/>}

    return (
       
    <React.Fragment>
        <Grid container direction='column'>
        <Grid item xs={12} sm={12}>
        <UserPromo open={promoAlert} original_ammount={originalAmmount} settotaldue={setTotalDue} customer_number={customerNumber} setpromocodesused={setPromoCodesUsed} close={closeAlert} accounts={accounts} setrecurringpaymentamount={setRecurringPaymentAmount} numberofpayments={numberOfPayments} /> 
        </Grid>
        </Grid>
    <Typography variant='h3' align='center' sx={{mb: '1rem'}}>Payment Page</Typography>
    <Typography variant='h6' align='center' sx={{mb: '1rem'}}>We accept payments via secure electronic funds transfer through ACH, or via Money Order. </Typography>
    <Typography variant ='h6' align='center' sx={{mb: '1rem'}} color='primary'>Don't forget to enter your Promo Code by hitting the red button labeled Add Promo Code above the payment button.</Typography> 
    <Typography color = 'secondary' variant='h5' align='center' sx={{mb: '1rem'}}>(Reminder we will not store this account information and you will only be billed the amount listed.)</Typography>
    <Grid container spacing={2} direction='row' justifyContent='center'  sx={{mb: '1rem'}}>
    <Grid item>
        <ToggleButtonGroup size='large'aria-label ='One Time or Recurring Payments' exclusive value={paymentType} onChange={paymentChange}>
            <ToggleButton  color= 'primary' value='oneTime'>One-Time Payment</ToggleButton>
            <ToggleButton color= 'secondary' value='recurring' >Recurring Payments</ToggleButton>
            <ToggleButton color='primary' value='moneyOrder'>Money-Order</ToggleButton>
        </ToggleButtonGroup>
    </Grid>
    <Grid container direction='row' justifyContent='center'>
    
    {message}
    
    </Grid>
    </Grid>
    {form2}
    
    </React.Fragment>
    )
}

