import * as React from 'react'
import { Grid, Box, Container } from '@mui/material'
import { MFPayment } from '../../forms/MFPayment'


export const OldPayment = () =>{

    return(
    
    <Box sx={{minHeight:'70vh', mt: 15, mb: 2}}>
        <Grid container direction='column' alignItems='center'  justifyContent='center'>
           <Grid item>
                <MFPayment />
           </Grid> 
        </Grid>
    </Box>
        
        

    )

}