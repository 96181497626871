import * as React from 'react'
import { Typography, Box, Grid,
         Paper, Radio, RadioGroup, FormLabel,
         FormControl, FormControlLabel, Button,
         Breadcrumbs, Link
 } from '@mui/material'
import axios from 'axios'
import { ErrorAlert } from '../../../alerts/ErrorAlert'
import { axiosErrorHandling } from '../../../misc/other'
import { Navigate, useLocation, Link as RouterLink } from 'react-router-dom'


export const AdminConfirmUserAccounts = () =>{
    const context = JSON.parse(window.localStorage.getItem('usercontext'))
   
    const accountpath = '/admin/customercare/accounts/' + context.email
    let location = useLocation()
    

    let initialAccounts = location.state.accounts.length
    let workingAccount = location.state.accounts
    const totalDue = location.state.totalDue
    const customer_number = location.state.accounts[0].customer_number

    const [typeOfSettlement, setTypeOfSettlement] = React.useState('Confirmed')
    const [readyToSettle, setReadyToSettle] = React.useState(false)
    const [goToDocuments, setGoToDocuments] = React.useState(false)
    const [errorAlert, setErrorAlert] = React.useState(false)
    const [errorMessage, setErrorMessage ] = React.useState('')

    const closeErrorAlert = () =>{
        setErrorAlert(false)
    }

    const handleChange = (event) =>{
        setTypeOfSettlement(event.target.value)
    }

    const handleConfirm = (event) =>{
        let dataToSend = []
        event.preventDefault();
        
        for(let i = 0; i<workingAccount.length; i++){
             
            dataToSend.push({ account_status: typeOfSettlement,
                              batch_number: workingAccount[i].batch_number,
                              customer_number: workingAccount[i].customer_number,
                              original_account_number: workingAccount[i].original_account_number
    
    
            })
        }
        
        axios({
            method: "POST",
            url: "api/oracle3/confirm",
            data: {dataToSend}
    
        }).then(result=>{
            
            for(let j=0; j<workingAccount.length; j++){
              workingAccount[j].settlement_per =  result.data[j].settlement_per 
              
            }
            
            if(typeOfSettlement === 'Confirmed'){
            setReadyToSettle(true) }
            else{setGoToDocuments(true)  }
          
        })
        .catch(errors=>{
            let errMess = axiosErrorHandling(errors)
            setErrorMessage(errMess)
            
        })   
        
    
    }
    
    if(readyToSettle === true){
        return <Navigate to='/admin/customercare/settlement' state= {{accounts: location.state.accounts, totalDue: totalDue }}/>
     }
     
     if(goToDocuments === true){
         return  <Navigate to='/admin/customercare/documents' state={{typeOfDocument: typeOfSettlement, customer_number: customer_number}}r/>
     }

    return(
        <Box component='form' onSubmit={handleConfirm} sx={{minHeight:'80vh', mt: 15, mb: 2}}>
        <Breadcrumbs sx={{mb: 3}}>
            <Link underline='hover' component={RouterLink} to='/admin/dashboard'>Dashboard</Link> 
            <Link underline='hover' component={RouterLink} to='/admin/customercare'>Search </Link> 
            <Link underline='hover' component={RouterLink} to='/admin/customercare/confirm'>Confirm Info</Link>
            <Link underline='hover' component={RouterLink} to={accountpath}>Select Accounts</Link>
            
        </Breadcrumbs>  
        <ErrorAlert open={errorAlert} error={errorMessage} handleClose={closeErrorAlert}/>    
        <Grid container direction='column' justifyContent='space-between' alignItems='center' sx={{margin: 2}} >
        <Grid item sx={{margin: 2}} >
        <Typography variant='h6'>Please select the appropriate description for the selected accounts ({initialAccounts}):</Typography>
        </Grid>
        <Grid item sx={{margin: 2}} xs={10} s={10}>
        <Paper>
            <FormControl component='fieldset'>
              <FormLabel component='legend' sx={{color: 'black', paddingTop: '1rem', paddingLeft: '1rem' }}>Select which describes your debt the best:</FormLabel>
              <RadioGroup aria-label='type of debt' name='typeOfDebt' value={typeOfSettlement} onChange={handleChange} sx={{margin: 2}}>
                  <FormControlLabel value='Confirmed' control={<Radio />} label='These are my debts and I want to settle them.' />
                  <FormControlLabel value='Already Settled' control={<Radio />} label='These debts are already settled.' />
                  <FormControlLabel value='Bankruptcy' control={<Radio />} label='These accounts are protected under a Bankruptcy filing.' />
                  <FormControlLabel value='Deceased' control={<Radio />} label='The person, who owes these debts, is deceased.' />
                  <FormControlLabel value='Wrong Identity' control={<Radio />} label='These debts are not owed by me.' />
                  <FormControlLabel value='1099-C' control={<Radio />} label='I have already received a 1099-C for these accounts.' />  
              </RadioGroup>
            <Button variant='contained' color='primary' type='submit'  >Confirm</Button>
           </FormControl>
             
        </Paper>
        </Grid>
        
        </Grid>
        </Box>
    )

}