import * as React from 'react'
import { useLocalStorage } from './useLocalStorage'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../utility/context'



export const initialContext = {

    admin: 2,   
    city: 'Spokane Valley',
    customer_number: 100000000,
    email: 'name@domain.com',
    first_name: 'Mary',
    last_name: 'Davis-Martin III',
    phone: '509-555-3547',
    street_address_1: '123 7th St',
    street_address_2: 'Apt 7A',
    state_abr: 'WA',
    zip: '99205',
    zip4: '7777',
    mail_marketing: 0,
    email_marketing: 1,
    address_verified: 0
}



export const AuthProvider = ({children})=>{
    

    const [user, setUser] = useLocalStorage('user', null)
    
    const navigate = useNavigate();

    const login = async (data) =>{
        
        setUser(data);
        if (data.admin === 1){
            document.cookie=`session=${data.tokens}`
            navigate('/admin/dashboard')
        } else{
            document.cookie=`session=${data.tokens}`
            navigate('/user/accounts/' + data.email)
            
        }
    }


    const logout = () =>{
        setUser({email: 'name@domain.com', admin: 2, token: ''});
        window.localStorage.setItem('context', JSON.stringify(initialContext))
        window.localStorage.setItem('usercontext',JSON.stringify(initialContext))




        navigate('/login', {replace: true});
    }

    // const value2 = React.useMemo (()=>({
    //     user,
    //     login,
    //     logout,     
      
    // }),[user])



return <AuthContext.Provider value={{user, login, logout}}>{children}</AuthContext.Provider>

    
}

export const useAuth = () => {
    return React.useContext(AuthContext)
}

export const getUserData = () => {

    new Promise((resolve)=>{
        const userdata = window.localStorage.getItem('user')
        
        resolve(userdata)
        
    })
}