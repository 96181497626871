import * as React from 'react'
import { Typography, Grid, Select, 
         FormControl, 
         MenuItem, InputLabel, 
         FormHelperText, Box, 
         Alert } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import SendIcon from '@mui/icons-material/Send'
import axios from 'axios'
import { axiosErrorHandling } from '../../misc/other'
import { ErrorAlert } from '../../alerts/ErrorAlert'




export const SendMarketingEmail = () =>{

    const [marketingEmails, setMarketingEmails] = React.useState([])
    const [selectedEmailTemplate, setSelectedEmailTemplate] = React.useState('')
    const [menuItems, setMenuItems] = React.useState(null)
    const [dirtyForm, setDirtyForm] = React.useState(false)
    const [submiting, setSubmitting] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    
    const closeError = () =>{
        setError(false)
    }
    


React.useEffect(()=>{
    axios({
        method: 'POST',
        url: 'api/oracle7/getemailtemplates'
        

    }).then(results=>{
        console.log('Here are the results', results)
        setMarketingEmails(results.data)

    }).catch(err=>{

         let errMessage = axiosErrorHandling(err)
        setErrorMessage(errMessage)
        setError(true)

    })
    

},[])

const selectChange = (event) => {
    setSelectedEmailTemplate(event.target.value)
    setDirtyForm(true)
    setError(false)
}

const sendEmailButton =() =>{
    setSubmitting(true)
    setError(false)
console.log('Here is the selection: ', selectedEmailTemplate)

const isolatedObject = marketingEmails.find((template)=> template._id===selectedEmailTemplate)

console.log('Here is the isolated Object: ', isolatedObject)
if(isolatedObject._id==='starter_test'){
axios({
    method: "POST",
    url:'api/oracle7/sendemailstarter',
    data: isolatedObject
}).then(results=>{
    console.log('Here are the results', results)
    
    if(results.status===200){
        setSubmitting(false)
        setDirtyForm(false)
        return <Alert severity='success' >Email has been Set</Alert>
        
    }else{
        setSubmitting(false)
        setDirtyForm(false)
        setErrorMessage(results.data)
        setError(true) 
    }
    
    
}).catch(err=>{
    
    setSubmitting(false)
    setDirtyForm(false)
    let errMessage = axiosErrorHandling(err)
    setErrorMessage(errMessage)
    setError(true)
}).catch(err=>{
    
    setSubmitting(false)
    setDirtyForm(false)
    let errMessage = axiosErrorHandling(err)
    setErrorMessage(errMessage)
    setError(true)
})
} else if (isolatedObject._id==='document_reminder'){
    axios({
        method: "POST",
        url:'api/oracle7/senddocumentemail',
        data: isolatedObject
    }).then(results=>{
        console.log('Here are the results', results)
        
        if(results.status===200){
            setSubmitting(false)
            setDirtyForm(false)
            return <Alert severity='success' >Email has been Set</Alert>
            
        }else{
            setSubmitting(false)
            setDirtyForm(false)
            setErrorMessage(results.data)
            setError(true)  
        }
        
        
    }).catch(err=>{
        
        setSubmitting(false)
        setDirtyForm(false)
        let errMessage = axiosErrorHandling(err)
        setErrorMessage(errMessage)
        setError(true)
    }).catch(err=>{
        
        setSubmitting(false)
        setDirtyForm(false)
        let errMessage = axiosErrorHandling(err)
        setErrorMessage(errMessage)
        setError(true)
    })


}


else {
    setError(true)
    setDirtyForm(false)
    setSubmitting(false)


}
}

React.useEffect(()=>{
    console.log('Here are the marketingEmails', marketingEmails)
    let menuitems = marketingEmails.map((e)=>
        <MenuItem value={e._id}>{e._id}</MenuItem>
    )
    console.log('Here are the menuitems', menuitems)
    setMenuItems(menuitems)
    

},[marketingEmails])

 

    return(
        
        <Box sx={{minHeight:'70vh', mt: 15, mb: 2}}>
            <Grid container direction='row'>
                <ErrorAlert open={error} handleClose={closeError} error={errorMessage}/>
            </Grid>    
        <Grid container direction='column'>
        <Grid item>
        <Typography sx={{mb: '1rem'}} variant='h4' color='secondary' >Please select a Template to send</Typography>
        </Grid>
        <Grid item>
            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id='select-email-template-label'>Template</InputLabel>
                <Select labelID='select-email-template-label'
                id='select-email-template'
                label='Template'
                value={selectedEmailTemplate}
                onChange={selectChange}
                >
                
                <MenuItem value=''><em>None</em></MenuItem>
                {menuItems}

                </Select>
                <FormHelperText> Please select a template</FormHelperText>
                

            </FormControl>
        </Grid>
        <Grid item>
        <LoadingButton
        sx={{m: 1}}
        variant='contained'
        disabled={!dirtyForm}
        loading={submiting}
        loadingPosition='end'
        endIcon={<SendIcon/>}
        onClick={sendEmailButton}
        
        >Send Email</LoadingButton>
        
        </Grid>
        <Grid item>
        <Typography color='primary'>{errorMessage}</Typography>
        </Grid>
        </Grid>
        </Box>
        
        
    )
}