import * as React from 'react'
import { Typography, Box, Button, Grid, TextField } from '@mui/material'
import { VerifyBillingForm } from './VerifyBilling'
import AccountBoxIcon from '@mui/icons-material/AccountBox'


export const PaybyCheck = ({customerInfo, paymentInfo}) =>{


const [billingForm, setBillingForm] = React.useState(null)

const onVerifyClick = () =>{
    if (billingForm){
        setBillingForm(null)
    } else {
        setBillingForm(<VerifyBillingForm customerInfo={customerInfo}/>)
    }
}


return(
    <Box sx={{minHeight:'75vh', mt: 2, mb: 2}}>
        <Grid container direction='row' sx={{mb: 1}}>
            <Grid item>
                <Typography>Please fill out your checking account info</Typography> 
            </Grid>
        </Grid>
        <Grid container direction='row'>
            <Grid item sx={{mb: 1}} xs={12} sm={10} md={9}>
                <TextField
                    fullWidth
                    name='accountnumber'
                    id='accountnumber'
                    label="Account Number"
                    type='number'
                    required
                />
            </Grid>
        </Grid>
        <Grid container direction='row'>
            <Grid item sx={{mb: 1}} xs={12} sm={10} md={9}>
                <TextField
                    fullWidth
                    name='routingnumber'
                    id='routingnumber'
                    label="Routing Number"
                    type='number'
                    required
                />
            </Grid>
        </Grid>
        <Grid container direction='row-reverse' sx={{mb: 1}}>
            <Grid item xs={4}>
                <Button  fullWidth variant='contained'> Pay ${paymentInfo.totalDue} </Button>
            </Grid>
        </Grid>      
         <Grid container direction='column'>
            <Grid item xs={12}>
                <Button color='secondary' variant='outlined' fullWidth onClick={onVerifyClick} endIcon={<AccountBoxIcon />}>Verify Billing Info</Button>
            </Grid>
            {billingForm}
        </Grid>
    </Box>
    
    
)
}