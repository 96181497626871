import * as React from 'react'
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom'
import { Grid, Typography, Box, Container} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { ErrorAlert } from '../../alerts/ErrorAlert'



export const Completed = ()=>{


const context = JSON.parse(window.localStorage.getItem('context'))
const path =  '/user/accounts/' + context.email   
const [loading, setLoading] = React.useState(true)

const location = useLocation()




    
React.useEffect(()=>{



let data = location.state.data
let mailDocs = location.state.mail_documents
let promoCodesUsed = location.state.promo_code_used

    axios({
        method: "POST",
        url: "api/oracle3/paid",
        data: {data: data,
               mail_documents: mailDocs,
               promo_code_used: promoCodesUsed
                 }

    }).then(result=>{
        
        if (result.data === "Payment Complete"){
            
            setLoading(false)
            
            
            
    } else{
        let errormes='Something went wrong in the paid call update'
        return ErrorAlert(errormes)
    }


    }).catch(err=>{
        let errmes = ('Here is the caught error in the paid axios call', err)
        return ErrorAlert(errmes)
    })

},[ location.state.data, location.state.mail_documents, location.state.promo_code_used])

    return(
       
        <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
        <Grid container direction='row' justifyContent='center' alignItems ='center'>
        <Grid item xs={7} sm={6} md={6} lg={4}>
        <Typography variant='h6'gutterBottom={true}>Payment Processed...Check your download folder for the settlement letter.  Keep the letter on file for future reference. If any other entity requests to settle the debt please show them this letter. </Typography>
        </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid item>
        <LoadingButton loading={loading} loadingPosition='end' component={Link} to={path}  variant='contained' color='primary'> Go Back To Account Home </LoadingButton>
        
        </Grid>
        </Grid>
        
        </Box>
        
        
    )

}