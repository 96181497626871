import * as React from 'react'
import { Dialog, DialogTitle, Button, DialogContent, DialogActions, Typography, ButtonGroup, IconButton  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import { getProps, OfferLetterPDF} from '../letters/OfferLetter'
import {styled} from '@mui/material/styles'
import FileSaver from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import axios from 'axios'
import { PDFDate } from '../misc/dates';
import { ErrorAlert } from './ErrorAlert';

const CustomDialog = styled(Dialog)(({theme})=>({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
      },
  
  }));

  export const CounterOffer = ({open, onClose, offerAmount, offerAccepted, data, gotoPayment, settotaldue }) =>{
    const context = JSON.parse(window.localStorage.getItem('context'))
    let customer_number = context.customer_number

    const getFillData =(data, offerAccepted ) =>{
        let newData = []

        for(let i=0; i<data.length; i++){
            newData.push({
                settlement_amount:(offerAccepted*data[i].per_of_settlement),
                original_account_number: data[i].original_account_number,
                batch_number: data[i].batch_number
            })
        }
        return newData
    }

    const file_name = customer_number.toString() +'_OfferLetter_'+ PDFDate + '.pdf'
    
    const blobData = {customer_number: customer_number,
                      file_name: file_name,
                      blob_file: null}

    let fillData = getFillData(data, offerAccepted)
    
    const handleClose = () => {
        onClose()
      };

      const handleClick = async() => {
        
        
        settotaldue(offerAccepted)  
        const PDFprops = await getProps(data, offerAccepted)
        const doc = <OfferLetterPDF {...PDFprops}/>
        const asPDF = pdf([])
        asPDF.updateContainer(doc)
        const blob = await asPDF.toBlob()
        FileSaver.saveAs(blob, blobData.file_name)
  
  
        axios({
          method: 'POST',
          url: 'api/oracle4/upload',
          headers: {'Content-Type': 'application/octet-stream'},
          params:{fileName: file_name,
                  customer_number: customer_number,
                  autogenerated: 1,
                  email: context.email
                  },
          data: blob
          
      }).then(response=>{
          if (response.status===200){
              gotoPayment()
              onClose()
          } else {
              let err=('Here is the error in response from the upload call: ', response.statusText)
              return ErrorAlert(err)
          }
      }).catch(error=>{
          let errs=('Here are the errors with the axios upload call: ', error)
          return ErrorAlert(errs)
      })
  
      
  
      axios({
              method: 'POST',
              url:'api/oracle5/confirm',
              data: {customer_number: customer_number,
                     data: fillData,
                    file_name: file_name}
          
            }).then(result=>{
              if (result.status===200){
                  gotoPayment()
                  onClose()
              }
            })
            .catch(errors=>{
              let error=('Here are the errors in the confirmation call:',errors)
              return ErrorAlert(error)
          })
          
      }

      return (
        <CustomDialog open={open} onclose={handleClose}>
             <DialogTitle>
             We Counter Your Offer with the Following:
            </DialogTitle>
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
             <CloseIcon />
            </IconButton>   
            <DialogContent dividers>
              <Typography gutterBottom>
                We will settle the debt for: 
              </Typography>
              <Typography gutterBottom variant='h6' align='center' >
                ${offerAmount}
              </Typography>
              <Typography>
                To accept, please click on 'ACCEPT OFFER' below.
              </Typography>
              <Typography gutterBottom>
                To submit a new offer, click on 'NEW OFFER' 
              </Typography>
            </DialogContent>
            <DialogActions>
               <ButtonGroup>
              <Button  onClick={handleClose} color="secondary">
                New Offer
              </Button>
              <Button autoFocus onClick={handleClick} color='primary'>Accept Offer</Button>
              </ButtonGroup> 
            </DialogActions>
        </CustomDialog>
      )
    
  }