import * as React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Typography, Button,Box } from '@mui/material'




export const RecurringSetup = ()=>{
    const context = JSON.parse(window.localStorage.getItem('context'))
    const path = '/user/accounts/' + context.email

    
    
    return(
      
        <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
            <Grid container direction='row' justifyContent='center' alignItems ='center'>
                <Grid item xs={7} sm={6} md={6} lg={4}>
                <Typography variant='h6'gutterBottom={true}> Recurring Payment Setup Completed.  We have debited the 1st payment.  The next payment will occur in one month's time.  If today is the 31st then the next payment will be on the 30th.  If the next payment is a holiday or weekend it will be debited the previous business day.  This will happen until the balance is zero.  </Typography>
            </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Grid item>
                <Button component={Link} to={path}  variant='contained' color='primary'> Go Back To Account Home </Button>
            </Grid>
        </Grid>
        
        </Box>
        
        
    
    )

}