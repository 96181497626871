import React from "react";
import { Typography, Grid, Link, Container, Box } from "@mui/material";
import { PrivacyPolicyDate } from "../misc/dates";




export const TermsAndConditions =() =>{

   
  
let definitions =[{ id: 1, label: 'Affiliate ', definition:' means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.'},
    { id: 2, label: 'Account', definition:"means a unique account created for You to access our Service or parts of our Service."},
    { id: 3, label: 'Company', definition:'(Referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Boyer Financial Solutions LLC, 4302 North Raymond Road, Spokane Valley, WA, 99206.'},
    { id: 4, label: 'Country', definition:'refers to: Washington, United States'},
    { id: 5, label: 'Cookies', definition:'These are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.'},                                          
    { id: 6, label: 'Country', definition:'Refers to: Washington, United States'},
    { id: 7, label: 'Device', definition:'Any device that can access the Service such as a computer, a cellphone or a digital tablet.'},    
    { id: 8, label: 'Feedback', definition:'means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.'},
    { id: 9, label: 'Goods', definition:'refers to the accounts offered for reconciliation on the Service.'},
    { id: 10, label: 'Orders', definition: "mean a request by You to fulfill Goods from Us."},   
    { id: 11, label: 'Promotions', definition:'refers to promotions offered through the Service.'},
    { id: 12, label: 'Service', definition:'refers to the Website.'},  
    { id: 13, label: 'Terms and Conditions', definition:'(also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.'}, 
    { id: 14, label: 'Third-party Social Media Service', definition:'means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.'},
    { id: 15, label: 'You', definition:'means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.'},  
    { id: 16, label: 'Website ', definition: 'refers to NameMySettlement.com, accessible from https://www.namemysettlement.com'}  ] 

let orderCancelled =[{id:1, description: 'Errors in the description or prices for Goods.'},
                      {id: 2, description: 'Errors in Your Order.'},
                      {id: 3, description: 'If fraud or an anauthorized or illegal transaction is suspected.'}]

const orderCancelledList= orderCancelled.map((e)=>
<li key={e.id}>{e.description}<br/><br/></li>
)                      

const listDescr = definitions.map((d)=>
<li key={d.id}><b>{d.label}</b>: {d.definition}<br/><br/></li>
);

return(
    <React.Fragment>
    <Container>
        <Box sx={{minHeight:'70vh', mt: 15, mb: 2}}>
    <Grid container direction='column' justifyContent='center' justifyItems='center'>
        <Grid item>
            <Typography variant='h3' sx={{marginLeft: '8rem', marginRight: '12rem'}} color='primary' gutterBottom>Terms and Conditions</Typography>
        </Grid>
        <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Last updated: {PrivacyPolicyDate}</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Please read these terms and conditions carefully before using Our Service.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Interpretation and Definitions</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h5' color='primary' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}}>Interpretation</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h5' color='primary' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}}>Definitions</Typography>
     </Grid>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>For the purposes of these Terms and Conditions:</Typography>
     </Grid>
     <Grid item >
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '12rem', marginRight: '12rem', textIndent: 5}}>{listDescr}</Typography>
     </Grid> 
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Acknowledgement</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Placing Orders for Goods</Typography>
     </Grid>    
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>By placing an Order for Goods through the Service, You warrant that You are legally capable of entering into binding contracts.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h5' gutterBottom={true} color= 'primary' sx={{marginLeft: '8rem', marginRight: '12rem'}}>Your Information</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>If You wish to place an Order for Goods available on the Service, You may be asked to supply certain information relevant to Your Order including, without limitation, Your name, Your email, Your phone number, Your credit card number, the expiration date of Your credit card, Your billing address, and Your shipping information.</Typography>
     </Grid>      
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or other payment method(s) in connection with any Order; and that (ii) the information You supply to us is true, correct and complete.</Typography>
     </Grid>     
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>By submitting such information, You grant us the right to provide the information to payment processing third parties for purposes of facilitating the completion of Your Order.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h5' gutterBottom={true} color= 'primary' sx={{marginLeft: '8rem', marginRight: '12rem'}}>Order Cancellation</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>We reserve the right to refuse or cancel Your Order at any time for certain reasons including but not limited to:</Typography>
     </Grid>
     <Grid item >
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '12rem', marginRight: '12rem', textIndent: 5}}>{orderCancelledList}</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h6' gutterBottom={true} color='secondary' sx={{marginLeft: '8rem', marginRight: '12rem'}}>Your Order Cancellation Rights</Typography>
     </Grid> 
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Our Refunds Policy forms a part of these Terms and Conditions. Please read our Refunds Policy to learn more about your right to cancel Your Order.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Any Goods you purchase can only be returned in accordance with these Terms and Conditions and Our Refunds Policy.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h5' gutterBottom={true} color= 'primary' sx={{marginLeft: '8rem', marginRight: '12rem'}}>Availability, Errors and Inaccuracies</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>We are constantly updating Our offerings of Goods on the Service. The Goods available on Our Service may be mispriced, described inaccurately, or unavailable, and We may experience delays in updating information regarding our Goods on the Service and in Our advertising on other websites.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h5' gutterBottom={true} color= 'primary' sx={{marginLeft: '8rem', marginRight: '12rem'}}>Prices Policy</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>We allow for the negotiation of your debt.  Either you have offered us a price we accepted or we offered you a price that you have accepted.  Once an offer or counter offer have been accepted that is the price negotiated to settle the account.  Once that price has been accepted it cannot be changed. </Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h5' gutterBottom={true} color= 'primary' sx={{marginLeft: '8rem', marginRight: '12rem'}}>Payments</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>All Goods purchased are subject to either a one-time payment or recurring payment not to exceed 12 months. Payment can be made through various payment methods we have available, such as Visa, MasterCard, Affinity Card, American Express cards or e-check. </Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Payment cards (credit cards or debit cards) and echecks(ACH) are subject to validation checks and authorization by Your card issuer or bank. If we do not receive the required authorization, We will not settle the accounts. </Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Promotions</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Any Promotions made available through the Service may be governed by rules that are separate from these Terms.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>If You participate in any Promotions, please review the applicable rules as well as our Privacy policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>User Accounts</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Intellectual Property</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Your Feedback to Us</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction. Essentially we can publish and positive feedback we receive. We try to be vague in the use of the Feedback. We do not use last names and we only use major metropoliton markets as a location identinfier.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>SMS-Texting Policy and Phone Policy</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>We will not call or text any phone number on file until initiated by our customers.  You may call our number at any time 24 hours a day, 7 days a week, but we will not call or text you pre-emptively, we only call back or text after the customer has initiated the call, password reset, or multi-factor authentication.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Links to Other Websites</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</Typography>
     </Grid>
     <br/>
     <Grid item>
  <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Termination</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Limitation of Liability</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>"AS IS" and "AS AVAILABLE" Disclaimer</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Governing Law</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Disputes Resolution</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>For European Union (EU) Users</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>United States Legal Compliance</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Severability and Waiver</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h5' color='primary' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}}>Severability</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h5' color='primary' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}}>Waiver</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Translation Interpretation</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Changes to These Terms and Conditions</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</Typography>
     </Grid>
     <br/>
     <Grid item>
 <Typography variant='h4' gutterBottom={true} sx={{marginLeft: '8rem', marginRight: '12rem'}} color='secondary'>Contact Us</Typography>
      </Grid>
      <br/>
      <Grid item >
 <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>If you have any questions about this our Terms of Service, You can contact us:</Typography>
     </Grid>
     <br/>
     <Grid item>
         <Link underline='hover' color='secondary' variant='body1' sx={{marginLeft: '12rem', marginRight: '12rem', textIndent: 5}} href='mailto:admin@namemysettlement.com?Subject=Questions%20About%20Terms%20And%20Conditions'>Email: admin@namemysettlement.com</Link>
     </Grid>
     <br/>
     <Grid item>
         <Typography variant= 'body1' sx={{marginLeft: '12rem', marginRight: '12rem', textIndent: 5}}>Phone: 1-866-465-6269<br/></Typography>
     </Grid>
     <br/>
     <Grid item>
         <Typography variant= 'body1' sx={{marginLeft: '12rem', marginRight: '12rem', textIndent: 5}}>Mail:<br/>NameMySettlement.com<br/>Attn:Terms and Conditions Questions<br/> PO Box 15333<br/>Spokane Valley, WA 99215-333</Typography>
     </Grid>
     <br/>
     
    </Grid>
    </Box>
    
    </Container>
   
    </React.Fragment>
)


}