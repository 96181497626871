import * as React from 'react'
import {
    Typography, 
    Button, 
    Grid, 
    TextField, 
    FormControl, 
    InputLabel, 
    MenuItem,
    ListSubheader,
    Select, 
    FormGroup,
    FormControlLabel,
    Switch, Box, Breadcrumbs, Link} from '@mui/material'
import { ChangePassword } from '../../alerts/ChangePassword'
import { LoadingButton } from '@mui/lab'
import {MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import {useFormik} from 'formik'
import * as yup from 'yup'
import axios from 'axios'
import UploadIcon from '@mui/icons-material/Upload'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import { ChangePasswordSucces } from '../../alerts/ChangePasswordSuccess'
import { axiosErrorHandling } from '../../misc/other'
import { ErrorAlert } from '../../alerts/ErrorAlert'
import { useOutletContext, Link as RouterLink } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'



var messages = {receivingEmail: 'You are currently receiving notifications, promotions, or advertisements via email.',
                receivingMail: 'You are currently receiving mail notifications and promotions through the postal mail',
                notReceivingEmail: 'You are currrently not receiving email notifications and Promotions via email.',
                notReceivingMail: 'You are currently not receiving mail notifications or advertisements via postal mail.',
                optinEmail: 'You are opting in to receive email notifications, promotions, and advertisements.',
                optoutEmail: 'You are opting out and once submitted will not receive any further notifications, promotions, and advertisements via email.',
                optinMail: 'You are opting in to receive notifications, promotions, and advertisements via US Postal mail.',
                optoutMail: 'You are are opting out of receiving notifications, promotions, and advertisements via US Postal mail.'}


                const validationSchema = yup.object().shape({
                    first_name: yup.string().min(2, "Please enter a valid name"),
                    last_name: yup.string().min(2, "Please enter a valid name"),
                    email: yup.string().email("Email needs to include an @ and a . to update your email address"),
                    phone: yup.string().min(10, "Please enter 10 numbers").max(11, "Please enter only 10 numbers"),
                    address: yup.string().min(5, 'Please enter a valid address'),
                    address2: yup.string().nullable().min(5, 'Please enter a valid address'),
                    city: yup.string().min(3, 'Please enter a valid city name'),
                    zip: yup.string().min(5,'Please enter 5 numbers').max(5, 'Please enter only 5 numbers'),
                    zip4: yup.string().min(4, 'Please enter 4 numbers').max(4, "Please enter only 4 numbers")
                    
                    
                    })

export const UserProfile = () =>{


const changeUserEmail = useOutletContext()

    const context = JSON.parse(window.localStorage.getItem('context'))
    
    const user = JSON.parse(window.localStorage.getItem('user'))
    

    let phone_number = context.phone.toString()
    
    const [emailMarketing, setEmailMarketing] = React.useState(false)
    const [emailMessage, setEmailMessage] = React.useState('')
    const [email_marketing, setEmail_Marketing] = React.useState(null)
    const [mailMarketing, setMailMarketing] = React.useState(false)
    const [mailMessage, setMailMessage]= React.useState('')
    const [mail_marketing, setMail_Marketing] = React.useState(null)
    const [updatedMessage, setUpdatedMessage] = React.useState(false)
    const [errorAlert, setErrorAlert] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [phoneNumber, setPhoneNumber] = React.useState(phone_number)
    const [phoneToStore, setPhoneToStore] = React.useState(context.phone)
    const [phoneNumberError, setPhoneNumberError] = React.useState(false)
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = React.useState(null)
    const [changePassword, setChangePassword] = React.useState(false)
    const [changePasswordMessage, setChangePasswordMessage] = React.useState(false)
    const [addressValidated, setAddressValidated] = React.useState(0)
    const [accountButton, setAccountButton] = React.useState(<Grid item></Grid>)
    

    let email = context.email
    
    
    const openPasswordChange = () =>{
        setChangePassword(true)
    }

    const closePasswordChange = () =>{
        setChangePassword(false)
    }
    
    const closeAlert = ()=>{
        setChangePasswordMessage(false)
    }

    const setAlertSuccess=()=>{
        setChangePasswordMessage(true)
    }

    const errorClose =()=>{
        setErrorAlert(false)

    }



    React.useEffect(()=>{
    
       
        if(context.email_marketing===0){
            setEmailMessage(messages.notReceivingEmail)
            setEmailMarketing(false)
            setEmail_Marketing(0)
        }else{
            setEmailMessage(messages.receivingEmail)
            setEmailMarketing(true)
            setEmail_Marketing(1)
        };
        if(context.mail_marketing===0){
            setMailMessage(messages.notReceivingMail)
            setMailMarketing(false)
            setMail_Marketing(0)
        }else{
            setMailMessage(messages.receivingMail)
            setMailMarketing(true)    
            setMail_Marketing(1)    
        }
    
    
    },[context.email_marketing, context.mail_marketing])

    const changeEmail = (event) =>{
        setEmailMarketing(event.target.checked)
        if(event.target.checked){
            setEmailMessage(messages.optinEmail)
            setEmail_Marketing(1)
            
        }else{
            setEmailMessage(messages.optoutEmail)
            setEmail_Marketing(0)
    
        }
    }

    const changeMail = (event) =>{
        setMailMarketing(event.target.checked)
        if(event.target.checked){
            setMailMessage(messages.optinMail)
            setMail_Marketing(1)
        }else{
            setMailMessage(messages.optoutMail)
            setMail_Marketing(0)
        }
    }

    const changePhoneNumber = (newValue, info) =>{
        
        setPhoneNumber(newValue)
        
        if(matchIsValidTel(info.numberValue)){
            setPhoneToStore(info.nationalNumber)
            
            setPhoneNumberError(false)
            setPhoneNumberErrorMessage("")
        }else{
            setPhoneNumberError(true)
            setPhoneNumberErrorMessage('Please enter a valid phone number')
        }
    
    }

    const formik = useFormik({
        initialValues:{ first_name: context.first_name,
            last_name: context.last_name,
            email: email,
            phone_number:  context.phone,
            street_address_1: context.street_address_1,
            street_address_2: context.street_address_2,
            city: context.city,
            state: context.state_abr,
            zip: context.zip,
            zip4: context.zip4
        },    
        
        validationSchema: validationSchema,

        onSubmit:(values, actions) =>{
            actions.setSubmitting(true)
            axios({
                method: 'POST',
                url: 'api/oracle1/uspsapi',
                data: {street1: values.street_address_1,
                       street2: values.street_address_2,
                       city: values.city,
                       zip: values.zip,
                }

            }).then(result=>{
                if(result.status===200){
                    values.street_address_1 = result.data.street1
                    values.street_address_2 = result.data.street2
                    values.city = result.data.city
                    values.state = result.data.state
                    values.zip = result.data.zip
                    values.zip4 = result.data.zip4
                    setAddressValidated(1)

                }

            }).then(()=>{

            


            
            axios({
                method: 'POST',
                url: 'api/oracle5/updateprofile',
                data: {values, 
                       contact_via_mail: mail_marketing, 
                       contact_via_email: email_marketing, 
                       customer_number: context.customer_number, 
                       old_email: email, 
                       phone: phoneToStore,
                       address_validated: addressValidated}
            })
            .then(res=>{
                if(context.mail_marketing !== mail_marketing){
                   context.mail_marketing=mail_marketing}                
                if(context.email_marketing !== email_marketing){
                    context.email_marketing= email_marketing}
                

                if(email !== values.email){
                    context.email= values.email
                    changeUserEmail(values.email)
                }
                if(res.status===200){
                    setUpdatedMessage(true)
                    actions.setSubmitting(false)
                }
                window.localStorage.setItem('context', JSON.stringify(context))
                user.email = values.email
                window.localStorage.setItem('user', JSON.stringify(user))
                setAccountButton(<Grid item><Button color='primary' variant='contained' component={RouterLink} to={'/user/accounts/'+ values.email} endIcon={<PersonIcon />} > Back to Accounts</Button> </Grid>)
                
                actions.setSubmitting(false)
                
            }).catch(err=>{
                const error_message = axiosErrorHandling(err)
                actions.setSubmitting(false)
                setErrorMessage(error_message)
                setErrorAlert(true)

            })
        }).catch(err=>{
            const error_message = axiosErrorHandling(err)
            actions.setSubmitting(false)
            setErrorMessage(error_message)
            setErrorAlert(true)

        })
        },
    
        
        
    
    })
    
    let successMessage;
    
    

    if (updatedMessage){successMessage = <Typography variant='h6' color='secondary'>**Profile Updated**</Typography>}
    // if(formik.dirty){setUpdatedMessage(false)}

    return (
        
            <Box component='form' onSubmit={formik.handleSubmit} sx={{minHeight:'70vh', mt: 15, mb: 2}}>
                <Breadcrumbs>
                <Link underline='hover' component={RouterLink} disabled={formik.values.email != context.email} to={'/user/accounts/'+ formik.values.email} >Accounts</Link>
                </Breadcrumbs>
                <Grid container='row'>
                <ErrorAlert open={errorAlert}  error={errorMessage} handleClose={errorClose}/> 
                <ChangePassword open={changePassword} email={email} close={closePasswordChange} successAlert={setAlertSuccess}/>
                <ChangePasswordSucces open={changePasswordMessage} onClose={closeAlert}> </ChangePasswordSucces>
                </Grid>
                <Grid container='row' justifyContent='center'>
                    <Grid item>
                        <Typography variant='h6' color='primary'>Update Profile Here: </Typography>

                    </Grid>
                <Grid container direction='row' justifyContent='center'>
                    <Grid> 
                    {successMessage}
                    </Grid>
                </Grid>
                
                <Grid container direction='row' spacing={2} justifyContent='center'>
                    <Grid item xs={8} s={4} md={4} lg={3} >
                        <TextField
                            fullWidth
                            type='text'
                            margin='normal'
                            variant='outlined'
                            id='first_name'
                            name='first_name'
                            label='First Name' 
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                            helperText={formik.errors.first_name} />
                    </Grid>
                    <Grid item xs={8} s={4} md={4} lg={3}>
                        <TextField
                            fullWidth
                            margin='normal'
                            type='text'
                            variant='outlined'
                            id='last_name'
                            name='last_name'
                            label='Last Name' 
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                            helperText={formik.errors.last_name}
                            />
                    </Grid>
                </Grid>
                <Grid container direction='row' spacing={2} justifyContent='center' >
                    <Grid item xs={8} sm={8} md={8} lg={6}>
                        <TextField
                            fullWidth
                            margin='normal'
                            type='text'
                            variant='outlined'
                            id='email'
                            name='email'
                            label='Email'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.errors.email}
                        />
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent='center' spacing={2} >
                    <Grid item xs={8} sm={8} md={8} lg={6}>
                        <MuiTelInput
                            fullWidth
                            variant='outlined'
                            defaultCountry="US"
                            forceCallingCode
                            disableDropdown={true}
                            onlyCountries={['US']}
                            label='Phone Number'
                            id='phone'
                            name='phone'
                            type='phone'
                            value={phoneNumber}
                            onChange={changePhoneNumber}
                            error={phoneNumberError}
                            helperText={phoneNumberErrorMessage}
                              /> 
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent='center' spacing={2} >
                    <Grid item xs={8} sm={8} md={8} lg={6}>
                        <TextField
                            type='text'
                            margin='normal'
                            fullWidth
                            variant='outlined'
                            id='address'
                            name='address'
                            label='Street Address Line 1'
                            value={formik.values.street_address_1}
                            onChange={formik.handleChange}
                            error={formik.touched.street_address_1 && Boolean(formik.errors.street_address_1)}
                            helperText={formik.errors.street_address_1}
                        />
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent='center' spacing={2} sx={{mb: '1rem'}}>
                    <Grid item xs={8} sm={8} md={8} lg={6}>
                        <TextField
                            type='text'
                            margin='normal'
                            fullWidth
                            variant='outlined'
                            id='address2'
                            name='address2'
                            label='Street Address Line 2'
                            value={formik.values.street_address_2}
                            onChange={formik.handleChange}
                            error={formik.touched.street_address_2 && Boolean(formik.errors.street_address_2)}
                            helperText={formik.errors.street_address_2}
                        />
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent='center' spacing={2} sx={{mb: '1rem'}}>
                    <Grid item xs={6} sm={3} md={4} lg={2}>
                        <TextField
                            fullWidth
                            margin='none'
                            variant='outlined'
                            id='city'
                            name='city'
                            label='City'
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.errors.city}
                        />
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} lg={1}>
                        <FormControl variant='outlined'>
                            <InputLabel id='state-label'>State</InputLabel>
                            <Select
                                
                                label='state-label'
                                id='state'                                
                                defaultValue={formik.values.state}
                                value={formik.values.state}
                                onChange={formik.handleChange}

                            >
                                <ListSubheader>States</ListSubheader>
                                <MenuItem value="AL">AL</MenuItem>
                                <MenuItem value="AK">AK</MenuItem>
                                <MenuItem value="AR">AR</MenuItem>
                                <MenuItem value="AZ">AZ</MenuItem>
                                <MenuItem value="CA">CA</MenuItem>
                                <MenuItem value="CO">CO</MenuItem>
                                <MenuItem value="CT">CT</MenuItem>
                                <MenuItem value="DC">DC</MenuItem>
                                <MenuItem value="DE">DE</MenuItem>
                                <MenuItem value="FL">FL</MenuItem>
                                <MenuItem value="GA">GA</MenuItem>
                                <MenuItem value="HI">HI</MenuItem>
                                <MenuItem value="IA">IA</MenuItem>
                                <MenuItem value="ID">ID</MenuItem>
                                <MenuItem value="IL">IL</MenuItem>
                                <MenuItem value="IN">IN</MenuItem>
                                <MenuItem value="KS">KS</MenuItem>
                                <MenuItem value="KY">KY</MenuItem>
                                <MenuItem value="LA">LA</MenuItem>
                                <MenuItem value="MA">MA</MenuItem>
                                <MenuItem value="MD">MD</MenuItem>
                                <MenuItem value="ME">ME</MenuItem>
                                <MenuItem value="MI">MI</MenuItem>
                                <MenuItem value="MN">MN</MenuItem>
                                <MenuItem value="MO">MO</MenuItem>
                                <MenuItem value="MS">MS</MenuItem>
                                <MenuItem value="MT">MT</MenuItem>
                                <MenuItem value="NC">NC</MenuItem>
                                <MenuItem value="NE">NE</MenuItem>
                                <MenuItem value="NH">NH</MenuItem>
                                <MenuItem value="NJ">NJ</MenuItem>
                                <MenuItem value="NM">NM</MenuItem>
                                <MenuItem value="NV">NV</MenuItem>
                                <MenuItem value="NY">NY</MenuItem>
                                <MenuItem value="ND">ND</MenuItem>
                                <MenuItem value="OH">OH</MenuItem>
                                <MenuItem value="OK">OK</MenuItem>
                                <MenuItem value="OR">OR</MenuItem>
                                <MenuItem value="PA">PA</MenuItem>
                                <MenuItem value="RI">RI</MenuItem>
                                <MenuItem value="SC">SC</MenuItem>
                                <MenuItem value="SD">SD</MenuItem>
                                <MenuItem value="TN">TN</MenuItem>
                                <MenuItem value="TX">TX</MenuItem>
                                <MenuItem value="UT">UT</MenuItem>
                                <MenuItem value="VT">VT</MenuItem>
                                <MenuItem value="VA">VA</MenuItem>
                                <MenuItem value="WA">WA</MenuItem>
                                <MenuItem value="WI">WI</MenuItem>
                                <MenuItem value="WV">WV</MenuItem>
                                <MenuItem value="WY">WY</MenuItem>
                                <ListSubheader>Outlying Territories</ListSubheader>
                                <MenuItem value="AS">AS</MenuItem>
                                <MenuItem value="GU">GU</MenuItem>
                                <MenuItem value="MP">MP</MenuItem>
                                <MenuItem value="PR">PR</MenuItem>
                                <MenuItem value="UM">UM</MenuItem>
                                <MenuItem value="VI">VI</MenuItem>
                                <ListSubheader>Armed Forces </ListSubheader>
                                <MenuItem value="APO-AA">AA</MenuItem>
                                <MenuItem value="APO-AP">AP</MenuItem>
                                <MenuItem value="APO-AE">AE</MenuItem>
                                <MenuItem value="FPO-AA">AA</MenuItem>
                                <MenuItem value="FPO-AP">AP</MenuItem>
                                <MenuItem value="FPO-AE">AE</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2} lg={2}>
                        <TextField
                            sx={{ml: .5}}
                            fullWidth
                            margin='none'
                            variant='outlined'
                            type='phone'
                            id='zip'
                            name='zip'
                            label='Zip'
                            value={formik.values.zip}
                            onChange={formik.handleChange}
                            error={formik.touched.zip && Boolean(formik.errors.zip)}
                            helperText={formik.errors.zip}
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={1} lg={1} >
                        <TextField
                            sx={{ml: 1}}
                            margin='none'
                            variant='outlined'
                            type='phone'
                            id='zip4'
                            name='zip4'
                            label='+4'
                            value={formik.values.zip4}
                            onChange={formik.handleChange}
                            error={formik.touched.zip4 && Boolean(formik.errors.zip4)}
                            helperText={formik.errors.zip4}
                        />
                    </Grid>
                </Grid>
                <Grid container direction='column' justifyContent='center' alignItems='center'>
                    <Grid>
                        <Typography variant='h4' color='primary'>Marketing Preferences</Typography>
                    </Grid>

                    <Grid item lg={4} sx={{mb: '1rem'}}>          
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={emailMarketing} onChange={changeEmail} />} label="Please contact me via email for special promotions and offerings" />
                        <br />
                            <Typography variant='body1' color='secondary'>{emailMessage}</Typography>        
                        <br />
                        <FormControlLabel control={<Switch checked={mailMarketing} onChange={changeMail} />} label="Please contact me via us postal mail for special promotions and offerings"/>
                        <br />
                            <Typography variant='body1' color='secondary'>{mailMessage}</Typography>
                        <br />        
                    </FormGroup>
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent='center' spacing={2}>
                    {accountButton}
                    <Grid item>
                        <LoadingButton
                            loadingPosition='end'
                            loading={formik.isSubmitting}
                            endIcon={<UploadIcon />}
                            color='primary'
                            type='submit'
                            sx={{mr: 2}}
                            variant='contained'
                            size='large'
                            disabled={formik.isSubmitting||!formik.isValid||phoneNumberError}>Submit</LoadingButton>
                        <Button
                        endIcon={<VpnKeyIcon />}
                        color='secondary'
                        variant='contained'
                        size='large'
                        onClick={openPasswordChange}
                        >Change Password</Button>
                    </Grid>
                </Grid>
                
                
            </Grid>
            </Box>
            
        
    )

}