import * as React from 'react'
import axios from 'axios'
import { Typography, Grid, TextField, Box } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { LoadingButton } from '@mui/lab'
import { useLoaderData } from 'react-router-dom'
import { ErrorAlert } from '../../alerts/ErrorAlert'
import { SuccessAlert } from '../../alerts/Success'


const columns = [
    {field: 'id', headerName: 'ID', flex: 1},    
    {field: 'customer_number', headerName: 'customer_number', flex: 2},
    {field: 'paragraphOne', headerName: '1st Text Box', flex: 3},
    {field: 'paragraphTwo', headerName: '2nd Text Box', flex: 3},
    {field: 'paragraphThree', headerName: '3rd Test Box', flex: 2},
    {field: 'email', headerName: 'Email', flex: 2}
    
]  


export const FeedBackAdmin = () =>{
    
    let loaderData = useLoaderData()

    console.log('Here is the loader Data: ', loaderData)
    
    const context = JSON.parse(window.localStorage.getItem('context'))


    

    
    const [comments, setComments] = React.useState('')
    const [data, setData] = React.useState(loaderData)
    const [disableButtons, setDisableButtons] = React.useState(true)
    const [feedbackSelected, setFeedbackSelected] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    
    const closeError = () =>{
        setError(false)
    }
    

    let adminData = {first_name: context.first_name,
                     last_name: context.last_name,
                       admin_email: context.email,
                        }
    console.log('here is the admin data', adminData)



const rowClick = (row) =>{
    let i = 0
    if(!row.length || row.length!==1){setDisableButtons(true)}
    
    else{
        setDisableButtons(false)
        let rowSelected = []
        while (i<(data.length)){
            if(row.includes(data[i].id)){
                rowSelected.push(data[i])
                setFeedbackSelected(rowSelected)
                setComments(data[i].comments)
            }
            i++
        }

    }
    console.log('Here is the feedback selected: ', feedbackSelected)
}

const changeInComments = (event) =>{
    if(!event.target.value){
        setDisableButtons(true)
    
    }
    else {
        setDisableButtons(false)
        setComments(event.target.value)
    }

}

const replyClick = () => {
setLoading(true)
let replyData = [feedbackSelected[0], comments, adminData]
setComments(null)
axios({
    method: 'POST',
    url: 'api/oracle7/reply',
    data: replyData
}).then((result)=>{
    console.log('here is the result of the reply call', result)
    if(result.status===200){
        axios({method:'GET',
                url: 'api/oracle2/getfeedback'})
                .then(results=>{
                    setData(results.data)
                    setLoading(false)
                    let succesMessage = result.data
                    return SuccessAlert(succesMessage)

                })
        
    }else{

        let error = ('Here is the error in the reply call: ', result.statusText)
        setLoading(false)
        setErrorMessage(error)
        setError(true)

    }

})
console.log('Here is the reply data: ', replyData)
setLoading(false)

}

const closeClick = () =>{
    setLoading(true)
    setComments('')
    let closeData = [feedbackSelected[0], comments, adminData]
    axios({
        method: 'POST',
        url: 'api/oracle7/close',
        data: closeData
    }).then((result)=>{
        console.log('here is the result of the reply call', result)
        if(result.status===200){
            axios({method:'GET',
                url: 'api/oracle2/getfeedback'})
                .then(results=>{
                    setData(results.data)
                    setLoading(false)
                    let successMess = 'Feedback case Closed' + result.data
                    return SuccessAlert(successMess)
                })
        
    
        }else{
    
            let errMessage = ('Here is the error: ', result.statusText)
            setLoading(false)
            setErrorMessage(errMessage)
            setError(true)

        }
    
    })
    
    console.log("Here is the closeClick data: ", closeData)
    setLoading(false)

}



return (
    
    <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
        <ErrorAlert open={error} handleClose={closeError} error={errorMessage}/>
        <Grid container direction='column'>
            <Grid item>
                <Typography variant='h3' align='center' color='secondary'>Feedback Page</Typography>
             </Grid>
            <Grid item xs={10}>
                <DataGridPro
                    sx={{mb: '1rem'}} 
                    rows={data}
                    loading={data.length===0}
                    columns={columns}
                    autoPageSize={true}
                    checkboxSelection
                    disableMultipleSelection={true}
                    columnBufferPx={150}
                    hideFooter={false}
                    onRowSelectionModelChange={rowClick}
                />
            </Grid>
             <Grid item xs={10}>
                <TextField
                    sx={{mb: '1rem'}} 
                    fullWidth
                    value={comments}
                    id='comments'
                    label="Comment"
                    multiline
                    rows={8}
                    variant='outlined'
                    onChange={changeInComments}
                />
            </Grid>
        </Grid>    
        <Grid container direction='row-reverse' alignItems='flex-end' justifyItems='right'>
            <Grid item sx={{m: '1rem'}}>
                <LoadingButton loading={loading} variant='contained'color='primary' disabled={disableButtons} onClick={replyClick}>Reply</LoadingButton>
            </Grid>
            <Grid item sx={{m: '1rem'}}>
                <LoadingButton loading={loading} variant='contained' color='secondary' disabled={disableButtons} onClick={closeClick}>Close</LoadingButton>

            </Grid>
        </Grid>

    
    </Box>
     
);
    


}