import * as React from 'react'
import { MainSearchForm } from '../forms/MainSearch'
import {Grid, Container, Paper, Box, Typography} from '@mui/material'



export const Main=()=>{


const path = window.localStorage.getItem('BASE_URL')





   
  return(
      <React.Fragment>
      <Container>
          <Box sx={{minHeight:'60vh', mt: 15, mb: 2}}>
      <Container>
      <Grid container
      direction='column'
      justifyContent='center'>
          <Grid item xs={12} sm={10} md={8}>
      <Paper sx={{mt: '3rem', padding: 5}}>
      <MainSearchForm />
      </Paper>
      <Typography color = 'secondary' variant='body1' align='center' alignItems='center' sx={{mb: '1rem', verticalAlign:'middle'}}>We accept the following payment methods: <img alt='E-Check' src='e-check.svg' width='60' height='40' style={{ verticalAlign:'middle' }} /><img alt='Money Order' src='money_order.svg' width='50' height='30' style={{  marginLeft: 1, verticalAlign:'middle' }} />  </Typography>
      
      <Typography color = 'secondary' variant='body1' align='center' sx={{mb: '1rem'}}>***If you use E-Check through the website, your bank information is not stored and you will only be debited the amount as described.***</Typography>
      </Grid>
      
      </Grid>
      
      </Container>
      </Box >
      
      </Container>
     
      </React.Fragment>
  )

}