import * as React from 'react'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import { Typography, Box, ButtonGroup, Button, Grid, Breadcrumbs, Link } from '@mui/material'
import { round } from '../../misc/other'
import { PaybyCC } from '../../forms/PaybyCC'
import { PaybyCheck } from '../../forms/PaybyCheck'
import { RecurringPayment } from '../../forms/ReccuringPayments'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import EventRepeatIcon from '@mui/icons-material/EventRepeat';



export const UserPayment =() =>{

    const context = JSON.parse(window.localStorage.getItem('context'))
    const location = useLocation()
    
    
    const accountpath = '/user/accounts/' + context.email
    const paymentInfo = location.state
    let totalDue = paymentInfo.totalDue
    paymentInfo.monthlyBill = round(totalDue/6)
    paymentInfo.numberofMonths = 6

    const [payByCC, setPayByCC] = React.useState(null)
    const [payByCheck, setPayByCheck] = React.useState(null)
    const [recurringPayment, setRecurringPayment] = React.useState(null)

const onPayByCCClick = () =>{
    setPayByCheck(null)
    setRecurringPayment(null)
    if(payByCC){setPayByCC(null)}else{
        setPayByCC(<PaybyCC paymentInfo={paymentInfo} customerInfo={context}/>)
    }
}

const onPayByCheckClick = () =>{
    setPayByCC(null)
    setRecurringPayment(null)
    if(payByCheck){setPayByCC(null)}else{
        setPayByCheck(<PaybyCheck paymentInfo={paymentInfo} customerInfo={context}/>)
    }
}

const onRecurringPaymentClick = () =>{
    setPayByCheck(null)
    setPayByCC(null)
    if(recurringPayment){setRecurringPayment(null)}else{
        setRecurringPayment(<RecurringPayment paymentInfo={paymentInfo} customerInfo={context}/>)
    }
}



return(
    <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
        <Breadcrumbs sx={{mb: 2}}>
            <Link underline='hover' component={RouterLink} to={accountpath}>Accounts</Link>
            <Link underline='none' color='gray'>Confirm Debt</Link>
            <Link underline='none' color='gray'>Propose Settlement</Link>
        </Breadcrumbs>
        <Grid container direction='column' alignContent='center' justifyContent='center' spacing={3} >
            <Grid item>    
                <Typography variant='h4' align='center' >Enter Payment Type </Typography>
            </Grid>
            <Grid item alignContent='center' alignSelf='center'>
                <ButtonGroup>
                    <Button endIcon={<CreditCardIcon />} onClick={onPayByCCClick}>Credit Card</Button>
                    <Button endIcon={<AccountBalanceIcon />} onClick={onPayByCheckClick}>E-Check</Button>
                    <Button endIcon={<EventRepeatIcon />} onClick={onRecurringPaymentClick}>Set Up Monthly Payment</Button>
                </ButtonGroup>
            </Grid>
            <Grid item>
                   {payByCC}
                   {payByCheck}
                   {recurringPayment}      
            </Grid>
        </Grid>
        </Box>
)

}
