import * as React from 'react'
import { Typography, Box, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { VerifyBillingForm } from './VerifyBilling'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { LoadingButton } from '@mui/lab'
import PaymentIcon from '@mui/icons-material/Payment';


export const PaybyCC = ({customerInfo, paymentInfo}) =>{
    



 const [billingForm, setBillingForm] = React.useState(null)




const onVerifyClick = () =>{
    if (billingForm){
        setBillingForm(null)
    } else {
        setBillingForm(<VerifyBillingForm customerInfo={customerInfo}/>)
    }
}

return(
    <Box sx={{minHeight:'75vh', mt: 2, mb: 2}}>
         <Grid container direction='row' sx={{mb: 2}}>
            <Grid item>
                <Typography>Please enter Payment Information</Typography>
            </Grid>
         </Grid>
         <Grid container direction='row' spacing={1} mb={1}>
            <Grid item xs={12}>
            <TextField
             fullWidth
         id='card_number'
         label="Card Number"
         type='number'
         required
        />
            </Grid>
            
        </Grid>
        <Grid container direction='row' spacing={1} sx={{mb:2}}>
        <Grid item xs={4}>
            <TextField
            id='cvv'
            label="CVV"
            type='number'
            required
            />
            </Grid>
            <Grid item xs={4}>
                <FormControl variant='outlined' fullWidth>
                    <InputLabel>Exp Month</InputLabel>
                        <Select
                        label='Exp Month'
                        id='month'
                        fullWidth
                         >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                        </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl variant='outlined' fullWidth>
                    <InputLabel>Exp Year</InputLabel>
                        <Select
                        label='Exp Year'
                        id='year'
                        fullWidth
                         >
                            <MenuItem value={24}>24</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={26}>26</MenuItem>
                            <MenuItem value={27}>27</MenuItem>
                            <MenuItem value={28}>28</MenuItem>
                            <MenuItem value={29}>29</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            
                        </Select>
                </FormControl>
            </Grid>
        </Grid>
        <Grid container direction='row-reverse' spacing={0} sx={{mb:3}}>
            <Grid xs={4}>
                <LoadingButton startIcon={<PaymentIcon/>} fullWidth variant='contained'> Pay ${paymentInfo.totalDue}</LoadingButton>
            </Grid>
        </Grid>
         
        <Grid container direction='column'>
            <Grid item xs={12}>
                <Button variant='outlined' color='secondary' fullWidth onClick={onVerifyClick} endIcon={<AccountBoxIcon />}>Verify Billing Info</Button>
            </Grid>
            {billingForm}
        </Grid>
        
    </Box>
)
}