import * as React from 'react'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import { Box,
         AppBar,
         Toolbar,
         Hidden,
         Button,
         IconButton,
         Menu,
         MenuItem,
         Grid,
         Typography,
         Tab,
         Tabs,
         ListItemButton,
         MenuList,
         ListItemText,
         ListItemIcon 

 } from '@mui/material'
 import MenuIcon from '@mui/icons-material/Menu'
 import HomeIcon from '@mui/icons-material/Home'; 
 import AccountBoxIcon from '@mui/icons-material/AccountBox';
 import { useAuth } from '../../misc/auth';
 import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
 import PersonIcon from '@mui/icons-material/Person'
 import DescriptionIcon from '@mui/icons-material/Description'
 import LogoutIcon from '@mui/icons-material/Logout'
 import DashboardIcon from '@mui/icons-material/Dashboard';

export const MainHeader =({pages}) =>{

    

    const [toolbar, setToolbar] = React.useState(null)
    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const location = useLocation()
    const navigate = useNavigate()
    const pathname = location.pathname
    
    


const MenuItems = pages.map((a)=>{ return (<MenuItem 
key={a.label} onClick={ ()=>handleCloseNavMenu(a.path)}> 
<Typography textAlign='center'>{a.label} </Typography>
</MenuItem>)



})


    const handleOpenNavMenu = (event) => {
    
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = (path) =>{
        setAnchorElNav(null)
        if (path){
            navigate(path)
        }
    }
    

 
    const headerAction = (pathname, elNav) =>{
        let button;

     

    switch (pathname){
        case '/' :
            button = <Button color='secondary' variant='contained' size = 'large' endIcon= {<AccountBoxIcon/>} component={Link} to="/login" sx={{marginLeft:'auto', marginRight: '1rem'}}          
           >Login</Button>
           setToolbar(
            <Toolbar>
                <img alt='Splat Logo' src='splat_logo.svg' width='100' height='100' />
                <Hidden only = {['sm', 'xs']}>
                    <img alt='Name My Settlement' src='transparent_name_only.svg' width='300' height='100' />  
                {button}
                </Hidden>
                <Hidden>
                <Box sx={{ flexGrow: 1,display: { xs: "flex", md: "none" }}}>
            <IconButton
               id='home-menu'
               size="large"
               edge='start'
               aria-label="Home Menu"
               aria-controls="home-menu"
               aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
              sx={{marginLeft: 'auto'}}
            >
              <MenuIcon />
            </IconButton>
                <Menu
                id='home-menu'
                open={Boolean(elNav)}
                anchorEl={elNav}
                onClose={handleCloseNavMenu}
                sx={{
                    display: {xs: 'block', md: 'none'}
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',

                }}
                
                >
                
                {MenuItems}
                </Menu>
                </Box>
            </Hidden>
            </Toolbar>
           )
           break;

           case '/login' :
            button = <Button color='secondary' variant='contained' size = 'large' endIcon= {<HomeIcon />} component={Link} to="/" sx={{marginLeft:'auto', marginRight: '1rem'}}          
           >Home</Button>
           setToolbar(
            <Toolbar>
                <img alt='Splat Logo' src='splat_logo.svg' width='100' height='100' />
                <Hidden only = {['sm', 'xs']}>
                    <img alt='Name My Settlement' src='transparent_name_only.svg' width='300' height='100' />  
                {button}
                </Hidden>
                <Hidden>
                <Box sx={{ flexGrow: 1,display: { xs: "flex", md: "none" }}}>
            <IconButton
               id='home-menu'
               size="large"
               edge='start'
               aria-label="Home Menu"
               aria-controls="home-menu"
               aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
              sx={{marginLeft: 'auto'}}
            >
              <MenuIcon />
            </IconButton>
                <Menu
                id='home-menu'
                open={Boolean(elNav)}
                anchorEl={elNav}
                onClose={handleCloseNavMenu}
                sx={{
                    display: {xs: 'block', md: 'none'}
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',

                }}
                
                >
                
                {MenuItems}
                </Menu>
                </Box>
            </Hidden>
            </Toolbar>
           )
           break;
        
        case '/confirmation/' : 
        case '/FAQ':
        case '/privacy-policy':
        case '/refund-policy':    
        case '/termsandconditions' :
        case '/unsubscribe' :     
            
             button = <React.Fragment>
                <Grid container direction='row' alignContent='flex-end' alignItems='flex-end'>
                   <Grid item xs={4} sm={6} md={4} lg={8}></Grid> 
                    <Grid item md={8} lg={4}>
             <Button color='primary' variant='contained' size = 'large' endIcon= {<HomeIcon />} component={Link} to="/" sx={{margin: '1rem'}}         
            >Home</Button> <Button color='secondary' variant='contained' size = 'large' endIcon= {<AccountBoxIcon/>} component={Link} to="/login" sx={{marginLeft:'auto', marginRight: '1rem'}}          
             >Login</Button>
             </Grid>
             </Grid>
             </React.Fragment>
            
            
            setToolbar(
                <Toolbar disableGutters>
                    <img alt='Splat Logo' src='splat_logo.svg' width='100' height='100'/>
                    <Hidden only={['sm', 'xs']}>
                    <img alt='Name My Settlement' src='transparent_name_only.svg' width='300' height='100'/>
                    {button}
                    </Hidden>
                    <Hidden only={['md', 'lg', 'xl']}>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              id='home-menu'
              size="large"
              edge='start'
              aria-label="Home Menu"
              aria-controls="home-menu"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
              sx={{marginLeft: 'auto'}}
            >
              <MenuIcon />
            </IconButton>
                <Menu
                id='home-menu'
                anchorEl={elNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'

                }}
                open={Boolean(elNav)}
                onClose={handleCloseNavMenu}
                sx={{
                    display: {xs: 'block', md: 'none', color: '#565656'}
                }}
                >
                <MenuItem key={'login'}>
                    <Typography textAlign='center'>Login</Typography>
                </MenuItem>
                    {MenuItems}
                </Menu>
                </Box>
            </Hidden>
                </Toolbar>)
        break;
        default:
            button = <React.Fragment>
            <Grid container direction='row' alignContent='flex-end' alignItems='flex-end'>
               <Grid item xs={4} sm={6} md={4} lg={8}></Grid> 
                <Grid item md={8} lg={4}>
         <Button color='primary' variant='contained' size = 'large' endIcon= {<HomeIcon />} component={Link} to="/" sx={{margin: '1rem'}}         
        >Home</Button> <Button color='secondary' variant='contained' size = 'large' endIcon= {<AccountBoxIcon/>} component={Link} to="/login" sx={{marginLeft:'auto', marginRight: '1rem'}}          
         >Login</Button>
         </Grid>
         </Grid>
         </React.Fragment>
        
        
        setToolbar(
            <Toolbar disableGutters>
                <img alt='Splat Logo' src='splat_logo.svg' width='100' height='100'/>
                <Hidden only={['sm', 'xs']}>
                <img alt='Name My Settlement' src='transparent_name_only.svg' width='300' height='100'/>
                {button}
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <IconButton
          id='home-menu'
          size="large"
          edge='start'
          aria-label="Home Menu"
          aria-controls="home-menu"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="primary"
          sx={{marginLeft: 'auto'}}
        >
          <MenuIcon />
        </IconButton>
            <Menu
            id='home-menu'
            anchorEl={elNav}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'

            }}
            open={Boolean(elNav)}
            onClose={handleCloseNavMenu}
            sx={{
                display: {xs: 'block', md: 'none', color: '#565656'}
            }}
            >
            <MenuItem key={'login'}>
                <Typography textAlign='center'>Login</Typography>
            </MenuItem>

            </Menu>
            </Box>
        </Hidden>
            </Toolbar>)
    break;


    }
}

React.useLayoutEffect(()=>{
    // eslint-disable-next-line react-hooks/exhaustive-deps
        headerAction(pathname, anchorElNav)
        
        
    
    
    },[pathname, anchorElNav]) 

    return(
        <AppBar position='static' sx={{backgroundColor: '#F5F8FF'}}>
            {toolbar}
        </AppBar>
    )


}

export const UserHeader =({pages, email})=>{
    const [toolbar, setToolbar] = React.useState(null)
    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const navigate = useNavigate()
    const {logout} =useAuth()
    const [tabView, setTabView] = React.useState(0)
    const location = useLocation()
    const pathname = location.pathname

    const handleOpenNavMenu = (event) =>{
        setAnchorElNav(event.currentTarget)


    }

    const handleCloseNavMenu = (path) =>{
        setAnchorElNav(null)
        if(path){
            navigate(path)
        }
    }

    const baseURL = window.localStorage.getItem('BASE_URL')
    const userAccountsPath = '/user/accounts/' + email
    const splatURL = baseURL + '/splat_logo.svg'
    const TransparentNameOnlyURL = baseURL + '/transparent_name_only.svg'
    
    const handleTabChange = (e, value) =>{
        setTabView(value)
    }


    const switchHeader = (pathname, elNav) =>{
        
        let button;

        switch(pathname){

            
           
           case userAccountsPath :
           case '/user/completed':
           case '/user/confirmation':
           case '/user/confirmaccounts':
           case '/user/FAQ' : 
           case '/user/payment' :
           case '/user/oldpayment' : 
           case '/user/recurringsetup':
           case '/user/settlement':
            
    
           
            if(tabView !==0 ){
                setTabView(0)
            } 
               button = <Button color= 'primary' variant= 'contained' component={Link} to="/login" onClick={logout} sx={{ml: 'auto'}} endIcon= {<LogoutIcon />}
               >Log Out</Button>; 
               setToolbar( <Toolbar>
                <Hidden only={['sm','xs']}>
                <img alt='Splat Logo' src={splatURL} width='100' height='100'/>
                </Hidden>
                <Hidden only={['sm','xs','md']}>
                <img alt='Name My Settlement' src={TransparentNameOnlyURL} width='300' height='100'/>
                
                </Hidden>
                <Hidden only={['xs']}>
                <Tabs value={tabView} textColor='secondary'  sx={{ml: 'auto'}} onChange={handleTabChange}>
                   <Tab value={0} sx={{textTransform:'none', fontWeight: 700}} component={Link} to={userAccountsPath} icon={<AttachMoneyIcon/>} label='ACCOUNTS'/>
                   <Tab value={1} sx={{textTransform:'none', fontWeight: 700}} component={Link} to='/user/documents' icon={<DescriptionIcon/>} label='DOCUMENTS'/>
                   <Tab value={2} sx={{textTransform:'none', fontWeight: 700}} component={Link} to='/user/profile' icon={<PersonIcon/>} label ='PROFILE' />   
                   </Tabs>
               </Hidden> 
               <Hidden only={['sm','md','lg','xl']}>
                   
               
                      
    
                <IconButton size="large"
                    id='user-menu'
                    edge='end'
                    aria-label='User Menu'
                    aria-controls='user-menu'
                    aria-haspopup='true'
                    onClick={handleOpenNavMenu}>
                   <MenuIcon  />
               </IconButton>
                    <Menu  anchorEl={elNav}
                    open={Boolean(elNav)}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',   
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    onClose={handleCloseNavMenu}
                    sx={{ color: '#565656', 
                        display: {xs: 'block', md: 'none', color: '#565656'}
                    }}
                    >
    
                        <MenuList>
                            <MenuItem onClick={()=>{ handleCloseNavMenu();  setTabView(0)}}>
                            <ListItemButton  
                                  divider  
                                  component={Link} 
                                  to={userAccountsPath} 
                                  selected={tabView === 0} >
                              <ListItemIcon>
                                  <AttachMoneyIcon/>
                              </ListItemIcon>
                              <ListItemText sx={{color: '#565656'}} primary='Accounts'/>
                          </ListItemButton> 
                            </MenuItem>
                            <MenuItem>
                             <ListItemButton onClick={()=>{ handleCloseNavMenu(); setTabView(1)}} 
                          divider 
                          component={Link} 
                          to='/user/documents'
                          selected={tabView === 1}>
                              <ListItemIcon>
                                  <DescriptionIcon/>
                              </ListItemIcon>
                              <ListItemText sx={{color: '#565656'}} primary='Documents'/>
                          </ListItemButton>
                            </MenuItem>
                            <MenuItem>
                              <ListItemButton onClick={()=>{ handleCloseNavMenu(); setTabView(2)}} 
                                    divider 
                                    component={Link} to='/user/profile'
                                    selected={tabView === 2}>
                              <ListItemIcon>
                                  <PersonIcon/>
                              </ListItemIcon>
                              <ListItemText sx={{color: '#565656'}} primary='Profile'/>
                          </ListItemButton>
                            </MenuItem>
                        </MenuList>
    
                    </Menu>
               </Hidden>
      
                {button}
             </Toolbar> 
    
    
    
               )   
                break;
                case '/user/profile':
                    if(tabView !==2 ){
                        setTabView(2)
                    } 
                       button = <Button color= 'primary' variant= 'contained' component={Link} to="/login" onClick={logout} sx={{ml: 'auto'}} endIcon= {<LogoutIcon />}
                       >Log Out</Button>; 
                       setToolbar( <Toolbar>
                        <Hidden only={['sm','xs']}>
                        <img alt='Splat Logo' src={splatURL} width='100' height='100'/>
                        </Hidden>
                        <Hidden only={['sm','xs','md']}>
                        <img alt='Name My Settlement' src={TransparentNameOnlyURL} width='300' height='100'/>
                        
                        </Hidden>
                        <Hidden only={['xs']}>
                        <Tabs value={tabView} textColor='secondary'  sx={{ml: 'auto'}} onChange={handleTabChange}>
                           <Tab value={0} sx={{textTransform:'none', fontWeight: 700}} component={Link} to={userAccountsPath} icon={<AttachMoneyIcon/>} label='ACCOUNTS'/>
                           <Tab value={1} sx={{textTransform:'none', fontWeight: 700}} component={Link} to='/user/documents' icon={<DescriptionIcon/>} label='DOCUMENTS'/>
                           <Tab value={2} sx={{textTransform:'none', fontWeight: 700}} component={Link} to='/user/profile' icon={<PersonIcon/>} label ='PROFILE' />   
                           </Tabs>
                       </Hidden> 
                       <Hidden only={['sm','md','lg','xl']}>
    
    
                <IconButton size="large"
                    id='user-menu'
                    edge='end'
                    aria-label='User Menu'
                    aria-controls='user-menu'
                    aria-haspopup='true'
                    onClick={handleOpenNavMenu}>
                   <MenuIcon  />
               </IconButton>
                    <Menu  anchorEl={elNav}
                    open={Boolean(elNav)}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',   
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    onClose={handleCloseNavMenu}
                    sx={{ color: '#565656', 
                        display: {xs: 'block', md: 'none', color: '#565656'}
                    }}
                    >
    
                        <MenuList>
                            <MenuItem>
                            <ListItemButton onClick={()=>{handleCloseNavMenu(); setTabView(0)}} 
                                  divider  
                                  component={Link} 
                                  to={userAccountsPath}
                                  selected={tabView === 0} >
                              <ListItemIcon>
                                  <AttachMoneyIcon/>
                              </ListItemIcon>
                              <ListItemText sx={{color: '#565656'}} primary='Accounts'/>
                          </ListItemButton> 
                            </MenuItem>
                            <MenuItem>
                             <ListItemButton onClick={()=>{setTabView(1)}} 
                          divider 
                          component={Link} 
                          to='/user/documents'
                          selected={tabView === 1}>
                              <ListItemIcon>
                                  <DescriptionIcon/>
                              </ListItemIcon>
                              <ListItemText sx={{color: '#565656'}} primary='Documents'/>
                          </ListItemButton>
                            </MenuItem>
                            <MenuItem>
                              <ListItemButton onClick={()=>{handleCloseNavMenu(); setTabView(2)}} 
                                    divider 
                                    component={Link} to='/user/profile'
                                    selected={tabView === 2}>
                              <ListItemIcon>
                                  <PersonIcon/>
                              </ListItemIcon>
                              <ListItemText sx={{color: '#565656'}} primary='Profile'/>
                          </ListItemButton>
                            </MenuItem>
                        </MenuList>
    
                    </Menu>
    
    
                       </Hidden>
              
                        {button}
                     </Toolbar> 
            
            
            
                       ) 
                      break;
                      case '/user/documents':
                    if(tabView !==1 ){
                        setTabView(1)
                    } 
                       button = <Button color= 'primary' variant= 'contained' component={Link} to="/login" onClick={logout} sx={{ml: 'auto'}} endIcon= {<LogoutIcon />}
                       >Log Out</Button>; 
                       setToolbar( <Toolbar>
                        <Hidden only={['sm','xs']}>
                        <img alt='Splat Logo' src={splatURL} width='100' height='100'/>
                        </Hidden>
                        <Hidden only={['sm','xs','md']}>
                        <img alt='Name My Settlement' src={TransparentNameOnlyURL} width='300' height='100'/>
                        
                        </Hidden>
                        <Hidden only={['xs']}>
                        <Tabs value={tabView} textColor='secondary'  sx={{ml: 'auto'}} onChange={handleTabChange}>
                           <Tab value={0} sx={{textTransform:'none', fontWeight: 700}} component={Link} to={userAccountsPath} icon={<AttachMoneyIcon/>} label='ACCOUNTS'/>
                           <Tab value={1} sx={{textTransform:'none', fontWeight: 700}} component={Link} to='/user/documents' icon={<DescriptionIcon/>} label='DOCUMENTS'/>
                           <Tab value={2}  sx={{textTransform:'none', fontWeight: 700}} component={Link} to='/user/profile' icon={<PersonIcon/>} label ='PROFILE' />   
                           </Tabs>
                       </Hidden> 
                       <Hidden only={['sm','md','lg','xl']}>
                           
                       <IconButton size="large"
                    id='user-menu'
                    edge='end'
                    aria-label='User Menu'
                    aria-controls='user-menu'
                    aria-haspopup='true'
                    onClick={handleOpenNavMenu}>
                   <MenuIcon  />
               </IconButton>
                    <Menu  anchorEl={elNav}
                    open={Boolean(elNav)}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',   
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    onClose={handleCloseNavMenu}
                    sx={{ color: '#565656', 
                        display: {xs: 'block', md: 'none', color: '#565656'}
                    }}
                    >
    
                        <MenuList>
                            <MenuItem>
                            <ListItemButton onClick={()=>{handleCloseNavMenu(); setTabView(0)}} 
                                  divider  
                                  component={Link} 
                                  to={userAccountsPath} 
                                  selected={tabView === 0} >
                              <ListItemIcon>
                                  <AttachMoneyIcon/>
                              </ListItemIcon>
                              <ListItemText sx={{color: '#565656'}} primary='Accounts'/>
                          </ListItemButton> 
                            </MenuItem>
                            <MenuItem>
                             <ListItemButton onClick={()=>{setTabView(1)}} 
                          divider 
                          component={Link} 
                          to='/user/documents'
                          selected={tabView === 1}>
                              <ListItemIcon>
                                  <DescriptionIcon/>
                              </ListItemIcon>
                              <ListItemText sx={{color: '#565656'}} primary='Documents'/>
                          </ListItemButton>
                            </MenuItem>
                            <MenuItem>
                              <ListItemButton onClick={()=>{handleCloseNavMenu(); setTabView(2)}} 
                                    divider 
                                    component={Link} to='/user/profile'
                                    selected={tabView === 2}>
                              <ListItemIcon>
                                  <PersonIcon/>
                              </ListItemIcon>
                              <ListItemText sx={{color: '#565656'}} primary='Profile'/>
                          </ListItemButton>
                            </MenuItem>
                        </MenuList>
    
                    </Menu>
                       </Hidden>
              
                        {button}
                     </Toolbar> 
            
            
            
                       ) 
                      break;  
            default:
                
                button =  <Button color='primary' variant='contained' size = 'large' endIcon= {<HomeIcon />} component={Link} to="/" sx={{marginLeft:'auto' }}          
                     >Home</Button>
                
                setToolbar(
                <Toolbar>
                    <img alt='Splat Logo' src={splatURL} width='100' height='100'/>
                    <Hidden only={['sm', 'xs']}>
                    <img alt='Name My Settlement' src={TransparentNameOnlyURL} width='300' height='100'/>
                    {button}
                    </Hidden>
                    <Hidden only={['md', 'lg', 'xl']}>
                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  edge='start'
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="primary"
                  sx={{marginLeft: 'auto'}}
                >
                  <MenuIcon />
                </IconButton>
                    <Menu
                    id='menu-appbar'
                    anchorEl={elNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
    
                    }}
                    open={Boolean(elNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: {xs: 'block', md: 'none'}
                    }}>
                        {pages?.map((page)=>(
                            <MenuItem
                            key={page.label}
                            onClick={()=>handleCloseNavMenu(page.path)}
                            >
                            <Typography textAlign='center'>{page.label}</Typography>
                            </MenuItem>
                        ))
                        }
                        <MenuItem key={'logout'} onClick={logout}>
                                <Typography textAlign='center'>Logout</Typography>
                            </MenuItem>
                        
    
                    </Menu>
                    </Box>
                </Hidden>
                </Toolbar>) ;



        }


    }


    React.useLayoutEffect(()=>{
        // eslint-disable-next-line react-hooks/exhaustive-deps
            switchHeader(pathname, anchorElNav)
            
        
        
        },[pathname, anchorElNav]) 

return(
    <AppBar sx={{backgroundColor: '#F5F8FF'}}>
        {toolbar}
    </AppBar>
)
    

}

export const AdminHeader = ({pages,email}) =>{
    const context = JSON.parse(window.localStorage.getItem('usercontext'))

    let accountpath = '/admin/customercare/accounts/' + context.email


        
    
    const [toolbar, setToolbar] = React.useState(null)
    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const navigate = useNavigate()
    const {logout} = useAuth()
    const baseURL = window.localStorage.getItem('BASE_URL')
    const splatURL = baseURL + '/splat_logo.svg'
    const TransparentNameOnlyURL = baseURL + '/transparent_name_only.svg'

    const handleOpenNavMenu = (event) => {
    
        setAnchorElNav(event.currentTarget)
    }
    
        function handleCloseNavMenu(path) {
           
            setAnchorElNav(null)
            
            if (path) {
                navigate(path)
            }
        }
        
    const location = useLocation()
    
    const pathname = location.pathname
    


    const switchHeader = (pathname, elNav) =>{


    let button;

switch (pathname){

    case '/admin/dashboard':
            button = <React.Fragment>
            <Grid container direction='row' alignContent='flex-end' alignItems='flex-end'>
                <Grid item xs={4} md={6} lg={8}></Grid> 
                <Grid item>
        <Button color='secondary' variant='contained' component={Link} to="/login" onClick={logout} sx={{margin: '1rem'}} endIcon= {<LogoutIcon />}
        >Log Out</Button> 
        </Grid>
        </Grid>
        </React.Fragment> 
           setToolbar(
               <Toolbar>
                   <img alt='Splat Logo' src={splatURL} width='100' height='100'/>
                   <Hidden only={['sm', 'xs']}>
                   <img alt='Name My Settlement' src={TransparentNameOnlyURL} width='300' height='100'/>
                   {button}
                   </Hidden>
                   <Hidden only={['md', 'lg', 'xl']}>
           <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
           <IconButton
             size="large"
             edge='start'
             aria-label="account of current user"
             aria-controls="menu-appbar"
             aria-haspopup="true"
             onClick={handleOpenNavMenu}
             color="primary"
             sx={{marginLeft: 'auto'}}
           >
             <MenuIcon />
           </IconButton>
               <Menu
               id='menu-appbar'
               anchorEl={elNav}
               anchorOrigin={{
                   vertical: 'bottom',
                   horizontal: 'right'
               }}
               keepMounted
               transformOrigin={{
                   vertical: 'top',
                   horizontal: 'right'

               }}
               open={Boolean(elNav)}
               onClose={handleCloseNavMenu}
               sx={{
                   display: {xs: 'block'}
               }}>
                   {pages?.map((page)=>(
                       <MenuItem
                       key={page.label}
                       onClick={()=>handleCloseNavMenu(page.path)}
                       >
                       <Typography textAlign='center'>{page.label}</Typography>
                       </MenuItem>
                   ))}
                   

               </Menu>
               </Box>
           </Hidden>
               </Toolbar>)
break;
        case '/admin/addcustomers' :
        case '/admin/addemailtemplate' :    
        case '/admin/addpromo' :
        case '/admin/customercare' :
        case accountpath :
        case '/admin/customercare/confirm' :
        case '/admin/customercare/confirmaccounts' :
        case '/admin/customercare/documents' :
        case '/admin/customercare/payment' :        
        case '/admin/customercare/settlement' :             
        case '/admin/feedback' :   
        case '/admin/sendmarketingemail':    
        case '/admin/validateemail':        
            button = <React.Fragment>
            <Grid container direction='row' alignContent='flex-end' alignItems='flex-end'>
                <Grid item xs={4} md={6} lg={8}></Grid> 
                <Grid item>
        <Button color='secondary' variant='contained' component={Link} to="/login" onClick={logout} sx={{margin: '1rem'}} endIcon= {<LogoutIcon />}
        >Log Out</Button> 
        <Button color='primary' variant='contained' component={Link} to="/admin/dashboard"  endIcon= {<DashboardIcon />}
        >Dashboard</Button>
        </Grid>
        </Grid>
        </React.Fragment> 
           setToolbar(
               <Toolbar>
                   <img alt='Splat Logo' src={splatURL} width='100' height='100'/>
                   <Hidden only={['sm', 'xs']}>
                   <img alt='Name My Settlement' src={TransparentNameOnlyURL} width='300' height='100'/>
                   {button}
                   </Hidden>
                   <Hidden only={['md', 'lg', 'xl']}>
           <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
           <IconButton
             size="large"
             edge='start'
             aria-label="account of current user"
             aria-controls="menu-appbar"
             aria-haspopup="true"
             onClick={handleOpenNavMenu}
             color="primary"
             sx={{marginLeft: 'auto'}}
           >
             <MenuIcon />
           </IconButton>
               <Menu
               id='menu-appbar'
               anchorEl={elNav}
               anchorOrigin={{
                   vertical: 'bottom',
                   horizontal: 'right'
               }}
               keepMounted
               transformOrigin={{
                   vertical: 'top',
                   horizontal: 'right'

               }}
               open={Boolean(elNav)}
               onClose={handleCloseNavMenu}
               sx={{
                   display: {xs: 'block'}
               }}>
                   {pages?.map((page)=>(
                       <MenuItem
                       key={page.label}
                       onClick={()=>handleCloseNavMenu(page.path)}
                       >
                       <Typography textAlign='center'>{page.label}</Typography>
                       </MenuItem>
                   ))
                   
                   }
                   <MenuItem key={'logout'} onClick={logout}>
                           <Typography textAlign='center'>Logout</Typography>
                       </MenuItem>

               </Menu>
               </Box>
           </Hidden>
               </Toolbar>)
       break;
       default:
        button = <React.Fragment>
            <Grid container direction='row' alignContent='flex-end' alignItems='flex-end'>
                <Grid item xs={4} md={6} lg={8}></Grid> 
                <Grid item>
        <Button color='secondary' variant='contained' component={Link} to="/login" onClick={logout} sx={{margin: '1rem'}} endIcon= {<LogoutIcon />}
        >Log Out</Button> 
        </Grid>
        </Grid>
        </React.Fragment> 
           setToolbar(
               <Toolbar>
                   <img alt='Splat Logo' src={splatURL} width='100' height='100'/>
                   <Hidden only={['sm', 'xs']}>
                   <img alt='Name My Settlement' src={TransparentNameOnlyURL} width='300' height='100'/>
                   {button}
                   </Hidden>
                   <Hidden only={['md', 'lg', 'xl']}>
           <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
           <IconButton
             size="large"
             edge='start'
             aria-label="account of current user"
             aria-controls="menu-appbar"
             aria-haspopup="true"
             onClick={handleOpenNavMenu}
             color="primary"
             sx={{marginLeft: 'auto'}}
           >
             <MenuIcon />
           </IconButton>
               <Menu
               id='menu-appbar'
               anchorEl={elNav}
               anchorOrigin={{
                   vertical: 'bottom',
                   horizontal: 'right'
               }}
               keepMounted
               transformOrigin={{
                   vertical: 'top',
                   horizontal: 'right'

               }}
               open={Boolean(elNav)}
               onClose={handleCloseNavMenu}
               sx={{
                   display: {xs: 'block'}
               }}>
                   {pages?.map((page)=>(
                       <MenuItem
                       key={page.label}
                       onClick={()=>handleCloseNavMenu(page.path)}
                       >
                       <Typography textAlign='center'>{page.label}</Typography>
                       </MenuItem>
                   ))}
                   

               </Menu>
               </Box>
           </Hidden>
               </Toolbar>)



}


}

React.useLayoutEffect(()=>{
    // eslint-disable-next-line react-hooks/exhaustive-deps
        switchHeader(pathname, anchorElNav)
        
    
    
    },[pathname, anchorElNav]) 

return(
    
    <AppBar sx={{backgroundColor: '#F5F8FF' }}>
        {toolbar}
    </AppBar>
    
    )
    


}