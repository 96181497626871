import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Grid, Typography, Button, Box } from '@mui/material'





export const MoneyOrder = ()=>{
    const location = useLocation()
    const context = JSON.parse(window.localStorage.getItem('context'))
    const path = '/user/accounts/' + context.email
    const totalDue = location.state.totalDue

    
    
    return(
        
        <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
        <Grid container direction='row' justifyContent='center' alignItems ='center'>
        <Grid item xs={7} sm={6} md={6} lg={4}>
        <Typography variant='h6'gutterBottom={true}> To complete payment by money order, make the money order 
                                          to NameMySettlement.com with the amount of ${totalDue}. Please send the Money Order to the following address, PO Box 15333, Spokane Valley, WA 99215. Please include your offer 
                                          letter in the envelope.   It is best to send the Money Order certified mail with a return on receipt service to verify that 
                                          we have received the Money Order. </Typography>
        </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid item>
        <Button component={Link} to={path}  variant='contained' color='primary'> Go Back To Account Home </Button>
        </Grid>
        
        </Grid>
        
        
        </Box>
        
        
    
    )

}