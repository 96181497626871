import * as React from 'react'
import { Typography, Box, Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { VerifyBillingForm } from './VerifyBilling'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { PayByCreditRec } from './RecurringCC'
import { round } from '../misc/other'
import { PaybyCheckRec } from './RecurringCheck'


export const RecurringPayment = ({customerInfo, paymentInfo}) =>{
    
    
    
    const [billingForm, setBillingForm] = React.useState(null)
    const [newPaymentInfo, setNewPaymentInfo] = React.useState(paymentInfo)
    const [paymentModule, setPaymentModule] = React.useState(<PayByCreditRec  paymentInfo={paymentInfo} customerInfo={customerInfo}/>)
    const [paymentSelection, setPaymentSelection] = React.useState('credit')

    const onVerifyClick = () =>{
        if (billingForm){
            setBillingForm(null)
        } else {
            setBillingForm(<VerifyBillingForm customerInfo={customerInfo}/>)
        }
    }

    

    const onPaymentNumberChange =(event) =>{
        
        let oldPaymentInfo = newPaymentInfo 
        let totalDue = oldPaymentInfo.totalDue
        oldPaymentInfo.monthlyBill = round(totalDue / event.target.value)
        oldPaymentInfo.numberofMonths = event.target.value
        setNewPaymentInfo(oldPaymentInfo)
        if (paymentSelection === 'credit'){
            setPaymentModule(<PayByCreditRec paymentInfo={oldPaymentInfo} customerInfo={customerInfo}/>)
        } else {
            setPaymentModule(<PaybyCheckRec paymentInfo={oldPaymentInfo} customerInfo={customerInfo} />)
        }       
               
    } 
    
const onChangeofPayment =(event) =>{
    
    setPaymentSelection(event.target.value)
    if (event.target.value === 'credit'){
        setPaymentModule(<PayByCreditRec paymentInfo={newPaymentInfo} customerInfo={customerInfo}/>)
    } else {
        setPaymentModule(<PaybyCheckRec paymentInfo={newPaymentInfo} customerInfo={customerInfo} />)
    }

}


return(
    <Box sx={{minHeight:'75vh', mt: 2, mb: 1}}>
        <Grid container direction='row' sx={{mb: 1}}>
            <Grid item>
                <Typography>Select Number of Payments and Payment Type:</Typography>   
            </Grid>
        </Grid>
        <Grid container direction='row' sx={{mb: 1}} spacing={1}>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id='number_of_payments'># of Payments</InputLabel>
                        <Select
                            onChange={onPaymentNumberChange}
                            fullWidth
                            label='number_of_payments'
                            id='number_of_payments'
                            defaultValue={'6'}
                        >
                            <MenuItem value='2'>2</MenuItem>
                            <MenuItem value='3'>3</MenuItem>
                            <MenuItem value='4'>4</MenuItem>
                            <MenuItem value='5'>5</MenuItem>
                            <MenuItem value='6'>6</MenuItem>
                            <MenuItem value='7'>7</MenuItem>
                            <MenuItem value='8'>8</MenuItem>
                            <MenuItem value='9'>9</MenuItem>
                            <MenuItem value='10'>10</MenuItem>
                            <MenuItem value='11'>11</MenuItem>
                            <MenuItem value='12'>12</MenuItem>
                            <MenuItem value='13'>13</MenuItem>                

                        </Select>
                </FormControl>  
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id='payment_type'>Type of Payment</InputLabel>
                        <Select
                            onChange={onChangeofPayment}
                            fullWidth
                            label='payment_type'
                            id='payment_type'
                            defaultValue={'credit'}
                            
                        >
                            <MenuItem value='credit'>Debit/Credit Card</MenuItem>
                            <MenuItem value='check'>Check</MenuItem>
                                          

                        </Select>
                </FormControl>  
            </Grid>
        </Grid>
        <Grid container direction='row'>
            <Grid item>
                {paymentModule}
            </Grid>

        </Grid>
         <Grid container direction='column'>
            <Grid item xs={12}>
                <Button color='secondary' variant='outlined' fullWidth onClick={onVerifyClick} endIcon={<AccountBoxIcon />}>Verify Billing Info</Button>
            </Grid>
            {billingForm}
        </Grid>
    
    </Box>
)
}