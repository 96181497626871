import * as React from 'react'
import { Typography, Box, Breadcrumbs, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { AdminConfirmationForm } from '../../../forms/AdminConfirmationForm'


export const AdminConfirmAccounts = () =>{
const data = JSON.parse(window.localStorage.getItem('usercontext'))
console.log('Here is the data on the confirm admin page: ', data)

return(
    <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
    <Breadcrumbs>
        <Link underline='hover' component={RouterLink} to='/admin/dashboard'>Dashboard</Link> 
        <Link underline='hover' component={RouterLink} to='/admin/customercare'>Search </Link> 
    </Breadcrumbs>    
    <Typography align='center' variant='h6'sx={{mb: .5}} > Please Confirm The Following Information with the Customer: </Typography>
    <Typography align='center' variant='h6' sx={{mb: 3}}>Customer Number: {data.customer_number}</Typography>
    <AdminConfirmationForm data={data}/>
    </Box>
)
}