import * as React from 'react'
import * as yup from 'yup'
import {Box, Dialog, IconButton, TextField, DialogTitle,Button, DialogContentText, Grid, useMediaQuery, useTheme, Divider } from '@mui/material'
import { useFormik } from 'formik'
import axios from 'axios'
import { lowercaseregex, UpperCaseRegex, numberRegex, specialCharacterRegex } from '../utility/regex'
import { ErrorAlert } from './ErrorAlert'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import CloseIcon from '@mui/icons-material/Close'


const validationSchema2 =  yup.object().shape({
    
password: yup.string().matches(UpperCaseRegex, "Please include 1 UPPER CASE character")
              .matches(lowercaseregex, "Please include a lower case characters")
              .matches(numberRegex, 'Pl3as3 1nclude a Numb3r 0-9')
              .matches(specialCharacterRegex, 'Ple@$e include a $pec!@l Ch@r@cter (#,%&^)') 
              .min(8, 'Minimum 8 characters required')
              .required("Please enter a Password"),
confirmPassword: yup.string().oneOf([yup.ref('password')],"Please check to make sure this password matches your previously entered password.").required("Please confirm your previous password here.")

})


export const ChangePassword = ({open, email, close, successAlert}) =>{
    
    const theme = useTheme()
     const fullscreen = useMediaQuery(theme.breakpoints.down('md'))
    



    
    

    const formik2 = useFormik({
        
        initialValues:{
            password: '',
            confirmPassword: ''
        },
        validationSchema: validationSchema2,
        onSubmit: (values, actions) =>{
            

            axios({
                method: 'POST',
                url:'api/oracle2/changepassword',
                data: {email: email,
                    password: values.password}
            }).then( result=>{
                if (result.status===200){
                    
                    close()
                    successAlert()
                    formik2.isSubmitting(false)
                }else{console.log('There was an error')}
                
                        
                    
                    
                
    
                
    
            })
            .catch(errors=>{
                actions.setSubmitting(false)
                let err = ('Here are the errors when confirming the accounts: ',errors)
                return ErrorAlert(err)
            })
    
        }
    
       })

    
       return(
        <Box component='form' onSubmit={formik2.handleSubmit}>
        <Dialog open={open} aria-labelledby='form-dialog-title' fullScreen={fullscreen} onClose={close} >
        <DialogTitle id='form-dialog-title' sx={{backgroundColor: 'secondary.main', color: 'white'}}>
            Enter New Password
        </DialogTitle>
        <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        <Divider />
        <DialogContentText  color='black' sx={{mt:'1rem', ml:'1rem'}}>
           Enter Password Twice - Please remember your password.   
        </DialogContentText>
        <Divider sx={{mt: '1rem'}}/>
        <Grid container direction='column' sx={{mt: '2rem', ml: '1rem'}}>
        
        <Grid item xs={12} sm={12} md={8} lg={6}> 
        <TextField
       
        sx={{mb: '2rem'}}
        autoFocus
        margin='none'
        type='password' 
        id ='password'
        name='password'
        label= 'Password'
        value={formik2.values.password}
        onChange={formik2.handleChange}
        error={formik2.touched.password && Boolean(formik2.touched.password)}
        helperText={formik2.errors.password}
        />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
        <TextField
       
        sx={{mb: '2rem'}}
        margin='none'
        // variant='Standard'
        type='password' 
        id ='confirmPassword'
        name='confirmPassword'
        label= 'Confirm Password'
        value={formik2.values.confirmPassword}
        onChange={formik2.handleChange}
        error={formik2.touched.confirmPassword && Boolean(formik2.errors.confirmPassword)}
        helperText={formik2.errors.confirmPassword}
        />
        </Grid>
        <Grid item>
    <Button 
            onClick={formik2.handleSubmit}
            endIcon={<VpnKeyIcon />}
            type ='submit'
            sx={{mb: '4rem', ml: '6rem', mr: '6rem'}} 
            variant='contained' 
            color='secondary'
            disabled ={formik2.isSubmitting||!formik2.isValid||!formik2.dirty}>
                Submit</Button>
                </Grid>
                </Grid>
                
    </Dialog>
    </Box>
        

       

)}