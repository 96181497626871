import * as React from 'react'
import { Box, Grid, TextField, FormGroup, FormControlLabel, Checkbox,
         FormControl, Select, MenuItem, ListSubheader, Button, InputLabel, 
         Typography} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useNavigate} from 'react-router-dom'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import PublishIcon from '@mui/icons-material/Publish'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import ContactPageIcon from '@mui/icons-material/ContactPage'
import { ErrorAlert } from '../alerts/ErrorAlert'
import { axiosErrorHandling } from '../misc/other'
import { useFormik } from 'formik'
import { AdminUserCreationSuccess } from '../alerts/AdminUserCreation'
import { AdminChangedPassword } from '../alerts/AdminPasswordChanged'
import * as yup from 'yup'
import axios from 'axios'
import PasswordIcon from '@mui/icons-material/Password';

const validationSchema = yup.object().shape({
    first_name: yup.string().min(2, "Please enter a valid first name"),
    last_name: yup.string().min(2, "Please enter a valid last name"),
    street_address_1: yup.string().min(5, 'Please enter a valid address'),
    street_address_2: yup.string().nullable().min(5, 'Please enter a valid address'),
    city: yup.string().min(3, 'Please enter a valid city name'),
    zip: yup.string().min(5,'Please enter 5 numbers').max(5, 'Please enter only 5 numbers'),
    zip4: yup.string().min(4, 'Please enter 4 numbers').max(4, "Please enter only 4 numbers"),
    email: yup.string().email("Email needs to include an @ and a . for registering")
    
    })

export const AdminConfirmationForm = ({data})=>{


    const [phoneNumber, setPhoneNumber] = React.useState(data.phone_number.toString())
    const [phoneToStore, setPhoneToStore] = React.useState(data.phone_number)
    const [phoneErrorMessage, setPhoneErrorMessage] = React.useState('')
    const [phoneError, setPhoneError] = React.useState(false)
    const [mailChecked, setMailChecked] = React.useState(false)
    const [emailChecked, setEmailChecked] = React.useState(true)
    const [mailCheckValue, setMailCheckValue] = React.useState(0)
    const [emailCheckValue, setEmailCheckValue] = React.useState(1)
    const [accountSetup, setAccountSetup] = React.useState(false)
    const [isUpdating, setIsUpdating] = React.useState(false)
    const [errorAlert, setErrorAlert] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [customerLoading, setCustomerLoading] = React.useState(false)
    const [userSetupSuccess, setUserSetupSuccess] = React.useState(false)
    const [passwordChanged, setPasswordChanged] = React.useState(false)
    const [addressValidated, setAddressValidated] = React.useState(0)
    const [goToAccounts, setGoToAccounts] = React.useState('/admin/customercare/accounts/' + data.email)

    


const navigate = useNavigate()

    React.useLayoutEffect(()=>{
        
        if(data.account_setup===0){
            setAccountSetup(false)
        } else {setAccountSetup(true)}


    },[data.account_setup])

    const errorClose =()=>{
        setErrorAlert(false)

    }

    const gotoAccounts = () =>{
        
        navigate(goToAccounts)
    }
    
   const closePasswordChanged = () =>{
    setPasswordChanged(false)
   }


    const handleMailChange = (event) =>{
        setMailChecked(event.target.checked)
        if (event.target.checked){
            setMailCheckValue(1)
        } else {
            setMailCheckValue(0)
        }
    }

    const handleEmailChange = (event) =>{
        setEmailChecked(event.target.checked)
        if (event.target.checked){
            setEmailCheckValue(1)
        } else {
            setEmailCheckValue(0)
        }
    }

    
    const closeUserCreationAlert =()=>{
        setUserSetupSuccess(false)
    }



    const handlePhoneChange = (newValue, info)=>{
        setPhoneNumber(newValue)
        if(matchIsValidTel(info.numberValue)){
            setPhoneToStore(info.nationalNumber)
            setPhoneError(false)
            setPhoneErrorMessage('')
        }else{
            setPhoneError(true)
            setPhoneErrorMessage('Please enter a valid phone number')
        }
    }

    let initialzip = data.zip.replace(/\s/g, "")
    let initalzip4= data.zip4.replace(/\s/g, "")

    const formik = useFormik({
        initialValues:{
            first_name: data.first_name,
            last_name: data.last_name,
            street_address_1: data.street_address_1,
            street_address_2: data.street_address_2,
            city: data.city,
            state: data.state,
            zip: initialzip,
            zip4: initalzip4,
            email: data.email
        },
        validationSchema: validationSchema,
        onSubmit:(values, actions) =>{
            
            setIsUpdating(true)
            actions.setSubmitting(true)
            axios({
                method: 'POST',
                url: 'api/oracle1/uspsapi',
                data: {street1: values.street_address_1,
                       street2: values.street_address_2,
                       city: values.city,
                       state: values.state,
                       zip: values.zip
                }
            }).then(result =>{
                if(result.status===200){
                    values.street_address_1 = result.data.street1
                    values.street_address_2 = result.data.street2
                    values.city = result.data.city
                    values.state = result.data.state
                    values.zip = result.data.zip
                    values.zip4 = result.data.zip4
                    setAddressValidated(1)
                } 
            }).then(()=>{
                data.first_name = values.first_name
                data.email = values.email
                data.last_name = values.last_name
                data.street_address_1 = values.street_address_1
                data.street_address_2 = values.street_address_2
                data.phone_number = phoneToStore
                data.city = values.city
                data.zip = values.zip
                data.zip4 = values.zip4
                window.localStorage.setItem('usercontext', JSON.stringify(data))


            axios({
                    method:'POST',
                    url: '/api/oracle5/updateprofile',
                    data: {values, 
                           contact_via_mail: mailCheckValue, 
                           contact_via_email: emailCheckValue, 
                           customer_number: data.customer_number, 
                           old_email: data.email,
                           phone: phoneToStore,
                           address_validated: addressValidated}
                
            }).then(result=>{
                let path = '/admin/customercare/accounts/' + values.email
                setGoToAccounts(path)
                setIsUpdating(false)
                actions.setSubmitting(false)
                

            }).catch(err=>{ 
                const error_message = axiosErrorHandling(err)
                setIsUpdating(false)
                setErrorMessage(error_message)
                setErrorAlert(true)


            })

        })
        }
        
    })

    const changePassword = () => {
        axios({method: 'POST',
               url: 'api/oracle2/passwordreset',
               data: {email: formik.values.email}
        }).then(result=>{
            
            let path = '/admin/customercare/accounts/' + formik.values.email
            setGoToAccounts(path)
            if(result.data==='Password Updated'){
                setPasswordChanged(true)
            }else{
                setErrorMessage(result.message)
                setErrorAlert(true)
            }

        })
    }

    const userCreate=() =>{
        setCustomerLoading(true)
       
        axios({ method:'POST',
                url:'api/oracle2/adminusercreate',
                data:{email: formik.values.email,
                      customer_number: data.customer_number,
                      first_name: formik.values.first_name,
                      last_name: formik.values.last_name,
                      street_address_1: formik.values.street_address_1,
                      street_address_2: formik.values.street_address_2,
                      city: formik.values.city,
                      state: formik.values.state,
                      zip: formik.values.zip,
                      zip4: formik.values.zip4,
                      phone_number: phoneToStore
                      } }).then(results=>{
                        if (results.status===200){
                            let newContext ={
                                customer_number: data.customer_number,
                                first_name: formik.values.first_name,
                                last_name: formik.values.last_name,
                                email: formik.values.email,
                                phone: phoneToStore,
                                street_address_1: formik.values.street_address_1,
                                street_address_2: formik.values.street_address_2,
                                city: formik.values.city,
                                state: formik.values.state,
                                zip: formik.values.zip,
                                zip4: formik.values.zip4,
                                account_setup: 1
                                                    }
                        window.localStorage.setItem('usercontext', JSON.stringify(newContext))
                        data.account_setup=1
                        let path = '/admin/customercare/accounts/' + formik.values.email
                        setGoToAccounts(path)
                        setAccountSetup(true)
                        setUserSetupSuccess(true)
                        }

                        setCustomerLoading(false)
                        
                      }).catch(err=>{
                        
                        let error_message = axiosErrorHandling(err)
                        setErrorMessage(error_message)
                        setErrorAlert(true)                        
                        setCustomerLoading(false)})
    }

   return (
    <Box component='form' onSubmit={formik.handleSubmit}>
    <AdminUserCreationSuccess open={userSetupSuccess} handleClose={closeUserCreationAlert} />
      <ErrorAlert open={errorAlert}  error={errorMessage} handleClose={errorClose}/>  
    <AdminChangedPassword open={passwordChanged} handleClose={closePasswordChanged}/>
    <Grid container spacing={2} direction='row' justifyContent='center' sx={{mb: '2rem'}}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
            <TextField
            fullWidth
            type='text'
            margin='none'
            variant='outlined'
            id='first_name'
            name='first_name'
            label='First Name'
            value={formik.values.first_name}
            onChange={formik.handleChange}
            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
            helperText={formik.errors.first_name} 
            />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
            <TextField
            fullWidth
            type='text'
            margin='none'
            variant='outlined'
            id='last_name'
            name='last_name'
            label='Last Name'
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.errors.last_name}
            />
        </Grid>
    </Grid>
    <Grid container spacing={2} direction='row' justifyContent='center' sx={{mb: '2rem'}}>
            <Grid item  xs={12} sm={12} md ={6} lg={6}>   
                    <TextField
                    type='text'
                    margin='none'
                    fullWidth
                    variant='outlined'
                    id ='street_address_1'
                    name='street_address_1'
                    label= 'Street Address Line 1'
                    value={formik.values.street_address_1}
                    onChange={formik.handleChange}
                    error={formik.touched.street_address_1 && Boolean(formik.errors.street_address_1)}
                    helperText={formik.errors.street_address_1}
                    />
            </Grid>
    </Grid>
    <Grid container spacing={2} direction='row' justifyContent='center' sx={{mb: '2rem'}}>
            <Grid item  xs={12} sm={12} md ={6} lg={6}>   
                    <TextField
                    type='text'
                    margin='none'
                    fullWidth
                    variant='outlined'
                    id ='street_address_2'
                    name='street_address_2'
                    label= 'Street Address Line 1'
                    value={formik.values.street_address_2}
                    onChange={formik.handleChange}
                    error={formik.touched.street_address_2 && Boolean(formik.errors.street_address_2)}
                    helperText={formik.errors.street_address_2}
                    />
            </Grid>
    </Grid>
    <Grid container direction='row' justifyContent='center' spacing={2} sx={{mb: '2rem'}}>
            <Grid item xs={8} sm={12} md={2} lg={2}>      
                    <TextField
                    fullWidth
                    margin='none'
                    variant='outlined' 
                    id ='city'
                    name='city'
                    label= 'City'
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.errors.city}
                    />
            </Grid>
            <Grid item xs={4} sm={3} md={1} lg={1}>    
                    <FormControl variant='outlined' fullWidth>
                        <InputLabel
                        id='state-label'
                        >State</InputLabel>
                            <Select
                            label='state-label'
                            id='state'
                            defaultValue={formik.values.state}
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            >
                                <ListSubheader>States</ListSubheader>        
                                    <MenuItem value="AL">AL</MenuItem>
                                    <MenuItem value="AK">AK</MenuItem>
                                    <MenuItem value="AR">AR</MenuItem>	
                                    <MenuItem value="AZ">AZ</MenuItem>
                                    <MenuItem value="CA">CA</MenuItem>
                                    <MenuItem value="CO">CO</MenuItem>
                                    <MenuItem value="CT">CT</MenuItem>
                                    <MenuItem value="DC">DC</MenuItem>
                                    <MenuItem value="DE">DE</MenuItem>
                                    <MenuItem value="FL">FL</MenuItem>
                                    <MenuItem value="GA">GA</MenuItem>
                                    <MenuItem value="HI">HI</MenuItem>
                                    <MenuItem value="IA">IA</MenuItem>	
                                    <MenuItem value="ID">ID</MenuItem>
                                    <MenuItem value="IL">IL</MenuItem>
                                    <MenuItem value="IN">IN</MenuItem>
                                    <MenuItem value="KS">KS</MenuItem>
                                    <MenuItem value="KY">KY</MenuItem>
                                    <MenuItem value="LA">LA</MenuItem>
                                    <MenuItem value="MA">MA</MenuItem>
                                    <MenuItem value="MD">MD</MenuItem>
                                    <MenuItem value="ME">ME</MenuItem>
                                    <MenuItem value="MI">MI</MenuItem>
                                    <MenuItem value="MN">MN</MenuItem>
                                    <MenuItem value="MO">MO</MenuItem>	
                                    <MenuItem value="MS">MS</MenuItem>
                                    <MenuItem value="MT">MT</MenuItem>
                                    <MenuItem value="NC">NC</MenuItem>	
                                    <MenuItem value="NE">NE</MenuItem>
                                    <MenuItem value="NH">NH</MenuItem>
                                    <MenuItem value="NJ">NJ</MenuItem>
                                    <MenuItem value="NM">NM</MenuItem>			
                                    <MenuItem value="NV">NV</MenuItem>
                                    <MenuItem value="NY">NY</MenuItem>
                                    <MenuItem value="ND">ND</MenuItem>
                                    <MenuItem value="OH">OH</MenuItem>
                                    <MenuItem value="OK">OK</MenuItem>
                                    <MenuItem value="OR">OR</MenuItem>
                                    <MenuItem value="PA">PA</MenuItem>
                                    <MenuItem value="RI">RI</MenuItem>
                                    <MenuItem value="SC">SC</MenuItem>
                                    <MenuItem value="SD">SD</MenuItem>
                                    <MenuItem value="TN">TN</MenuItem>
                                    <MenuItem value="TX">TX</MenuItem>
                                    <MenuItem value="UT">UT</MenuItem>
                                    <MenuItem value="VT">VT</MenuItem>
                                    <MenuItem value="VA">VA</MenuItem>
                                    <MenuItem value="WA">WA</MenuItem>
                                    <MenuItem value="WI">WI</MenuItem>	
                                    <MenuItem value="WV">WV</MenuItem>
                                    <MenuItem value="WY">WY</MenuItem>
                                <ListSubheader>Outlying Territories</ListSubheader>  
                                    <MenuItem value="AS">AS</MenuItem>
                                    <MenuItem value="GU">GU</MenuItem>
                                    <MenuItem value="MP">MP</MenuItem>
                                    <MenuItem value="PR">PR</MenuItem>
                                    <MenuItem value="UM">UM</MenuItem>
                                    <MenuItem value="VI">VI</MenuItem>
                                <ListSubheader>Armed Forces</ListSubheader> 
                                    <MenuItem value="APO-AA">AA</MenuItem>
                                    <MenuItem value="APO-AP">AP</MenuItem>
                                    <MenuItem value="APO-AE">AE</MenuItem>
                                    <MenuItem value="FPO-AA">AA</MenuItem>
                                    <MenuItem value="FPO-AP">AP</MenuItem>
                                    <MenuItem value="FPO-AE">AE</MenuItem>		   
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={8} sm ={6} md={2} lg={2}>
                        <TextField
                        fullWidth
                        margin='none'
                        variant='outlined'
                        type='phone' 
                        id ='zip'
                        name='zip'
                        label= 'Zip'
                        value={formik.values.zip}
                        onChange={formik.handleChange}
                        error={formik.touched.zip && Boolean(formik.errors.zip)}
                        helperText={formik.errors.zip}
                    />
                    </Grid>
                    <Grid item xs={4} sm ={3} md={1} lg={1}> 
                        <TextField
                        margin='none'
                        variant='outlined'
                        type='phone' 
                        id ='zip4'
                        name='zip4'
                        label= '+4'
                        value={formik.values.zip4}
                        onChange={formik.handleChange}
                        error={formik.touched.zip4 && Boolean(formik.errors.zip4)}
                        helperText={formik.errors.zip4}
                        />
                    </Grid> 
                </Grid>
                <Grid container direction='row' justifyContent='center' sx={{mb: '2rem'}} spacing={2}>
                    <Grid item xs={12} sm={12} md ={6} lg={6}>
                        <MuiTelInput
                         fullWidth                    
                        variant='outlined'
                        defaultCountry='US'
                        forceCallingCode={true}
                        disableDropdown={true}
                        onlyCountries={['US']}
                        value={phoneNumber}
                        label='Phone Number'
                        id='phone'
                        name='phone'
                        type='phone'
                        onChange={handlePhoneChange}
                        error={phoneError}
                        helperText={phoneErrorMessage}/>
                        </Grid>
                    </Grid>
                <Grid container direction='row' justifyContent='center' sx={{mb: '2rem'}} spacing={2}>
                    <Grid item xs={12} sm={12} md ={6} lg={6}>
                        <TextField
                        fullWidth
                        margin='none'
                        variant='outlined'
                        type='text' 
                        id ='email'
                        name='email'
                        label= 'Email'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.errors.email}
                        />
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent='center' sx={{mb: '2rem'}} spacing={2}>
                    <Grid item>
                        <Typography variant='h6' color='secondary'>Ask Customer How they Would like to be Contacted: </Typography>
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent='center' sx={{mb: '2rem'}} spacing={2} >
                    <Grid item xs={12} md={2}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={emailChecked} onChange={handleEmailChange}/>} label='Contact by Email'/>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={mailChecked} onChange={handleMailChange}/>} label='Contact by Mail'/>
                        </FormGroup>   
                    </Grid>
                </Grid>    
                <Grid container direction='row-reverse' justifyContent='center'>
                    
                    <Grid item>
                        <LoadingButton
                        loading={customerLoading}
                        loadingPosition='end'
                        sx={{ mt: 2}}
                        variant='contained' 
                        disabled={accountSetup}
                        onClick={userCreate}
                        endIcon={<ContactPageIcon />}>
                            Create User
                        </LoadingButton>
                    </Grid>
                    <Grid item > 
                        <LoadingButton

                        sx={{mt: 2, mr:2}}
                        variant='contained' 
                        endIcon={<PublishIcon />}
                        disabled={phoneError||!accountSetup}
                        loading={isUpdating}
                        loadingPosition='end'
                        type='submit'
                        onClick={formik.handleSubmit}                
                        >Update Information</LoadingButton>
                    </Grid>
                        
                    <Grid item>
                        <Button 
                        sx={{mt:2, mr:2}}
                        color='secondary'
                        variant='contained'
                        disabled={!accountSetup}
                        endIcon={<SaveAltIcon />}
                        onClick={gotoAccounts}
                        >Get Accounts</Button>
                    </Grid>
                    <Grid>
                        <LoadingButton
                        color='secondary'
                        variant='contained'
                        sx={{mt: 2, mr: 2}}
                        endIcon={<PasswordIcon />}
                        onClick={changePassword}
                        >
                            Force Password Reset
                        </LoadingButton>
                    </Grid>

                </Grid>

    </Box>
   ) 
}