import * as React from 'react'
import {Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Container, Box} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';


export const FAQ = () =>{


return(
    <React.Fragment>
    <Container>
     <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>  
    <Grid container direction='row' justifyContent='center'>
        <Grid item xs={10} sm={10} md={10}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<AddIcon />}
                    id='FAQ1'>
                  <Typography>What is NameMySettlement.com?</Typography>      
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        NameMySettlement.com is a website that allows you to settle your unsecured debt at a discounted price.  Allowing you to start rebuilding your credit and stop the annoying phone calls from Debt Collectors.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<AddIcon />}
                    id='FAQ2'>
                  <Typography>How do I use it?</Typography>      
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        The Process is very simple.  The first step is to find the email or postcard that brought you to this website. In either the postcard or email you will find your customer number.   
                        Enter this information along with your last name used on the original account on the home page. You will then be prompted to verify your address, email, and phone number.
                        Once you have verified your information you will then set up a password. Once you submit your password, you will be logged in. (Log out any time). Once you have selected one or multiple accounts to settle you will be directed to confirm the accounts are eligible for settlement. The next step will
                        be the actual determination of the price for settlement.  You will name a price and either the program will accept the price or offer another price.   Once either you confirm the new offer or the offer is accepted, an offer letter will then be generated.
                        You can then either pay via a money order or you can pay online via check(See FAQ on E-CHeck why we don't accept debit or credit cards yet).  To pay via mail order please follow the instructions in the generated letter(you can only make one time payments via money order).  To pay via the website just enter your banks 
                        routing number and your account number.  Choose whether you would like to make a single payment or reccuring payment (maximum of 12 monthly payments with the first payments due the same day, with each subsequent payment due 
                        the same day on each month).  Once completed you will get a settlement letter that you can show to any debt collector that shows the debt has been settled.      
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<AddIcon />}
                    id='FAQ3'>
                  <Typography>Why only e-check as an electronic form of payment?</Typography>      
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Debt collection agencies have a well documented history of 'screwing' people out of their money. Because of this there are few payment processors that will take on debt collectors due to the large
                        volumes of refunds issued and complaints that consumers report to the payment processors.   Since we are a startup we have to start somewhere and this is ground zero.  The good news is our payment 
                        partner will allow us to move towards debit and credit card acceptance once we have established a history of low charge backs and low customer complaints we will be able to allow for other forms of payments.
                        Who knows maybe Paypal will let us have an account.  One more point, your payment information is only sent from your browser to the payment processor, we do not store payment information.        
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<AddIcon />}
                    id='FAQ4'>
                  <Typography>What if the person who owns the debt is deceased?</Typography>      
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        We are so sorry for you loss.  We can absolutely close this debt out. During the 2nd phase of the process when you can confirm accounts you can mark the following selection: 'The person, who owes these debts, is deceased.'  Once completed you can upload the Documents
                        showing that the account holder is indeed deceased.  Documents that will verify that the account holder is deceased are a death certificate, obituary in a newspaper, or letter from a physician or the apropriate coroner's office. After the document is
                        confirmed we will close the account. 
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<AddIcon />}
                    id='FAQ5'>
                  <Typography>What if the account was protected/discharged under a bankruptcy?</Typography>      
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                         Select the account in the User Account Page after logging in that is protected under a bankruptcy. On the next page select: 'These accounts are protected under a Bankruptcy filing.'
                         Your next step will be to upload a supporting document. Please upload a letter from the judge, the official filing or any other order that shows that the account selected is protected 
                         and once we verify the information your account will be cleared.
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<AddIcon />}
                    id='FAQ6'>
                  <Typography>What if this debt is not mine?</Typography>      
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        After logging in and selecting the account in question, please select: 'These debts are not owed by me.' The next step is to upload a supporting document, which could be be a police report, 
                        correct Social Security Card or ID, or any other document showing that you did not open this account or that your name or SSN was used without your permission. 
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<AddIcon />}
                    id='FAQ7'>
                  <Typography>What if I have already settled this debt with another company?</Typography>      
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        After logging in and selecting the account in question, please select: 'These debts are already settled.' The next step is to upload a supporting document, which can be a statement or settlement letter from the previous  
                         dept collector or any other document showing that you have already paid this debt.  
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<AddIcon />}
                    id='FAQ8'>
                  <Typography>What if I have received a 1099-C for an account on your website?</Typography>      
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        After logging in and selecting the account in question, please select: 'I have already received a 1099-C for these accounts.' Then please upload the 1099-C that you have received and a redacted
                        copy of your tax return(please remove SSN, all other Income Sources, leave your name and address, but make sure to leave the other Income Line Item open) in which you claimed the 1099-C as income on your tax return.  If you have not 
                        claimed it as Income, there is a chance you will not have to file if you settle for less than $600.00 the original value.   
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<AddIcon />}
                    id='FAQ9'>
                  <Typography>Do you send 1099-C's for settled debt?</Typography>      
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        We are required by Federal Law to disclose all settled debt that was settled equalt to or greater than $600 than the original debt. 
                        Example if you owe $3,000 and you settle the debt for $2,400.00 and pay $2,400.00 you will receive a 1099-C from us. However if you settle  
                        settle the debt for $2,400.01 then you will not receive a 1099-C however you will have to report the $599.99 as other income on your 1040.         
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<AddIcon />}
                    id='FAQ10'>
                  <Typography>How did NameMySettlement.com gain my account and information?</Typography>      
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        NameMySettlement and its parent company are licensed debt collectors/debt buyers in your state.  We purchased the debt through a debt wholesaler who
                        probably bought the dept from the original company or a mediator.   The information would be the last known account information.           
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<AddIcon />}
                    id='FAQ11'>
                  <Typography>I mis-clicked my dept type on the confirmation page, now what?</Typography>      
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        If you accidentally confirmed your account and the account has a special circumstance, (death, bankruptcy, 1099-C, already paid, etc), please click the feedback button and 
                        use one of the text boxes to explain the special circumstance and we will be happy to change it. If you selected one of the other circumstances and you were mistaken, you can always select the
                        account and hit the settle button and settle the account normally.             
                    </Typography>
                </AccordionDetails>
                </Accordion>
        </Grid>
    </Grid>
    </Box> 
    
    </Container>
    
    </React.Fragment>
)

}