import * as React from 'react'
import { FormControl, InputLabel, 
          NativeSelect, MenuItem, Menu,
          Typography, Button, Select, 
          Grid, Box} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {useDropzone} from 'react-dropzone'
import FileSaver from 'file-saver'
import axios from 'axios'
import {DataGridPro} from '@mui/x-data-grid-pro'
import { ErrorAlert } from '../alerts/ErrorAlert'
import UploadIcon from '@mui/icons-material/Upload'
import DownloadIcon from '@mui/icons-material/Download'
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone'
import SendIcon from '@mui/icons-material/Send'
import { AdminDocumentResponse } from '../alerts/DocumentResponse'
import { PDFDate } from '../misc/dates'
import { TenNinetyNinePDF, getProps } from '../letters/TenNinetyNine'
import { AlreadySettledPDF, getAlreadySettledProps } from '../letters/AlreadySettled'
import { BankruptcyPDF, getBankruptcyProps } from '../letters/Banckruptcy'
import { DeceasedPDF, getDeceasedProps } from '../letters/Deceased'
import { WrongIdentityPDF, getWrongIdentityProps } from '../letters/WrongIdentity'
import { pdf } from '@react-pdf/renderer'
import { DocumentConfirmed } from '../alerts/DocumentConfirmed'
import { axiosErrorHandling } from '../misc/other'



const baseStyle = 
{
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#C31200',
    borderStyle: 'dashed',
    backgroundColor: '#F5F8FF',
    color: '#FE5200',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    
  }

  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const UploadDropzone = (props) =>{
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles
      } = useDropzone({accept: {'application/pdf': ['.pdf'], 
      'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'],
        }, maxFiles: 1, 
      onDrop:acceptedFiles=>{ props.fileToState(acceptedFiles)} });

const files = acceptedFiles.map(file => (
    <li key={file.path}>{file.path} - {file.size} kBs </li>
))

const style = React.useMemo(()=>({
    ...baseStyle,
    ...(isDragActive ? activeStyle:{}),
    ...(isDragAccept ? acceptStyle:{}),
    ...(isDragReject ? rejectStyle:{})

}),[
    isDragActive, 
    isDragReject,
    isDragAccept
])

return(
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>{props.instructions}</p>
      </div>
      <aside>
          <h4>File that you are attempting to upload</h4>
            <ul>{files}</ul>
      </aside>
    </div>
  );
}

var defaultdata = [
    {id: 1, original_creditor: 'Chase', original_account_number: '4564489746465', account_status: 'Already Settled'  },
    {id: 2, original_creditor: 'Chase', original_account_number: '4563468764321', account_status: 'Deceased'},
    {id: 3, original_creditor: 'Bank of America', original_account_number: 'asdfa64897964ad', account_status: '1099-C'},
    {id: 4, original_creditor: 'Poly Clinic', original_account_number: 'JEOHDJLS29', account_status: 'Wrong Identity'}

]

const renderOptions=(data)=>{
    return data.map((dt, i)=>{
        return(
            <option
            key={i}
            value={dt.original_creditor+'_'+dt.original_account_number +'_'+dt.account_status}>
               {dt.original_creditor+'_'+dt.original_account_number + '_'+dt.account_status} 
            </option>
        )
    })
}

export const UploadDocuments = ({download}) =>{

    const [data, setData] = React.useState(defaultdata)
    const [selectValue, setSelectValue] = React.useState('None')
    const [accountArray, setAccountArray] = React.useState(null)
    const [accountStatus, setAccountStatus] = React.useState(defaultdata[0].account_status)
    const [accountInstructions, setAccountInstructions] = React.useState('After selecting an account you can upload a document either by clicking, or dragging a pdf into, this box.')
    const [errorAlert, setErrorAlert] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [file, setFile] = React.useState(null)
    const [disableButton, setDisableButton] = React.useState(true)
    const [fileReady, setFileReady] = React.useState(true)
    const [isLoading, setIsLoading] =React.useState(true)  
    const context = JSON.parse(window.localStorage.getItem('context'))


    const closeErrorAlert = () =>{
        setErrorAlert(false)
    }

    React.useEffect(()=>{
        let email = context.email
        axios({
               method:"POST",
               url: 'api/oracle4/getdocument',
               data: {email: email}
            })
            .then(res=>{
                
                if(res.data === 'No Results'){
                    setIsLoading(false)
                    download()
            }else{
                setIsLoading(false)
                setData(res.data)
                
            }
            
            
            })
    },[context.email])
    
   const fileToBlob = async (file) => new Blob ([new Uint8Array(await file.arrayBuffer())], {type: file.type})
    
    
    
    React.useLayoutEffect(()=>{
        const accountMessages = {already_settled: 'Please upload a letter or invoice from the previous debt collector',
                            deceased: "Please upload a death certificate or letter from the local coroner's office indicating the person is deceased.",
                            ten99C: 'Please upload the 1099-C provided by the original creditor',
                            wrongIdentity: 'Please upload a valid SSN, police report, or letter from your bank presenting that this account was opened fraudently',
                            bankruptcy: 'Please upload your court order or signed letter from a judge that indicates this account is protected from creditors from a Chapter 7 or 13 bankruptcy'}
    
        messageToDisplay(accountStatus, accountMessages)
    
    },[accountStatus])
    
    
    const selectChange = (event) =>{
        
        let workingArray = event.target.value.split('_')
        
        setAccountStatus(workingArray[2])
        setAccountArray(workingArray)
        setSelectValue(event.target.value)
        setDisableButton(false)
        
        
    }
    
    const fileToState = (file) =>{
        setFile(file)
        setFileReady(false)
    }
    
    const onSubmit = async () =>
    {
    
        
          
    
     var customer_number = data[0].customer_number.toString()    
     var fileName = customer_number +'_'+ selectValue + '_' + file[0].name
     fileName = fileName.replace(/([^a-z0-9.]+)/gi, '_')
     let objectData = await fileToBlob(file[0])

    
    
     axios({
        method: 'POST',
        url: 'api/oracle4/upload',
        headers: {'Content-Type': 'application/octet-stream'},
        params:{fileName: fileName,
                customer_number: customer_number,
                autogenerated: 0,
                email: context.email},
        data: objectData

     })
     .then(result=>{

        if (result.status===200){
            axios({
                method: "POST",
                url: 'api/oracle3/putdocuments',
                data: {customer_number: customer_number,
                       file_name: fileName,
                       original_creditor: accountArray[0],
                       original_account_number: accountArray[1]            
                       }
           
            }).then(res=>{
                   if(res.status === 200){
                       download()
                   }
                   else{
                    let errMessage = 'Error occured in the downloading of documents.'
                    setErrorMessage(errMessage)
                    setErrorAlert(true)
                   }
           
                
            })

        }


     })
     
     
    
     
    
    
    
    }
    
    
    const messageToDisplay = (account_status, messages)=>{
        switch(account_status){
            case 'Already Settled' :
                setAccountInstructions(messages.already_settled)
                break
            case 'Bankruptcy' :
                setAccountInstructions(messages.bankruptcy)
                break
            case 'Deceased' :
                setAccountInstructions(messages.deceased)
                break
            case 'Wrong Identity' :
                setAccountInstructions(messages.wrongIdentity)
                break
            case '1099-C' :
                setAccountInstructions(messages.ten99C)
                break
                default:
                    setAccountInstructions('After selecting an account you can upload a document either by clicking, or dragging a pdf into, this box.')
        }
    }
    
    if(isLoading){return(
    
        <Grid item>
            <Typography variant='h6' color='primary'>Loading Info....</Typography>
        </Grid>
    )}
    
        return(
            <React.Fragment>
                <ErrorAlert open={errorAlert} error={errorMessage} handleClose={closeErrorAlert}/>
            <Grid item>
            <Typography>Select Account You Are Uploading the Document</Typography>
            </Grid>
            <Grid item>
            <FormControl sx={{margin: 1, minWidth: 120}}>
                <InputLabel htmlFor='select-account-that-needs-supportive-documents'>Accounts</InputLabel>
                <NativeSelect
                value={selectValue}
                onChange={selectChange}>
                    <option value='None'>Select an Account</option>
                    {renderOptions(data)}
                </NativeSelect>
            </FormControl>
           </Grid>
           <Grid item xs={8} sm={6} md={4} lg={4} xl={4} m>
           <UploadDropzone instructions ={accountInstructions} fileToState={fileToState}/>
            </Grid>
            <Grid item>
            <Button variant='contained' 
                    color='primary' 
                    onClick={onSubmit} 
                    disabled={disableButton || fileReady}
                    endIcon={<UploadIcon />}>Submit Document</Button>
            </Grid>
            </React.Fragment>
        )

}


export const DownloadDocuments = ()=>{
    
    const [customerNumber, setCustomerNumber] =React.useState(0)
    const [downloadData, setDownloadData] = React.useState([])
    const [downloading, setDownloading] = React.useState(false)
    const [noDocs, setNoDocs] = React.useState(false)
    const [docToPull, setDocToPull] = React.useState([])
    const [downloadButtonDisabled, setDownloadButtonDisabled] = React.useState(true)
    const [errorAlert, setErrorAlert] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')


    

    const context = JSON.parse(window.localStorage.getItem('context'))
    
   
    const columns = [
        {field: 'file_name', headerName: 'File Name', flex: 4},
        {field: 'original_creditor', headerName: 'Creditor', flex: 4},
        {field: 'original_account_number', headerName: 'Account Number', flex: 4}
    ]
    
 
    const closeErrorAlert = () =>{
        setErrorAlert(false)
    }

    React.useLayoutEffect(()=>{
        axios({
             method: 'POST',
             url: 'api/oracle3/getaccounts',
             data:{email: context.email}
            })
            .then(res=>{
                setCustomerNumber(res.data[0].customer_number)
             axios({
                 method: 'POST',
                 url: 'api/oracle3/documents',
                 data: {customer_number: res.data[0].customer_number}
             }).then(res=>{
                    if(res.data === 'No Documents'){
                        setNoDocs(true)
                    }

                 setDownloadData(res.data)
                 
             }).catch((errors)=>{
                let errorMessage = ('Here are the errors in the documents call: ', errors)
                setErrorMessage(errorMessage)
                setErrorAlert(true)
            })
            }).catch((errors)=>{
                let errorsMessage = ('Here are the errors in the getaccounts call: ', errors)
                setErrorMessage(errorsMessage)
                setErrorAlert(true)
            })
            
               
     },[context.email])

    const rowClick =(row)=>{
        
        if(!row.length){setDownloadButtonDisabled(true)}
        else{
            
            let rowSelected  = []
            let l = 0

            while (l < (downloadData.length)){
                if (row.includes(downloadData[l].id)){
                    rowSelected.push(downloadData[l])
                    setDownloadButtonDisabled(false)
                    
                    setDocToPull(rowSelected)
                }
                
               l++
            }
            
           
        }

    }

    const DownloadClick = async (selection)=>{
        
        setDownloading(true)
        axios({
            method: 'POST',
            url: 'api/oracle4/download',
            data: {selection: selection,
                   customer_number: customerNumber },
            responseType: 'blob'
            
        })
        .then((response)=>{
            
             
            
            FileSaver.saveAs(response.data, selection[0].file_name)
            setDownloading(false)
        })
        .catch(errors=>{
            let errorMessage = ('Here is the error in the download call', errors)
            setDownloading(false)
            setErrorMessage(errorMessage)
            setErrorAlert(true)
        })

       
       
       
        
    
   

    }

    if(noDocs){return (
        <Grid>
            <Typography color='secondary' variant= 'h5'>No Documents Avalailable for Download</Typography>
        </Grid>
    )} else{
    return(
        <React.Fragment>
            <ErrorAlert open={errorAlert} error={errorMessage} handleClose={closeErrorAlert} />
            <Grid container direction='row'>
            <Grid item xs={false} sm={1} md={1} lg={2}></Grid>
            <Grid item xs sm={11} md={11} lg={9} xl={8}>
                <Typography color='secondary' variant='h6'  gutterBottom={true}>Select Documents You Want to Download or View</Typography>
            </Grid>
        </Grid>  
        <Grid container direction='row' justifyContent = 'center' alignContent='center'>
        <Grid item xs={12} sm={10} md={9} lg={10}>
            
        <DataGridPro
            rows = {downloadData}
            columns={columns}
            autoPageSize={true}
            checkboxSelection
            disableMultipleSelection={true}
            disableMultipleRowSelection={true}
            columnBufferPx={150}
            hideFooter={false}
            onRowSelectionModelChange={rowClick} 
        />
        
        
            </Grid>
            </Grid>
            <Grid container direction='row'>
            <Grid item>
                </Grid>
            </Grid>
        <Grid container direction = 'row'>
            <Grid item xs ={6} sm={8} md={8} lg={9}>
            </Grid>
            <Grid item>
                <LoadingButton
                loading={downloading}
                loadingPosition='end'
                sx={{mt: '1rem'}}
                type ='button'
                size = 'large'
                variant = 'contained'
                color = 'secondary'
                endIcon = {< DownloadIcon/>}
                disabled = {downloadButtonDisabled}
                onClick ={() => DownloadClick(docToPull)}
                >Download</LoadingButton>
                
            </Grid>
        </Grid>
        </React.Fragment>
        
    )

    }
}


export const AdminDownloadDocuments = ()=>{
    const [customerNumber, setCustomerNumber] =React.useState(0)
    const [downloadData, setDownloadData] = React.useState([])
    const [downloading, setDownloading] = React.useState(false)
    const [noDocs, setNoDocs] = React.useState(false)
    const [docToPull, setDocToPull] = React.useState([])
    const [downloadButtonDisabled, setDownloadButtonDisabled] = React.useState(true)
    const [errorAlert, setErrorAlert] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [confirmLoading, setConfirmingLoading] = React.useState(false)
    const [responseAlert, setResponseAlert ] = React.useState(false)
    const [selectedType, setSelectedType] = React.useState('1099-C')
    const [documentConfirmed, setDocumentConfirmed] = React.useState(false)
    console.log('Here is the date for the file_name: ', PDFDate )

    const confirmedAlertClose = () =>{
        setDocumentConfirmed(false)
    }

    const responseClose = () =>{

        setResponseAlert(false)
    }

    const responseButtonClick = () =>{
        setResponseAlert(true)

    }


    

    const context = JSON.parse(window.localStorage.getItem('usercontext'))
    
    console.log('Here is the user context: ', context)
   
    const columns = [
        {field: 'file_name', headerName: 'File Name', flex: 5},
        {field: 'original_creditor', headerName: 'Creditor', flex: 4},
        {field: 'original_account_number', headerName: 'Account Number', flex: 4}
    ]
    
 
    const closeErrorAlert = () =>{
        setErrorAlert(false)
    }

    React.useLayoutEffect(()=>{
        const filterObject = (obj) =>{
            if(obj.status==='Pending'){
                return true
            }
            else {
                return false
            }
        }

        
        axios({
             method: 'POST',
             url: 'api/oracle3/getaccounts',
             data:{email: context.email}
            })
            .then(res=>{
                setCustomerNumber(res.data[0].customer_number)
             axios({
                 method: 'POST',
                 url: 'api/oracle3/documents',
                 data: {customer_number: res.data[0].customer_number}
             }).then(res=>{
                    if(res.data === 'No Documents'){
                        setNoDocs(true)
                    }

                 console.log('Here is the unfiltered data: ', res.data)
                 const filteredData = res.data.filter(filterObject)
                 console.log("Here is the filteredData: ", filteredData )   
                 if (filteredData.lenght < 1){
                    setNoDocs(true)
                 }
                 setDownloadData(filteredData)
                 
             }).catch((errors)=>{
                let errorMessage = ('Here are the errors in the documents call: ', errors)
                setErrorMessage(errorMessage)
                setErrorAlert(true)
            })
            }).catch((errors)=>{
                let errorsMessage = ('Here are the errors in the getaccounts call: ', errors)
                setErrorMessage(errorsMessage)
                setErrorAlert(true)
            })
            
               
     },[context.email])

    const rowClick =(row)=>{
        
        if(!row.length){setDownloadButtonDisabled(true)}
        else{
            
            let rowSelected  = []
            let l = 0

            while (l < (downloadData.length)){
                if (row.includes(downloadData[l].id)){
                    rowSelected.push(downloadData[l])
                    setDownloadButtonDisabled(false)
                    
                    setDocToPull(rowSelected)
                }
                
               l++
            }
            
           
        }

    }

    const DownloadClick = async (selection)=>{
        
        setDownloading(true)
        axios({
            method: 'POST',
            url: 'api/oracle4/download',
            data: {selection: selection,
                   customer_number: customerNumber },
            responseType: 'blob'
            
        })
        .then((response)=>{
            
             
            
            FileSaver.saveAs(response.data, selection[0].file_name)
            setDownloading(false)
        })
        .catch(errors=>{
            let errorMessage = ('Here is the error in the download call', errors)
            setDownloading(false)
            setErrorMessage(errorMessage)
            setErrorAlert(true)
        })
    }

    const changeInSelection = (event) =>{
            setSelectedType(event.target.value)


    }

    const confirmClick = async () =>{
        setConfirmingLoading(true)
        console.log('Row Selected: ', docToPull )
        console.log('SelectedType: ', selectedType)
        
        
        const file_name = await context.customer_number.toString()+'_'+selectedType+'_Notice'+ '_'+ docToPull[0].original_account_number+'_'+PDFDate+'.pdf'
        console.log('Here is the file_name: ', file_name)

        const blobData={customer_number: context.customer_number,
                        file_name: file_name,
                        blob_file: null
        }

        const fillData = {
            customer_number: context.customer_number,
            confirmedfile: file_name,
            original_creditor: docToPull[0].original_creditor,
            original_account_number: docToPull[0].original_account_number,
            old_file_name: docToPull[0].file_name
        }

        console.log('Here is the fillData: ', fillData)

        

        if (selectedType =='1099-C'){
            try{
                console.log('1099-C Blob Data: ', blobData)
                const PDFprops= await getProps(fillData)
                const doc = <TenNinetyNinePDF {...PDFprops} />
                const asPDF = pdf([])
                asPDF.updateContainer(doc)
                const blob = await asPDF.toBlob()
                FileSaver.saveAs(blob, blobData.file_name)

                axios({
                        method: 'POST',
                        url: 'api/oracle4/upload',
                        headers: {'Content-Type': 'application/octet-streat'},
                        params:{fileName: file_name,
                                customer_number: context.customer_number,
                                autogenerated: 1,
                                email: context.email },
                        data: blob

                }).then(response=>{
                    if(response.status===200){
                        axios({
                            method: 'POST',
                            url: 'api/oracle5/documentconfirmed',
                            data: {customer_number: context.customer_number,
                                confirmedfile: file_name,
                                original_creditor: docToPull[0].original_creditor,
                                original_account_number: docToPull[0].original_account_number,
                                old_file_name: docToPull[0].file_name}
                            
                        })
                        .then(result=>{
                            if(result.status===200){
                            setDocumentConfirmed(true)
                            setConfirmingLoading(false)
                            } else {setConfirmingLoading(false)}

                        }).catch(err=>{ throw err})
                    }else{setConfirmingLoading(false)}

                }).catch(err=>{ throw err})                   
                


            } catch(err){
                console.log('Here is the error: ', err)
                 let message = axiosErrorHandling(err)
                 setErrorMessage(message)
                 setErrorAlert(true)

            }


            
        } else if(selectedType==='Already_Settled'){
            try{
                console.log('Already_Settled Blob Data: ', blobData)
                const PDFprops= await getAlreadySettledProps(fillData)
                const doc = <AlreadySettledPDF {...PDFprops} />
                const asPDF = pdf([])
                asPDF.updateContainer(doc)
                const blob = await asPDF.toBlob()
                FileSaver.saveAs(blob, blobData.file_name)

                axios({
                        method: 'POST',
                        url: 'api/oracle4/upload',
                        headers: {'Content-Type': 'application/octet-streat'},
                        params:{fileName: file_name,
                                customer_number: context.customer_number,
                                autogenerated: 1,
                                email: context.email },
                        data: blob

                }).then(response=>{
                    if(response.status===200){
                        axios({
                            method: 'POST',
                            url: 'api/oracle5/documentconfirmed',
                            data: {customer_number: context.customer_number,
                                confirmedfile: file_name,
                                original_creditor: docToPull[0].original_creditor,
                                original_account_number: docToPull[0].original_account_number,
                                old_file_name: docToPull[0].file_name}
                            
                        })
                        .then(result=>{
                            if(result.status===200){
                            setDocumentConfirmed(true)
                            setConfirmingLoading(false)
                            } else {setConfirmingLoading(false)}

                        }).catch(err=>{ throw err})
                    }
                }).catch(err=>{ throw err})                   
                


            } catch(err){
                console.log('Here is the error: ', err)
                 let message = axiosErrorHandling(err)
                 setErrorMessage(message)
                 setErrorAlert(true)

            }

            
        } else if(selectedType==='Bankruptcy'){
            try{
                console.log('Here is the docToPull: ', docToPull)
                
                console.log('Bankruptcy Blob Data: ', blobData)
                const PDFprops= await getBankruptcyProps(fillData)
                const doc = <BankruptcyPDF {...PDFprops} />
                const asPDF = pdf([])
                asPDF.updateContainer(doc)
                const blob = await asPDF.toBlob()
                FileSaver.saveAs(blob, blobData.file_name)

                axios({
                        method: 'POST',
                        url: 'api/oracle4/upload',
                        headers: {'Content-Type': 'application/octet-streat'},
                        params:{fileName: file_name,
                                customer_number: context.customer_number,
                                autogenerated: 1,
                                email: context.email },
                        data: blob

                }).then(response=>{
                    if(response.status===200){
                        axios({
                            method: 'POST',
                            url: 'api/oracle5/documentconfirmed',
                            data: {customer_number: context.customer_number,
                                confirmedfile: file_name,
                                original_creditor: docToPull[0].original_creditor,
                                original_account_number: docToPull[0].original_account_number,
                                old_file_name: docToPull[0].file_name}
                            
                        })
                        .then(result=>{
                            if(result.status===200){
                            setDocumentConfirmed(true)
                            setConfirmingLoading(false)
                            } else {setConfirmingLoading(false)}

                        }).catch(err=>{ throw err})
                    } else{setConfirmingLoading(false)}
                }).catch(err=>{ throw err})                   
                
                setConfirmingLoading(false)

            } catch(err){
                console.log('Here is the error: ', err)
                 let message = axiosErrorHandling(err)
                 setErrorMessage(message)
                 setErrorAlert(true)

            }
        } else if(selectedType==='Deceased'){

            try{
                console.log('Here is the docToPull: ', docToPull)
                console.log('Here is the fillData, ', fillData)
                console.log('Deceased Blob Data: ', blobData)
                const PDFprops= await getDeceasedProps(fillData)
                const doc = <DeceasedPDF {...PDFprops} />
                const asPDF = pdf([])
                asPDF.updateContainer(doc)
                const blob = await asPDF.toBlob()
                FileSaver.saveAs(blob, blobData.file_name)

                axios({
                        method: 'POST',
                        url: 'api/oracle4/upload',
                        headers: {'Content-Type': 'application/octet-streat'},
                        params:{fileName: file_name,
                                customer_number: context.customer_number,
                                autogenerated: 1,
                                email: context.email },
                        data: blob

                }).then(response=>{
                    if(response.status===200){
                        axios({
                            method: 'POST',
                            url: 'api/oracle5/documentconfirmed',
                            data: {customer_number: context.customer_number,
                                confirmedfile: file_name,
                                original_creditor: docToPull[0].original_creditor,
                                original_account_number: docToPull[0].original_account_number,
                                old_file_name: docToPull[0].file_name}
                            
                        })
                        .then(result=>{
                            if(result.status===200){
                            setDocumentConfirmed(true)
                            setConfirmingLoading(false)
                            } else {setConfirmingLoading(false)}

                        }).catch(err=>{ throw err})
                    }else {setConfirmingLoading(false)}
                }).catch(err=>{ throw err})                   
                


            } catch(err){
                console.log('Here is the error: ', err)
                 let message = axiosErrorHandling(err)
                 setErrorMessage(message)
                 setErrorAlert(true)

            }
            
        } else if(selectedType==='Wrong_Identity'){
            try{
                console.log('Wrong_Identity Blob Data: ', blobData)
                const PDFprops= await getWrongIdentityProps(fillData)
                const doc = <WrongIdentityPDF {...PDFprops} />
                const asPDF = pdf([])
                asPDF.updateContainer(doc)
                const blob = await asPDF.toBlob()
                FileSaver.saveAs(blob, blobData.file_name)

                axios({
                        method: 'POST',
                        url: 'api/oracle4/upload',
                        headers: {'Content-Type': 'application/octet-streat'},
                        params:{fileName: file_name,
                                customer_number: context.customer_number,
                                autogenerated: 1,
                                email: context.email },
                        data: blob

                }).then(response=>{
                    if(response.status===200){
                        axios({
                            method: 'POST',
                            url: 'api/oracle5/documentconfirmed',
                            data: {customer_number: context.customer_number,
                                confirmedfile: file_name,
                                original_creditor: docToPull[0].original_creditor,
                                original_account_number: docToPull[0].original_account_number,
                                old_file_name: docToPull[0].file_name}
                        })
                        .then(result=>{
                            if(result.status===200){
                            setDocumentConfirmed(true)
                            setConfirmingLoading(false)
                            } else {setConfirmingLoading(false)}

                        }).catch(err=>{ throw err})
                    }else {setConfirmingLoading(false)}
                }).catch(err=>{ throw err})                   
                


            } catch(err){
                console.log('Here is the error: ', err)
                 let message = axiosErrorHandling(err)
                 setErrorMessage(message)
                 setErrorAlert(true)

            }
           
        } 

        




        
       
        

    }

    if(noDocs){return (
        <Grid>
            <Typography color='secondary' variant= 'h5'>No Documents Avalailable for Download</Typography>
        </Grid>
    )} else{
    return(
        <Box>
            <DocumentConfirmed open={documentConfirmed} handleClose={confirmedAlertClose} />    
            <AdminDocumentResponse open={responseAlert} onClose={responseClose}/>
            <ErrorAlert open={errorAlert} error={errorMessage} handleClose={closeErrorAlert} />
            <Grid container direction='row' alignContent='center' justifyItems='center'>
            <Grid item xs={12} sm={11} md={11} lg={9} xl={8}>
                <Typography color='secondary' variant='h6' gutterBottom={true}>Select Documents You Want to Download or View</Typography>
            </Grid>
        </Grid>  
        <Grid container direction='row' justifyContent = 'center' alignContent='center'>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            
        <DataGridPro
            
            rows = {downloadData}
            columns={columns}
            autoPageSize={true}
            sx={{width: 1400}}
            checkboxSelection
            disableMultipleSelection={true}
            disableMultipleRowSelection={true}
            columnBufferPx={100}
            hideFooter={false}
            onRowSelectionModelChange={rowClick} 
        />
        </Grid>
        </Grid>
        <Grid container sx={{mt: 2, mb:2}}direction='row' justifyContent='center' alignContent='center'>
           <Grid item xs={12} sm={10}> 
        <FormControl fullWidth>
        <InputLabel>Type of Reconciliation</InputLabel>
        <Select id='select-letter-to=send' 
                fullWidth
                value={selectedType}
                onChange={changeInSelection}>
            
            <MenuItem value='1099-C'>1099-C</MenuItem>
            <MenuItem value='Already_Settled'>Already Settled</MenuItem>
            <MenuItem value='Bankruptcy'>Bankruptcy</MenuItem>
            <MenuItem value='Deceased'>Deceased</MenuItem>
            <MenuItem value='Wrong_Identity'>Wrong Identity</MenuItem>
            
            
        </Select>
        </FormControl>
        </Grid>
        </Grid>
            <Grid container direction='row'>
            <Grid item>
                </Grid>
            </Grid>
        <Grid container direction='row-reverse' spacing={1} sx={{mt: 1}}>
            <Grid item xs ={6} sm={4} md={3} lg={2}>
            </Grid>
            <Grid item>
                <LoadingButton
                loading={downloading}
                loadingPosition='end'
                
                type ='button'
                variant = 'contained'
                color = 'secondary'
                endIcon = {< DownloadIcon/>}
                disabled = {downloadButtonDisabled}
                onClick ={() => DownloadClick(docToPull)}
                >Download</LoadingButton>
                
            </Grid>
            <Grid item>
                <LoadingButton 
                               color='primary'
                               disabled={downloadButtonDisabled} 
                               variant='contained' 
                               loading={confirmLoading} 
                               loadingPosition='end' 
                               endIcon={<FileDownloadDoneIcon />} 
                               onClick={confirmClick}
                               >Document is Confirmed
                </LoadingButton>

            </Grid>
            <Grid item>
                <Button onClick={responseButtonClick} variant='contained' color='secondary'endIcon={<SendIcon/>} > Send Response </Button>
            </Grid>
        </Grid>
        </Box>
        
    )

    }



}


export const AdminUploadDocuments = ({download}) =>{

    

    const [data, setData] = React.useState(defaultdata)
    const [selectValue, setSelectValue] = React.useState('None')
    const [accountArray, setAccountArray] = React.useState(null)
    const [accountStatus, setAccountStatus] = React.useState(defaultdata[0].account_status)
    const [accountInstructions, setAccountInstructions] = React.useState('After selecting an account you can upload a document either by clicking, or dragging a pdf into, this box.')
    const [errorAlert, setErrorAlert] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [file, setFile] = React.useState(null)
    const [disableButton, setDisableButton] = React.useState(true)
    const [fileReady, setFileReady] = React.useState(true)
    const [isLoading, setIsLoading] =React.useState(true)  
    const context = JSON.parse(window.localStorage.getItem('usercontext'))
    console.log('Here is the user context: ', context)


    const closeErrorAlert = () =>{
        setErrorAlert(false)
    }

    React.useEffect(()=>{
        let email = context.email
        axios({
               method:"POST",
               url: 'api/oracle4/getdocument',
               data: {email: email}
            })
            .then(res=>{
                
                if(res.data === 'No Results'){
                    setIsLoading(false)
                    download()
            }else{
                setIsLoading(false)
                setData(res.data)
                
            }
            
            
            })
    },[context.email])
    
   const fileToBlob = async (file) => new Blob ([new Uint8Array(await file.arrayBuffer())], {type: file.type})
    
    
    
    React.useLayoutEffect(()=>{
        const accountMessages = {already_settled: 'Please upload a letter or invoice from the previous debt collector',
                            deceased: "Please upload a death certificate or letter from the local coroner's office indicating the person is deceased.",
                            ten99C: 'Please upload the 1099-C provided by the original creditor',
                            wrongIdentity: 'Please upload a valid SSN, police report, or letter from your bank presenting that this account was opened fraudently',
                            bankruptcy: 'Please upload your court order or signed letter from a judge that indicates this account is protected from creditors from a Chapter 7 or 13 bankruptcy'}
    
        messageToDisplay(accountStatus, accountMessages)
    
    },[accountStatus])
    
    
    const selectChange = (event) =>{
        
        let workingArray = event.target.value.split('_')
        
        setAccountStatus(workingArray[2])
        setAccountArray(workingArray)
        setSelectValue(event.target.value)
        setDisableButton(false)
        
        
    }
    
    const fileToState = (file) =>{
        setFile(file)
        setFileReady(false)
    }
    
    const onSubmit = async () =>
    {
    
        setIsLoading(true)
        
          
    
     var customer_number = data[0].customer_number.toString()    
     var fileName = customer_number +'_'+ selectValue + '_' + file[0].name
     fileName = fileName.replace(/([^a-z0-9.]+)/gi, '_')
     let objectData = await fileToBlob(file[0])

    
    
     axios({
        method: 'POST',
        url: 'api/oracle4/upload',
        headers: {'Content-Type': 'application/octet-stream'},
        params:{fileName: fileName,
                customer_number: customer_number,
                autogenerated: 0,
                email: context.email},
        data: objectData

     })
     .then(result=>{

        if (result.status===200){
            axios({
                method: "POST",
                url: 'api/oracle3/putdocuments',
                data: {customer_number: customer_number,
                       file_name: fileName,
                       original_creditor: accountArray[0],
                       original_account_number: accountArray[1]            
                       }
           
            }).then(res=>{
                   if(res.status === 200){
                       download()
                       setIsLoading(false)
                   }
                   else{
                    let errMessage = 'Error occured in the downloading of documents.'
                    setErrorMessage(errMessage)
                    setErrorAlert(true)
                    setIsLoading(false)
                   }
           
                
            })

        }


     })
     
     
    
     
    
    
    
    }
    
    
    const messageToDisplay = (account_status, messages)=>{
        switch(account_status){
            case 'Already Settled' :
                setAccountInstructions(messages.already_settled)
                break
            case 'Bankruptcy' :
                setAccountInstructions(messages.bankruptcy)
                break
            case 'Deceased' :
                setAccountInstructions(messages.deceased)
                break
            case 'Wrong Identity' :
                setAccountInstructions(messages.wrongIdentity)
                break
            case '1099-C' :
                setAccountInstructions(messages.ten99C)
                break
                default:
                    setAccountInstructions('After selecting an account you can upload a document either by clicking, or dragging a pdf into, this box.')
        }
    }
    
    
    
        return(
            
                <Box>
                 <Grid container direction='column' alignContent='center' justifyItems='center'>   
                <Grid>
                <ErrorAlert open={errorAlert} error={errorMessage} handleClose={closeErrorAlert}/>
                </Grid>
            <Grid item>
            <Typography>Select Account You Are Uploading the Document</Typography>
            </Grid>
            <Grid item>
            <FormControl sx={{mt: 2}}>
                <InputLabel htmlFor='select-account-that-needs-supportive-documents'>Accounts</InputLabel>
                <NativeSelect
                value={selectValue}
                onChange={selectChange}>
                    <option value='None'>Select an Account</option>
                    {renderOptions(data)}
                </NativeSelect>
            </FormControl>
           </Grid>
           <Grid item xs={8} sm={8} md={6} lg={8} xl={8} sx={{mt: 2}}>
           <UploadDropzone instructions ={accountInstructions} fileToState={fileToState}/>
            </Grid>
            <Grid item>
            <LoadingButton
                    loading={isLoading}
                    loadingPosition='end' 
                    fullWidth
                    variant='contained' 
                    color='primary' 
                    onClick={onSubmit} 
                    disabled={disableButton || fileReady}
                    endIcon={<UploadIcon />}>Submit Document</LoadingButton>
            </Grid>
            </Grid>
            </Box>
            
        )

}